import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';
import { Rule } from 'antd/lib/form';

import styles from './AddRetailPriceModal.modal.module.css'; // corrected import
import { AddRetailPriceModalType } from './AddRetailPriceModal.modal.types';
const AddRetailPriceModal = ({ open, cancel, form, action, loading, price }: AddRetailPriceModalType) => {
  const checkPriceRule: Rule = () => ({
    validator(_, value: string) {
      const floatValue = parseFloat(value);

      if (floatValue > price || value === '') {
        return Promise.resolve();
      }

      const errorMessage = `The retail price must be greater than  ${price}.`;
      return Promise.reject({
        message: <span className={styles['error-message']} dangerouslySetInnerHTML={{ __html: errorMessage }} />,
      });
    },
  });

  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> Update Retail Price </h1>
          </div>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={() => action('edit')}>
            <Form.Item
              label="Retail Price"
              name="retailPrice"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>Required</span>,
                },
                checkPriceRule,
              ]}
            >
              <Input className={styles.input} name="retailPrice" placeholder={`Enter Price (greater than ${price})`} />
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Spin className={styles.spin} /> : 'Update'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddRetailPriceModal;
