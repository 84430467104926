import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';

import styles from './AddAdminUserModal.modal.module.css';
import { EditOfferTypeModalTypes } from './AddAdminUserModal.modal.types';

const AddAdminModal = ({ open, cancel, form, action, loading }: EditOfferTypeModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> Add Admin user </h1>
          </div>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={() => action('add')}>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input className={styles.input} name="firstName" type={'text'} min={0} placeholder="Enter first name" />
            </Form.Item>

            <Form.Item
              label="Last name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input className={styles.input} name="lastName" type={'text'} min={0} placeholder="Enter last name" />
            </Form.Item>

            <Form.Item
              label="Email address"
              name="emailAddress"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input
                className={styles.input}
                name="emailAddress"
                type={'text'}
                min={0}
                placeholder="Enter email address"
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input
                className={styles.input}
                name="password"
                type={'text'}
                min={0}
                placeholder="Enter Password"
                readOnly
              />
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Spin className={styles.spin} /> : 'Add'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddAdminModal;
