import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BackPartyLogo from '../../../assets/BarkPartyLogo.png';
import Checkbox from '../../../component/checkbox/Checkbox';
import { updateTokens } from '../../../redux/auth/auth.reducer';
import { useAppDispatch } from '../../../redux/hooks';
import { usePostUserMutation } from '../../../redux/login/login.actions';
import styles from './Login.module.css';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const [postUser] = usePostUserMutation();
  const handleLoginUser = async () => {
    try {
      const response = await postUser({ email, password }).unwrap();
      if (response?.accessToken) {
        localStorage.setItem(
          'token',
          JSON.stringify({ accessToken: response?.accessToken, refreshToken: response?.refreshToken }),
        );
        dispatch(updateTokens({ accessToken: response?.accessToken, refreshToken: response?.refreshToken }));
        navigate('/main/dashboard', { replace: true });
      }
    } catch (error: any) {
      const errorMessage = Array.isArray(error.data.message) ? error.data.message.join(', ') : error.data.message;
      setError(errorMessage);
    }
  };

  return (
    <div>
      <Row>
        <Col span={24} className={styles.navContainer}>
          <div className={styles.imageContainer}>
            <Image src={BackPartyLogo} alt="logo" preview={false}></Image>
          </div>
          <div className={styles.textContainer}>
            <h1 className={styles.title}>BarkParty</h1>
            <p className={styles.description}>Bark Party, turn your pet into art with Ai</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24} className={styles.loginFormContainer}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onSubmitCapture={handleLoginUser}
          >
            <Form.Item name="email" htmlFor="email">
              <Input
                prefix={<UserOutlined className={styles.userIcon} />}
                id="email"
                name="email"
                placeholder="Email"
                className={styles.userContainer}
                bordered={false}
                value={email}
                onChange={handleEmailChange}
              />
            </Form.Item>
            <Form.Item
              name="password"
              htmlFor="password"
              help={error ? <span className={styles.errorFormItem}>{error}</span> : ''}
            >
              <Input.Password
                prefix={<LockOutlined className={styles.passwordIcon} />}
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                bordered={false}
                className={styles.passwordContainer}
                onChange={handlePasswordChange}
                value={password}
              />
            </Form.Item>
            <Form.Item>
              <div className={styles.rememberMeContainer}>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox></Checkbox>
                </Form.Item>
                <a className={styles.forgotText} href="">
                  Forgot your password?
                </a>
              </div>
            </Form.Item>

            <Form.Item className={styles.signInContainer}>
              <Button type="primary" htmlType="submit" className={styles.signIn}>
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col span={24} className={styles.footerContainer}>
          <p className={styles.footerText}>2022 © All rights reserved. BarkPart.AI </p>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
