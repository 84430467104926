import { Image, Tag } from 'antd';
import React from 'react';

import { Models } from '../../redux/models/models.model';
import { formatDate, getStatusText } from '../../utils/helpers';
import styles from './Models.table.module.css';

const columns = () => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Trainer Data Id',
        key: 'trainer_data_id',
        render: (models: Models) => (
          <div className={styles.tagContainer}>
            {models.trainer.map((trainer) => (
              <Tag className={styles.tag} color="#22A89E" key={trainer.trainer_data_id}>
                {trainer.trainer_data_id}
              </Tag>
            ))}
          </div>
        ),
      },
      {
        title: 'Images',
        key: 'image',
        render: (models: Models) => {
          return (
            <div>
              {models.modelFiles.map((modelFile, index) => (
                <div key={index} className={styles.imgContainer}>
                  <Image
                    key={index}
                    height={40}
                    width={40}
                    src={modelFile.path}
                    alt={modelFile.name}
                    className={styles.img}
                  />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => {
          const statusText = getStatusText(text, 'model');
          return <p>{statusText}</p>;
        },
      },
      {
        title: 'Training',
        key: 'training',
        render: (models: Models) => <p>{models.training.name}</p>,
      },
      {
        title: 'Created at',
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: 'Updated at',
        key: 'updated_at',
        dataIndex: 'updated_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
    ];
  }
};
export default columns;
