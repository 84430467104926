import { Tooltip } from 'antd';
import React from 'react';

import DeleteIcon from '../../../assets/icon _close circle_.svg';
import { GetLab } from '../../../redux/lab/lab.model';
import { formatDate } from '../../../utils/helpers';
import styles from '../CreateExperiment/CreateLab.module.css';

const labColumns = (onLabActionClick: (_id?: number | undefined, _type?: string | undefined) => void) => {
  {
    return [
      {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'description',
        dataIndex: 'description',
        key: 'description',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        key: 'addExperiment',
        render: (lab: GetLab) => (
          <a
            onClick={() => {
              onLabActionClick(lab?.id, 'addExperiment');
            }}
          >
            Add Experiment
          </a>
        ),
      },
      {
        title: '',
        key: 'Delete',
        render: (text: string, record: GetLab) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={DeleteIcon}
                className={styles.deleteIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onLabActionClick(record.id, 'deleteLab');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};
export default labColumns;
