import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Upload } from 'antd';

import { BlankInformationModalType } from './BlankInformation.modal.types';
import styles from './BlankInformation.module.css';
const BlankInformationModal = ({
  open,
  cancel,
  form,
  action,
  onChange,
  handleChangeImage,
  type,
  imageUploaded,
}: BlankInformationModalType) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> {type === 'add' ? 'Add' : 'Edit'} Blank Information</h1>
          </div>
          <Form
            form={form}
            layout={'vertical'}
            autoComplete="off"
            name="basic"
            onFinish={type === 'add' ? () => action('add') : () => action('edit')}
          >
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the name' }]}>
              <Input
                className={styles.input}
                name="name"
                type={'text'}
                min={0}
                placeholder="Enter name"
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input the description' }]}
            >
              <Input
                className={styles.input}
                name="description"
                type={'text'}
                min={0}
                placeholder="Enter description"
                onChange={onChange}
              />
            </Form.Item>
            {type === 'add' ? (
              <Form.Item
                className={styles.uploadContainer}
                label="Image"
                name="image"
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload showUploadList={false} maxCount={1} action="" onChange={handleChangeImage}>
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                  {imageUploaded && <p className={styles.imageUploaded}>Image Uploaded!</p>}
                </Upload>
              </Form.Item>
            ) : (
              ''
            )}
            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit">
                {type === 'add' ? 'Add' : 'Edit'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default BlankInformationModal;
