import closeIcon from '../../../assets/icon _close circle_.svg';
import editIcon from '../../../assets/icon _edit_.svg';
import { TrainingRulesModel } from '../../../redux/trainings/trainingRules/training.rules.model';
import styles from '../../users/models/TrainingModels.module.css';

const columns = (onActionClick: (_data: TrainingRulesModel, _type: string) => void) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <a>{text}</a>;
        },
      },
      {
        title: 'Training',
        dataIndex: 'training_id',
        key: 'training_id',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Learning rate unet',
        dataIndex: 'learning_rate_unet',
        key: 'learning_rate_unet',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Learning rate text encoder',
        dataIndex: 'learning_rate_text_encoder',
        key: 'learning_rate_text_encoder',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Seed',
        dataIndex: 'seed',
        key: 'seed',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Steps text encoder',
        dataIndex: 'steps_text_encoder',
        key: 'steps_text_encoder',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Steps unet',
        key: 'steps_unet',
        dataIndex: 'steps_unet',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Base model',
        key: 'base_model_id',
        dataIndex: 'base_model_id',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: '',
        key: 'edit',
        render: (data: TrainingRulesModel) => {
          return (
            <img
              alt={'Edit'}
              style={{ cursor: 'pointer' }}
              src={editIcon}
              className={styles.icon}
              onClick={() => {
                onActionClick(data, 'edit');
              }}
            />
          );
        },
      },
      {
        title: '',
        key: 'close',
        render: (data: TrainingRulesModel) => {
          return (
            <img
              alt={'Delete'}
              src={closeIcon}
              className={styles.icon}
              onClick={() => {
                onActionClick(data, 'delete');
              }}
            />
          );
        },
      },
    ];
  }
};
export default columns;
