import React from 'react';

export const UserProfileIcon = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6504 14.475C13.5629 14.4625 13.4504 14.4625 13.3504 14.475C11.1504 14.4 9.40039 12.6 9.40039 10.3875C9.40039 8.12498 11.2254 6.28748 13.5004 6.28748C15.7629 6.28748 17.6004 8.12498 17.6004 10.3875C17.5879 12.6 15.8504 14.4 13.6504 14.475Z"
        stroke="#231F20"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9242 22.7251C19.6992 24.7626 16.7492 26.0001 13.4992 26.0001C10.2492 26.0001 7.29922 24.7626 5.07422 22.7251C5.19922 21.5501 5.94922 20.4001 7.28672 19.5001C10.7117 17.2251 16.3117 17.2251 19.7117 19.5001C21.0492 20.4001 21.7992 21.5501 21.9242 22.7251Z"
        stroke="#231F20"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 26C20.4035 26 26 20.4035 26 13.5C26 6.59644 20.4035 1 13.5 1C6.59644 1 1 6.59644 1 13.5C1 20.4035 6.59644 26 13.5 26Z"
        stroke="#231F20"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
