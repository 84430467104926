import { Tooltip } from 'antd';
import React from 'react';

import closeIcon from '../../../assets/icon _close circle_.svg';
import editIcon from '../../../assets/icon _edit_.svg';
import { TrainingCategoryData } from '../../../redux/trainings/trainingCategory/training.category.model';
import { formatDate } from '../../../utils/helpers';
import styles from '../../users/models/TrainingModels.module.css';
const columns = (onActionClick: (_id: TrainingCategoryData, _type: string) => void) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <a>{text}</a>;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Created at',
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: '',
        key: 'edit',
        render: (data: TrainingCategoryData) => {
          return (
            <Tooltip title="Edit" color="#23a89e">
              <img
                alt={'Edit'}
                style={{ cursor: 'pointer' }}
                src={editIcon}
                className={styles.editIcon}
                onClick={() => {
                  onActionClick(data, 'edit');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'close',
        render: (data: TrainingCategoryData) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={closeIcon}
                className={styles.deleteIcon}
                onClick={() => {
                  onActionClick(data, 'delete');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};
export default columns;
