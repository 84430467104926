import axios from 'axios';

// for multiple requests
let isRefreshing = false;
let failedQueue: any[] = [];

axios.interceptors.request.use(
  // Signing and setting headers of requests
  async (request) => {
    const tokens = await localStorage.getItem('tokens');
    const { accessToken } = tokens ? JSON.parse(tokens) : '';
    request.headers.Authorization = `Bearer ${accessToken ?? '123'}`;
    return request;
  },
);

const generateRefreshToken = async (refreshToken: string) => {
  const body = {
    refreshToken: refreshToken,
  };

  const headers = {
    Authorization: 'Bearer 123',
    'Content-Type': 'application/json',
  };

  return await axios.post(`${process.env.REACT_APP_API_URL}/authentication/admin/refresh/token`, body, {
    headers,
  });
};

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const tokens = await localStorage.getItem('tokens');
    // const dispatch = useDispatch();

    const { refreshToken } = tokens ? JSON.parse(tokens) : '';
    if (error.response.status === 401 && !originalRequest.retry) {
      // If a refresh token is pending, queue the requests
      if (isRefreshing) {
        failedQueue.push(originalRequest);
        return;
      }

      originalRequest.retry = true;
      isRefreshing = true;

      const response = await generateRefreshToken(refreshToken);

      if (response.status === 201) {
        localStorage.setItem(
          'tokens',
          JSON.stringify({ accessToken: response?.data?.accessToken, refreshToken: response?.data?.refreshToken }),
        );
      }
      isRefreshing = false;

      const originalResponse = await axios(originalRequest);

      failedQueue.forEach((request) => axios(request));
      failedQueue = [];

      return originalResponse;
    }

    if (error?.response?.status === 401) {
      // If session expired and retry failed, login is required
      failedQueue = [];
      throw error;
    }
    throw error;
  },
);
