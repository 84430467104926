import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../../utils/axiosBaseQuery';
import { DataModel } from './data.model';
import { TrainingRequirementsModel } from './training.requirements.model';

interface argsType {
  id: number;
  page: number;
}

export const trainingRequirementsApi = createApi({
  reducerPath: 'trainingRequirements',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['TrainingRequirements'],
  endpoints: (builder) => ({
    getTrainingRequirementsByTrainingPromptId: builder.query<DataModel, argsType>({
      query: (object) => ({
        url: `/training-prompt-requirements/training-prompt/${object.id}?limit=10&page=${object.page}`,
        method: 'get',
      }),
      providesTags: ['TrainingRequirements'],
    }),
    deleteTrainingRequirements: builder.mutation<string, number>({
      query: (id: number) => ({ url: `/training-prompt-requirements/${id}`, method: 'delete' }),
      invalidatesTags: ['TrainingRequirements'],
    }),
    createTrainingRequirements: builder.mutation<TrainingRequirementsModel, Partial<TrainingRequirementsModel>>({
      query: (data: TrainingRequirementsModel) => ({
        url: `/training-prompt-requirements`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['TrainingRequirements'],
    }),
    updateTrainingRequirements: builder.mutation<TrainingRequirementsModel, Partial<TrainingRequirementsModel>>({
      query: (data: TrainingRequirementsModel) => ({
        url: `/training-prompt-requirements/${data.id}`,
        method: 'PATCH',
        data: data,
      }),
      invalidatesTags: ['TrainingRequirements'],
    }),
  }),
});

export const {
  useGetTrainingRequirementsByTrainingPromptIdQuery,
  useCreateTrainingRequirementsMutation,
  useDeleteTrainingRequirementsMutation,
  useUpdateTrainingRequirementsMutation,
} = trainingRequirementsApi;
