import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';

import Images from '../../component/images/Images';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import { useDeleteGalleryMutation, useGetGalleryQuery } from '../../redux/images/images.actions';
import styles from '../diffusion/Diffusion.module.css';

const Gallery = () => {
  const [page, setPage] = useState(1);
  const { data: gallery, isLoading } = useGetGalleryQuery({ page: page });
  const [deleteTrigger] = useDeleteGalleryMutation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number[]>([]);

  const onPaginate = (page: number) => {
    setPage(page);
  };

  const onTrashButton = () => {
    setOpenModal(true);
  };

  const closeDeleteModal = () => {
    setOpenModal(false);
  };

  const onClickDelete = () => {
    deleteTrigger(selectedItem);
    setSelectedItem([]);
    setOpenModal(false);
  };

  const onChangeHandler = (e: CheckboxChangeEvent, id: number) => {
    if (e.target.checked) {
      setSelectedItem([...selectedItem, id]); // add the item id to the selected items array
    } else {
      setSelectedItem(selectedItem.filter((itemId) => itemId !== id)); // remove the item id from the selected items array
    }
  };

  // Making a checkbox event if the image is clicked
  const onImageClickHandler = (id: number) => {
    const isChecked = selectedItem.includes(id);
    const e = { target: { checked: !isChecked } } as CheckboxChangeEvent;
    onChangeHandler(e, id);
  };

  const closeSaveContainer = () => {
    setSelectedItem([]);
  };

  return (
    <div className={styles.mainContainer}>
      <Images
        onActionClick={onTrashButton}
        data={gallery}
        isLoading={isLoading}
        onPaginate={onPaginate}
        page={page}
        type={'gallery'}
        onChangeHandler={onChangeHandler}
        onImageClickHandler={onImageClickHandler}
        closeSaveContainer={closeSaveContainer}
        selectedItem={selectedItem}
      ></Images>
      <DeleteModal openModal={openModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
    </div>
  );
};

export default Gallery;
