import { Tooltip } from 'antd';

import DeleteIcon from '../../assets/icon _close circle_.svg';
import editIcon from '../../assets/icon _edit_.svg';
import { BlanksOffers } from '../../redux/blankOffers/blankOffers.model';
import { formatDate } from '../../utils/helpers';
import styles from '../blanks/Blanks.module.css';

const columns = (onActionClick: (_property?: BlanksOffers | undefined, _type?: string | undefined) => void) => {
  {
    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <p>{text}</p>;
        },
      },

      {
        title: 'Offer Type Id',
        dataIndex: 'offer_type_id',
        key: 'offer_type_id',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Blank Id',
        dataIndex: 'blank_id',
        key: 'blank_id',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: '',
        key: 'edit',
        render: (blankOffer: BlanksOffers) => {
          return (
            <Tooltip title="Edit" color="#23a89e">
              <img
                alt={'Edit'}
                style={{ cursor: 'pointer' }}
                src={editIcon}
                className={styles.editIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(blankOffer, 'edit');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'Delete',
        render: (blankOffer: BlanksOffers) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={DeleteIcon}
                className={styles.deleteIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(blankOffer, 'delete');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};

export default columns;
