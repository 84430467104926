import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import {
  useGetBlankPrintLocationImageRulesQuery,
  useUpdateBlankPrintLocationImageRulesStatusMutation,
} from '../../redux/blankPrintLocationImageRules/blankPrintLocationImageRules.action';
import { BlankPrintLocationImageRules as BlankPrintLocationImageRulesType } from '../../redux/blankPrintLocationImageRules/blankPrintLocationImageRules.model';
import styles from '../blanks/Blanks.module.css';
import columns from './BlankPrintLocationImageRules.table';

const BlankPrintLocationImageRules = () => {
  const { id } = useParams();
  const blankPrintLocationId = Number(id);
  const [page, setPage] = useState(1);
  const [isBlankActive, setBlankActive] = useState<{ [key: string]: boolean }>({});
  const { data: blankPrintLocationImageRules, isLoading } = useGetBlankPrintLocationImageRulesQuery({
    blankPrintLocationId,
    page,
  });
  const [updateStatus] = useUpdateBlankPrintLocationImageRulesStatusMutation();

  useEffect(() => {
    if (blankPrintLocationImageRules) {
      const newState: { [key: string]: boolean } = {};
      blankPrintLocationImageRules?.items.forEach((blankPrintLocation) => {
        if (blankPrintLocation.id && blankPrintLocation.active)
          newState[blankPrintLocation.id.toString()] = blankPrintLocation.active;
      });
      setBlankActive(newState);
    }
  }, [blankPrintLocationImageRules]);

  const onPaginate = (page: number) => {
    setPage(page);
  };

  // Function to handle checkBox click
  const onCheckBoxClick = (blankPrintLocationImageRulesId: number) => {
    const isActive = { ...isBlankActive };
    isActive[blankPrintLocationImageRulesId.toString()] = !isBlankActive[blankPrintLocationImageRulesId.toString()];
    setBlankActive(isActive);

    // Update the server with the new checkBox state
    updateStatus({ blankPrintLocationImageRulesId, isActive: isActive[blankPrintLocationImageRulesId.toString()] });
  };

  const onActionClick = (
    blankPrintLocationImageRules: BlankPrintLocationImageRulesType | undefined,
    type: string | undefined,
  ) => {
    if (type === 'activation' && blankPrintLocationImageRules) {
      if (blankPrintLocationImageRules.id) onCheckBoxClick(blankPrintLocationImageRules.id);
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <span className={styles.vector} />
          </Col>
          <Col>
            <div className={styles.subHeader}>
              <p className={styles.users}>Blanks</p>
              <p className={styles.details}>/ Blank Print Locations</p>
              <p className={styles.details}>/ Image rules</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>Image rules</p>
          </Col>
          <p className={styles.line}></p>
          <div className={styles.margins}></div>
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick, isBlankActive)}
          className={styles.table}
          dataSource={blankPrintLocationImageRules?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: blankPrintLocationImageRules?.meta?.itemsPerPage ?? 0,
            total: blankPrintLocationImageRules?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
      </Row>
    </Row>
  );
};

export default BlankPrintLocationImageRules;
