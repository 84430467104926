import { createApi } from '@reduxjs/toolkit/query/react';

import { User } from '../../containers/auth/login/Login.type';
import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { LoginModel } from './login.model';

export const loginApi = createApi({
  reducerPath: 'login',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['Login'],
  endpoints: (builder) => ({
    postUser: builder.mutation<User, Partial<User>>({
      query: (user: User) => ({
        url: `/authentication/login`,
        method: 'POST',
        data: user,
      }),
      transformResponse(response: LoginModel) {
        if (response?.accessToken)
          localStorage.setItem(
            'tokens',
            JSON.stringify({ accessToken: response.accessToken, refreshToken: response.refreshToken }),
          );
        return response;
      },
    }),
  }),
});

export const { usePostUserMutation } = loginApi;
