import { Button, Col, Form, Input, Modal, Row } from 'antd';

import styles from './gimpModal.modal.module.css';
import { GimpPerspectiveModalType } from './GimpPerspectiveModal.modal.types';
const GimpPerspectiveModal = ({ open, cancel, form, action }: GimpPerspectiveModalType) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> Add Perspective </h1>
          </div>
          <Form form={form} layout={'vertical'} autoComplete="off" onFinish={action}>
            {/* Top Left */}
            <Form.Item label="Top Left">
              <div className={styles.inputContainer}>
                <Form.Item
                  name="topLeftX"
                  rules={[{ required: true, message: 'required' }]}
                  className={styles.inputDivider}
                >
                  <Input placeholder="X" />
                </Form.Item>
                <Form.Item
                  name="topLeftY"
                  rules={[{ required: true, message: 'required' }]}
                  className={styles.inputDivider}
                >
                  <Input placeholder="Y" />
                </Form.Item>
              </div>
            </Form.Item>

            {/* Top Right */}
            <Form.Item label="Top Right">
              <div className={styles.inputContainer}>
                <Form.Item
                  name="topRightX"
                  rules={[{ required: true, message: 'required' }]}
                  className={styles.inputDivider}
                >
                  <Input placeholder="X" />
                </Form.Item>
                <Form.Item
                  name="topRightY"
                  rules={[{ required: true, message: 'required' }]}
                  className={styles.inputDivider}
                >
                  <Input placeholder="Y" />
                </Form.Item>
              </div>
            </Form.Item>

            {/* Bottom Left */}
            <Form.Item label="Bottom Left">
              <div className={styles.inputContainer}>
                <Form.Item
                  name="bottomLeftX"
                  rules={[{ required: true, message: 'required' }]}
                  className={styles.inputDivider}
                >
                  <Input placeholder="X" />
                </Form.Item>
                <Form.Item
                  name="bottomLeftY"
                  rules={[{ required: true, message: 'required' }]}
                  className={styles.inputDivider}
                >
                  <Input placeholder="Y" />
                </Form.Item>
              </div>
            </Form.Item>

            {/* Bottom Right */}
            <Form.Item label="Bottom Right">
              <div className={styles.inputContainer}>
                <Form.Item
                  name="bottomRightX"
                  rules={[{ required: true, message: 'required' }]}
                  className={styles.inputDivider}
                >
                  <Input placeholder="X" />
                </Form.Item>
                <Form.Item
                  name="bottomRightY"
                  rules={[{ required: true, message: 'required' }]}
                  className={styles.inputDivider}
                >
                  <Input placeholder="Y" />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default GimpPerspectiveModal;
