import React from 'react';

export const ToolsIcon = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 26C20.4035 26 26 20.4035 26 13.5C26 6.59644 20.4035 1 13.5 1C6.59644 1 1 6.59644 1 13.5C1 20.4035 6.59644 26 13.5 26Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2197 20.6178L11.7696 14.2628C10.3674 14.917 7.89013 14.5899 6.72169 12.8143C5.70408 11.2678 5.94269 9.44986 6.34777 8.60876C6.67495 8.967 7.44148 9.80499 7.89019 10.291C8.37312 10.805 9.61942 11.5993 10.7412 10.6648C11.8629 9.73023 10.8034 8.20379 10.414 7.90784L8.77824 6.22564C10.4609 5.75836 11.723 5.99199 13.1718 7.16019C14.6206 8.32839 14.5117 10.6648 14.2468 11.7395L20.4632 17.9076C20.8683 18.2814 21.4354 19.2907 20.4632 20.3374C19.491 21.3841 18.5625 20.9605 18.2197 20.6178Z"
        fill="black"
      />
    </svg>
  );
};
