import { Col, Row, Table } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import arrow from '../../assets/arrow.svg';
import { useGetOrderByIdQuery } from '../../redux/orders/orders.actions';
import styles from '../blankInformation/BlankInformation.module.css';
import { orderLineItemSpecs } from '../orderLineItem/OrderLineItem.types';
import columns from './ProductVariant.table';

const ProductVariant = () => {
  const { id, productVariantId } = useParams();
  const orderId = Number(id);
  const variantId = Number(productVariantId);
  const navigate = useNavigate();
  const { data: OrderInfo } = useGetOrderByIdQuery(orderId);

  const foundIndex = OrderInfo?.orderLineItems?.findIndex((orderLineItem) => {
    const orderLineItemSpec = Array.isArray(orderLineItem?.orderLineItemSpecs)
      ? orderLineItem?.orderLineItemSpecs[0]
      : orderLineItem?.orderLineItemSpecs;
    return orderLineItemSpec?.productVariant?.id === variantId;
  });
  const ProductVariant = OrderInfo?.orderLineItems[foundIndex ?? 0]?.orderLineItemSpecs as
    | Array<orderLineItemSpecs>
    | undefined;

  const ProductVariantData = ProductVariant?.[0]?.productVariant;

  return (
    <>
      <Row className={styles.mainContainer}>
        <Row className={styles.container}>
          <Row className={styles.header}>
            <Col>
              <img alt={'Arrow'} src={arrow} className={styles.vector} onClick={() => navigate(-1)} />
            </Col>
            <Col>
              <div className={styles.subHeader}>
                <p className={styles.users}>Orders</p>
                <p className={styles.details}>/ Order Information </p>
                <p className={styles.details}>/ Order Line Items </p>
                <p className={styles.details}>/ Product Variant </p>
              </div>
            </Col>
          </Row>
          <Row className={styles.detailsTitle}>
            <Col className={styles.title}>
              <p>Product Variant</p>
            </Col>
            <Col>
              <p className={styles.line}></p>
            </Col>
            <div className={styles.margins}></div>
          </Row>
          <Row className={styles.tableContainer}>
            <Table
              rowKey="id"
              columns={columns()}
              className={styles.table}
              dataSource={ProductVariantData ? [ProductVariantData] : []}
              scroll={{ x: 500 }}
            />
          </Row>
        </Row>
      </Row>
    </>
  );
};
export default ProductVariant;
