import { PrinterOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

import { Order } from '../../redux/orders/orders.model';
import { formatDate, getStatusText } from '../../utils/helpers';
import styles from './OrderInformation.module.css';

const columns = (onActionClick: (_order?: Order | undefined, _type?: string | undefined) => void) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'User id',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        const statusText = getStatusText(text, 'order');
        return <p>{statusText}</p>;
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render: (text: string, order: Order, index: number) => (
        <p>{order?.orderLineItems?.length > 0 ? order.orderLineItems[index]?.orderLineItemPayment?.discount : '-'}</p>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Shipping',
      dataIndex: 'shipping',
      key: 'shipping',
      render: (text: string, order: Order, index: number) => (
        <p>{order?.orderLineItems?.length > 0 ? order.orderLineItems[index]?.orderLineItemPayment?.shipping : '-'}</p>
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      render: (text: string, order: Order, index: number) => (
        <p>{order?.orderLineItems?.length > 0 ? order.orderLineItems[index]?.orderLineItemPayment?.tax : '-'}</p>
      ),
    },

    {
      title: 'Shipping Address',
      dataIndex: 'shipping_address_id',
      key: 'shipping_address_id',
      render: (order: Order) => {
        if (order && order.shipping_address !== null) {
          return (
            <a
              onClick={() => {
                onActionClick(order, 'shipping address');
              }}
            >
              View details
            </a>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Billing Address',
      dataIndex: 'billing_address_id',
      key: 'billing_address_id',
      render: (order: Order) => {
        if (order && order.billing_address !== null) {
          return (
            <a
              onClick={() => {
                onActionClick(order, 'billing address');
              }}
            >
              View details
            </a>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Order Line Items',
      dataIndex: 'order_line_Items',
      key: 'order_line_Items',
      render: (order: Order) => (
        <a
          onClick={() => {
            onActionClick(order, 'order line items');
          }}
        >
          View details
        </a>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: string) => {
        const formattedDate = formatDate(text);
        return <p>{formattedDate}</p>;
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text: string) => {
        const formattedDate = formatDate(text);
        return <p>{formattedDate}</p>;
      },
    },
    {
      title: '',
      key: 'Product Print File',
      render: (order: Order) => {
        return (
          <Tooltip title="Product Print File" color="#23a89e">
            <PrinterOutlined
              className={styles.printerIcon}
              onClick={(event) => {
                event.stopPropagation();
                onActionClick(order, 'ProductPrintFile');
              }}
            />
          </Tooltip>
        );
      },
    },
  ];
};
export default columns;
