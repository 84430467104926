import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../../utils/axiosBaseQuery';
import { DataModel } from './data.model';
import { TrainingRulesModel } from './training.rules.model';

interface argsType {
  id: number;
  page: number;
}
export const trainingRulesApi = createApi({
  reducerPath: 'trainingRules',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['TrainingRules'],
  endpoints: (builder) => ({
    getTrainingRulesByTrainingId: builder.query<DataModel, argsType>({
      query: (object) => ({ url: `/training-rules/training/${object.id}?limit=10&page=${object.page}`, method: 'get' }),
      providesTags: ['TrainingRules'],
    }),
    deleteTrainingRules: builder.mutation<string, number>({
      query: (id: number) => ({ url: `/training-rules/${id}`, method: 'delete' }),
      invalidatesTags: ['TrainingRules'],
    }),
    createTrainingRules: builder.mutation<TrainingRulesModel, Partial<TrainingRulesModel>>({
      query: (data: TrainingRulesModel) => ({ url: `/training-rules`, method: 'POST', data: data }),
      invalidatesTags: ['TrainingRules'],
    }),
    updateTrainingRules: builder.mutation<TrainingRulesModel, Partial<TrainingRulesModel>>({
      query: (data: TrainingRulesModel) => ({ url: `/training-rules/${data.id}`, method: 'PATCH', data: data }),
      invalidatesTags: ['TrainingRules'],
    }),
  }),
});

export const {
  useGetTrainingRulesByTrainingIdQuery,
  useDeleteTrainingRulesMutation,
  useUpdateTrainingRulesMutation,
  useCreateTrainingRulesMutation,
} = trainingRulesApi;
