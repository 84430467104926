import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import {
  useGetBlankPrintLocationsQuery,
  useUpdateBlankPrintLocationsStatusMutation,
} from '../../redux/blankPrintLocations/blankPrintLocations.action';
import { BlankPrintLocations as BlankPrintLocationsType } from '../../redux/blankPrintLocations/blankPrintLocations.model';
import { setCurrentPage } from '../../redux/navigations/navigations.reducer';
import styles from '../blanks/Blanks.module.css';
import columns from './BlankPrintLocations.table';

const BlankPrintLocations = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const blank_id = Number(id);
  const [isBlankActive, setBlankActive] = useState<{ [key: string]: boolean }>({});
  const navigate = useNavigate();
  const { data: blankPrintLocations, isLoading } = useGetBlankPrintLocationsQuery({ blank_id });
  const [updateStatus] = useUpdateBlankPrintLocationsStatusMutation();

  useEffect(() => {
    if (blankPrintLocations) {
      const newState: { [key: string]: boolean } = {};
      blankPrintLocations.forEach((blankPrintLocation) => {
        if (blankPrintLocation.id && blankPrintLocation.active)
          newState[blankPrintLocation.id.toString()] = blankPrintLocation.active;
      });
      setBlankActive(newState);
    }
  }, [blankPrintLocations]);

  // Function to handle checkBox click
  const onCheckBoxClick = (blankPrintLocationsId: number) => {
    const isActive = { ...isBlankActive };
    isActive[blankPrintLocationsId.toString()] = !isBlankActive[blankPrintLocationsId.toString()];
    setBlankActive(isActive);

    // Update the server with the new checkBox state
    updateStatus({ blankPrintLocationsId, isActive: isActive[blankPrintLocationsId.toString()] });
  };

  const onActionClick = (blankPrintLocation: BlankPrintLocationsType | undefined, type: string | undefined) => {
    dispatch(setCurrentPage({ previousPageUrl: '/main/Blanks', previousPageName: 'previousPageOfBlankVariants' }));
    if (type === 'activation' && blankPrintLocation) {
      if (blankPrintLocation.id) onCheckBoxClick(blankPrintLocation.id);
    } else {
      navigate(`/main/blank-print-location-image-rules/${blankPrintLocation?.id}`);
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <span className={styles.vector} />
          </Col>
          <Col>
            <div className={styles.subHeader}>
              <p className={styles.users}>Blanks</p>
              <p className={styles.details}>/ Blank Print Locations</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>Blank Print Locations</p>
          </Col>
          <p className={styles.line}></p>
          <div className={styles.margins}></div>
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick, isBlankActive)}
          className={styles.table}
          dataSource={blankPrintLocations}
          scroll={{ x: 500 }}
          onRow={(blankPrintLocations: BlankPrintLocationsType) => {
            return {
              onClick: () => {
                onActionClick(blankPrintLocations, 'showImageRules');
              },
            };
          }}
        />
      </Row>
    </Row>
  );
};

export default BlankPrintLocations;
