import React from 'react';

import { useGetTrainingStatusesQuery } from '../../redux/trainings/training/training.actions';
import { PieCharts } from '../pieChart/Pie';
import { PieDescription } from '../pieDescription/PieDescription';
import { PieTitle } from '../pieTitle/PieTitle';
import styles from './DashboardMenu.module.css';

const Dashboard = () => {
  const { data } = useGetTrainingStatusesQuery('');

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        {data?.map((item, i) => {
          return (
            <div key={i}>
              <PieTitle title={item.title} />
              <div className={styles.columContainer}>
                <PieCharts data={item.data} />
                <PieDescription total={item.total} data={item.data} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
