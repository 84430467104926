import '../training/AddModal.css';

import { Button, Col, Form, Input, Modal, Row } from 'antd';

import { TrainingRequirementsModalTypes } from './Training.requirements.modal.types';

const TrainingRequirementsAddModal = ({
  onChangeInput,
  onSubmit,
  open,
  cancel,
  form,
}: TrainingRequirementsModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} footer={null} className="training-modal">
      <Row>
        <Col span={24} className={'textContainer'}>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={onSubmit}>
            <Row className={'row'}>
              <div>
                <Col span={22}>
                  <Form.Item
                    label="Width"
                    name="width"
                    rules={[{ required: true, message: 'Please input your width!' }]}
                  >
                    <Input
                      className="input"
                      name="width"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter width"
                    />
                  </Form.Item>
                </Col>
              </div>
              <div>
                <Col span={24}>
                  <Form.Item
                    label="Height"
                    name="height"
                    rules={[{ required: true, message: 'Please input your height!' }]}
                  >
                    <Input
                      className="input"
                      name="height"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter height"
                    />
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Row className={'row'}>
              <div>
                <Col span={22}>
                  <Form.Item label="Seed" name="seed" rules={[{ required: true, message: 'Please input your seed!' }]}>
                    <Input
                      className="input"
                      name="seed"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter seed"
                    />
                  </Form.Item>
                </Col>
              </div>

              <div>
                <Col span={24}>
                  <Form.Item
                    label="Samples"
                    name="samples"
                    rules={[{ required: true, message: 'Please input your sample!' }]}
                  >
                    <Input
                      className="input"
                      name="samples"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter sample"
                    />
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Row className={'row'}>
              <div>
                <Col span={22}>
                  <Form.Item
                    label="Scheduler"
                    name="scheduler"
                    rules={[{ required: true, message: 'Please input your scheduler!' }]}
                  >
                    <Input className="input" name="scheduler" onChange={onChangeInput} placeholder="Enter scheduler" />
                  </Form.Item>
                </Col>
              </div>

              <div>
                <Col span={24}>
                  <Form.Item
                    label="Guidance scale"
                    name="guidance_scale"
                    rules={[{ required: true, message: 'Please input your guidance scale!' }]}
                  >
                    <Input
                      className="input"
                      name="guidance_scale"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter guidance scale"
                    />
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Row className={'row'}>
              <div>
                <Col span={22}>
                  <Form.Item
                    label="Enhance prompt"
                    name="enhance_prompt"
                    rules={[{ required: true, message: 'Please input your enhance prompt!' }]}
                  >
                    <Input
                      className="input"
                      name="enhance_prompt"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter enhance prompt"
                    />
                  </Form.Item>
                </Col>
              </div>

              <div>
                <Col span={24}>
                  <Form.Item
                    label="Negative prompt"
                    name="negative_prompt"
                    rules={[{ required: true, message: 'Please input your negative prompt!' }]}
                  >
                    <Input
                      className="input"
                      name="negative_prompt"
                      onChange={onChangeInput}
                      placeholder="Enter negative prompt"
                    />
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Row className={'row'}>
              <div>
                <Col>
                  <Form.Item
                    label="Num inference steps"
                    name="num_inference_steps"
                    rules={[{ required: true, message: 'Please input your num inference steps!' }]}
                  >
                    <Input
                      className="input"
                      name="num_inference_steps"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter num inference steps"
                    />
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Form.Item className={'enterContainer'}>
              <Button type={'primary'} htmlType="submit" className={'submitButton'}>
                Enter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default TrainingRequirementsAddModal;
