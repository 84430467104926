import './Gimp.css';

import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Modal, Select, Table, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import arrow from '../../assets/arrow.svg';
import GimpModal from '../../component/modals/gimpModal/gimpModal.modal';
import GimpPerspectiveModal from '../../component/modals/gimpModal/GimpPerspectiveModal.modal';
import { useCreateGimpMutation } from '../../redux/gimp/gimp.action';
import { getBase64 } from '../../utils/antdUpload';
import styles from './Gimp.module.css';
import { perspectiveData } from './Gimp.types';
import { layerColumns } from './GimpLayerTable.table';
import { perspectiveColumns } from './GimpPerspectiveTable.table';

const Gimp = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [perspectiveForm] = Form.useForm();
  const [openAddLayerModal, setOpenAddLayerModal] = useState(false);
  const [imageFileList, setImageFileList] = useState<UploadFile[]>([]);
  const [xcfFileList, setXcfFileList] = useState<UploadFile[]>([]);
  const [fileType, setFileType] = useState('Select file type');
  const [layers, setLayers] = useState([{ key: '', name: '' }]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [openAddPerspectiveModal, setOpenAddPerspectiveModal] = useState(false);
  const perspectiveInitialState = {
    topLeftX: '',
    topLeftY: '',
    topRightX: '',
    topRightY: '',
    bottomLeftX: '',
    bottomLeftY: '',
    bottomRightX: '',
    bottomRightY: '',
  };
  const [perspectiveData, setPerspectiveData] = useState<perspectiveData[]>([perspectiveInitialState]);
  const [addTrigger] = useCreateGimpMutation();

  const onSubmitClick = () => {
    const updatedPerspectiveData = perspectiveData.slice(1);
    const updatedLayers = layers.slice(1);

    const layerNames = updatedLayers.map((layer) => layer.name);

    const formattedPerspectiveArray = updatedPerspectiveData.map(
      ({ topLeftX, topLeftY, topRightX, topRightY, bottomLeftX, bottomLeftY, bottomRightX, bottomRightY }) => {
        return [topLeftX, topLeftY, topRightX, topRightY, bottomLeftX, bottomLeftY, bottomRightX, bottomRightY].join(
          ' ',
        );
      },
    );

    addTrigger({
      fileType: fileType,
      imageFileList: imageFileList,
      layers: layerNames,
      perspectiveData: formattedPerspectiveArray,
      xcfFileList: xcfFileList,
    });

    setPerspectiveData([perspectiveInitialState]);
    setLayers([{ key: '', name: '' }]);
    setFileType('Select file type');
    setXcfFileList([]);
    setImageFileList([]);
  };

  const onCreatePerspectiveClick = () => {
    setOpenAddPerspectiveModal(true);
  };

  const closeAddPerspectiveModal = () => {
    setOpenAddPerspectiveModal(false);
  };

  const onDeletePerspectiveClick = (index: number | undefined) => {
    setPerspectiveData((prevData) => {
      if (typeof index === 'number') {
        const newPerspectiveData = [...prevData];
        newPerspectiveData.splice(index, 1);
        return newPerspectiveData;
      }
      return prevData;
    });
  };

  const onAddLayerClick = () => {
    setOpenAddLayerModal(true);
  };
  const closeAddLayerModal = () => {
    setOpenAddLayerModal(false);
  };

  const onDeleteClick = (index: number | undefined) => {
    setLayers((prevData) => {
      if (index !== undefined) {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      }
      return prevData;
    });
  };

  const onAddPerspectiveClick = () => {
    perspectiveForm.validateFields().then((values) => {
      setPerspectiveData((prevData) => {
        const newPerspectiveItem = {
          topLeftX: values.topLeftX,
          topLeftY: values.topLeftY,
          topRightX: values.topRightX,
          topRightY: values.topRightY,
          bottomLeftX: values.bottomLeftX,
          bottomLeftY: values.bottomLeftY,
          bottomRightX: values.bottomRightX,
          bottomRightY: values.bottomRightY,
        };

        const newPerspectiveData = [...prevData, newPerspectiveItem];
        perspectiveForm.resetFields();
        closeAddPerspectiveModal();

        return newPerspectiveData;
      });
    });
  };

  const onAddLayer = () => {
    form.validateFields().then((values) => {
      const name = values.name;
      const uniqueKey = Date.now().toString();
      setLayers((prevData) => {
        const newData = [...prevData, { key: uniqueKey, name: name }];
        return newData;
      });
      form.resetFields();
      closeAddLayerModal();
    });
  };

  const handleXcfChange = (event: { fileList: SetStateAction<UploadFile[]> }) => {
    setXcfFileList(event?.fileList);
  };

  const handleFileTypeChange = (value: string) => {
    setFileType(value);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileList: any = newFileList.map((file) => (file.originFileObj ? { ...file, status: 'done' } : file));
    setImageFileList(updatedFileList);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <img alt={'Arrow'} src={arrow} className={styles.vector} onClick={() => navigate(-1)} />
          </div>
          <div>
            <div className={styles.subHeader}>
              <p className={styles.users}>Blanks </p>
              <p className={styles.details}>/ Blank Variants </p>
              <p className={styles.details}> / Gimp</p>
            </div>
          </div>
        </div>

        <div className={styles.tables}>
          <div className={styles.layerTable}>
            <Button onClick={onAddLayerClick} className={styles.addLayerButton}>
              Add Layers
            </Button>
            <Form form={form} name="layerForm">
              <Table
                dataSource={layers.slice(1)}
                columns={layerColumns(onDeleteClick)}
                pagination={{ pageSize: 3 }}
                rootClassName={layers.length > 1 ? 'antd_ThereIsData' : 'antd_noData'}
              />
            </Form>
          </div>
          <div className={styles.perspectiveTable}>
            <Button
              onClick={onCreatePerspectiveClick}
              disabled={layers.length <= 1 || perspectiveData.length >= layers.length}
              className={styles.addPerspectiveButton}
            >
              Add Perspective Data
            </Button>
            <Form form={perspectiveForm} name="perspectiveForm">
              <Table
                dataSource={perspectiveData.slice(1).map((data, index) => ({ ...data, key: index }))}
                columns={perspectiveColumns(onDeletePerspectiveClick)}
                pagination={{ pageSize: 3 }}
              />
            </Form>
          </div>
        </div>

        <div className={styles.images}>
          <div className={styles.imageList}>
            <Upload listType="picture-card" fileList={imageFileList} onPreview={handlePreview} onChange={handleChange}>
              {layers.length <= 1 || imageFileList.length >= layers.length - 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div className={styles.uploadImage}>Upload</div>
                </div>
              )}
            </Upload>
          </div>
          <div className={styles.mockup}>
            <Image
              width={200}
              height={200}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
          </div>
        </div>

        <div className={styles.tools}>
          <div className={styles.toolItem}>
            <div>
              <Select
                listHeight={120}
                placeholder={'Select the file type'}
                onChange={handleFileTypeChange}
                value={fileType}
              >
                <Select.Option value="png">PNG</Select.Option>
                <Select.Option value="jpg">JPG</Select.Option>
                <Select.Option value="jpeg">JPEG</Select.Option>
              </Select>
            </div>
            <div>
              <Upload showUploadList={false} onChange={handleXcfChange} fileList={xcfFileList}>
                <Button icon={<UploadOutlined />}>Upload XCF File</Button>
              </Upload>
            </div>
            <div>
              <Button
                disabled={
                  perspectiveData.length - 1 < layers.length - 1 ||
                  layers.length < 1 ||
                  fileType === 'Select file type' ||
                  xcfFileList.length === 0 ||
                  imageFileList.length < layers.length - 1
                }
                onClick={onSubmitClick}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <GimpModal open={openAddLayerModal} cancel={closeAddLayerModal} form={form} action={onAddLayer} />
      <GimpPerspectiveModal
        open={openAddPerspectiveModal}
        cancel={closeAddPerspectiveModal}
        form={perspectiveForm}
        action={onAddPerspectiveClick}
      />
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" className={styles.imagePreview} src={previewImage} />
      </Modal>
    </div>
  );
};

export default Gimp;
