import { Col, Form, Row, Table } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import DeleteModal from '../../component/modals/delete/Delete.modal';
import RefundOrderModal from '../../component/modals/refundOrder/RefundOrderModal';
import ResponseModal from '../../component/modals/responseModal/ResponseModal';
import useBackNavigation from '../../hooks/useBackNavigation';
import { setCurrentPage } from '../../redux/navigations/navigations.reducer';
import { RootState } from '../../redux/navigations/navigations.type';
import {
  useDeleteOrderMutation,
  useGetOrderByUserIdQuery,
  useGetOrdersQuery,
  useOrderRefundMutation,
} from '../../redux/orders/orders.actions';
import { Order as OrderType } from '../../redux/orders/orders.model';
import styles from '../orders/Order.module.css';
import columns from './Order.table';

const Order = () => {
  const { id } = useParams();
  const user_id = Number(id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalPage = useSelector((state: RootState) => state.pagination.pages.ordersCurrentPage);
  const [form] = Form.useForm();
  const [orderId, setOrderId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [modalData, setModalData] = useState({
    open: false,
    message: '',
    messageHeader: '',
  });
  const { data: orders } =
    id !== undefined ? useGetOrderByUserIdQuery({ user_id, page: globalPage }) : useGetOrdersQuery(globalPage);
  const [deleteTrigger] = useDeleteOrderMutation();
  const [refund] = useOrderRefundMutation();
  useBackNavigation('ordersCurrentPage');

  const onPaginate = (page: number) => {
    dispatch(setCurrentPage({ pageNumber: page, pageName: 'ordersCurrentPage' }));
  };

  const onActionClick = (order: OrderType | undefined, type: string | undefined) => {
    switch (type) {
      case 'refund':
        if (order && order?.id) {
          setOrderId(order.id);
          setOpenRefundModal(true);
        }

        break;
      case 'delete':
        if (order && order?.id) {
          setCurrentId(order.id);
          setOpenDeleteModal(true);
        }
        break;

      case 'showInfo':
        if (order && order?.id) {
          navigate(`/main/orders/order-information/${order?.id}`);
        }
        break;

      default:
      // Handle any other cases here
    }
  };

  const triggerRefund = async () => {
    const refundAmount = parseInt(form.getFieldValue('amount'));
    const refundType = form.getFieldValue('type');
    const response = await refund({ orderId, refundAmount, refundType });
    if ('data' in response) {
      setModalData({
        open: true,
        message: 'Amount successfully refunded ',
        messageHeader: 'Success!',
      });
    } else {
      setModalData({
        open: true,
        message: 'Error occurred while refunding. Please try again. ',
        messageHeader: 'Error',
      });
    }
  };

  const onClickDelete = () => {
    deleteTrigger(currentId);
    setOpenDeleteModal(false);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeRefundModal = () => {
    setOpenRefundModal(false);
  };

  const cancelResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  return (
    <>
      <Row className={styles.mainContainer}>
        <Row className={styles.container}>
          {id !== undefined && (
            <Row className={styles.header}>
              <Col>
                <div className={styles.subHeader}>
                  <p className={styles.users}>Users</p>
                  <p className={styles.details}>/ Order </p>
                </div>
              </Col>
            </Row>
          )}
          <Row className={styles.detailsTitle}>
            <Col className={styles.title}>
              <p>Orders</p>
            </Col>
            <Col>
              <p className={styles.line}></p>
            </Col>
            <div className={styles.margins}></div>
          </Row>
          <Row className={styles.tableContainer}>
            <Table
              rowKey="id"
              columns={columns(onActionClick)}
              className={styles.table}
              dataSource={orders?.items}
              scroll={{ x: 500 }}
              pagination={{
                current: globalPage,
                pageSize: orders?.meta?.itemsPerPage ?? 0,
                total: orders?.meta?.totalItems ?? 0,
                onChange: onPaginate,
              }}
            />
          </Row>
        </Row>
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
        <RefundOrderModal
          open={openRefundModal}
          cancel={closeRefundModal}
          form={form}
          action={triggerRefund}
        ></RefundOrderModal>
        <ResponseModal cancel={cancelResponseModal} {...modalData} />
      </Row>
    </>
  );
};
export default Order;
