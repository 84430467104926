import { createApi } from '@reduxjs/toolkit/query/react';
import FormData from 'form-data';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import {
  BlankVariantMockupProviderModel,
  BlankVariantMockupsModel,
  BlankVariantMockupStages,
  BlankVariantMockupStagesModel,
} from '../blanks/blanks.model';

interface PreviewImageType {
  data: {
    //image url
    url: string;
  };
}

interface Stage {
  // X coordinates
  x: number;
  // y coordinates
  y: number;
  // stage width
  width: number;
  // stage height
  height: number;
  // stage id
  id?: number;
}

export interface FormDataObject {
  formData: FormData;
}

export const previewApi = createApi({
  reducerPath: 'upload',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['getMockup'],
  endpoints: (builder) => ({
    getBlankVariantProvider: builder.query<BlankVariantMockupProviderModel, number>({
      query: (page) => ({ url: `/blank-variant-providers?limit=10&page=${page}`, method: 'get' }),
    }),

    getBlankVariantMockups: builder.query<BlankVariantMockupsModel, { blank_variant_id: number; page: number }>({
      query: (data: { blank_variant_id: number; page: number }) => ({
        url: `/blank-variant-mockups/${data.blank_variant_id}?limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['getMockup'],
    }),

    deleteBlankVariantMockup: builder.mutation<{ success: string }, number>({
      query: (id: number) => ({ url: `/blank-variant-mockups/${id}`, method: 'delete' }),
      invalidatesTags: ['getMockup'],
    }),

    getBlankVariantMockupById: builder.query<
      BlankVariantMockupStagesModel,
      { blank_variant_mockup_id: number; page: number }
    >({
      query: (data: { blank_variant_mockup_id: number; page: number }) => ({
        url: `/blank-variant-mockups/by/${data.blank_variant_mockup_id}?limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['getMockup'],
    }),

    deleteBlankVariantMockupStage: builder.mutation<{ success: string }, number>({
      query: (id: number) => ({ url: `/blank-variant-mockup-stages/${id}`, method: 'delete' }),
      invalidatesTags: ['getMockup'],
    }),

    postMockupPreview: builder.mutation<PreviewImageType, FormDataObject>({
      query: (data: { formData: FormData }) => ({
        headers: {
          'content-type': 'multipart/form-data',
          accept: '/',
        },
        url: `/mockups/preview`,
        method: 'POST',
        data: data.formData,
      }),
    }),

    updateBlankVariantMockupStage: builder.mutation<{ success: string }, { stageInfo: Stage; stageId: number }>({
      query: ({ stageInfo, stageId }) => ({
        url: `/blank-variant-mockup-stages/${stageId}`,
        method: 'PATCH',
        data: stageInfo,
      }),
      invalidatesTags: ['getMockup'],
    }),

    addBlankVariantMockupStage: builder.mutation<
      { blankVariantMockupStages: BlankVariantMockupStages },
      { stageInfo: Stage }
    >({
      query: ({ stageInfo }) => ({
        url: `/blank-variant-mockup-stages`,
        method: 'POST',
        data: stageInfo,
      }),
      invalidatesTags: ['getMockup'],
    }),

    postBlankVariantMockup: builder.mutation<{ success: string }, FormDataObject>({
      query: (data: { formData: FormData }) => ({
        headers: {
          'content-type': 'multipart/form-data',
          accept: '/',
        },
        url: `/blank-variant-mockups`,
        method: 'POST',
        data: data.formData,
      }),
      invalidatesTags: ['getMockup'],
    }),
  }),
});

export const {
  useDeleteBlankVariantMockupMutation,
  useDeleteBlankVariantMockupStageMutation,
  useAddBlankVariantMockupStageMutation,
  useUpdateBlankVariantMockupStageMutation,
  useGetBlankVariantMockupByIdQuery,
  useGetBlankVariantMockupsQuery,
  useGetBlankVariantProviderQuery,
  usePostMockupPreviewMutation,
  usePostBlankVariantMockupMutation,
} = previewApi;
