import { Button, Col, Form, Input, Modal, Row } from 'antd';

import styles from './gimpModal.modal.module.css';
import { GimpModalType } from './gimpModal.modal.types';
const GimpModal = ({ open, cancel, form, action }: GimpModalType) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> Add Layers </h1>
          </div>
          <Form form={form} layout={'vertical'} autoComplete="off" onFinish={action}>
            <Form.Item
              label="name"
              name="name"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input className={styles.input} name="name" type={'text'} min={0} placeholder="Enter name" />
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default GimpModal;
