import '../../containers/users/models/Antd.css';

import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import searchIcon from '../../assets/searchIcon.svg';
import { CategoryType } from '../../containers/modals/trainingtext/Training.category.type';
import TrainingTextModal from '../../containers/modals/trainingtext/TrainingTextModal';
import useBackNavigation from '../../hooks/useBackNavigation';
import { setCurrentPage } from '../../redux/navigations/navigations.reducer';
import { RootState } from '../../redux/navigations/navigations.type';
import {
  useDeleteTrainingMutation,
  useGetAllTrainingPromptCategoryQuery,
  useGetTrainingPromptQuery,
  usePostTrainingMutation,
  useUpdateTrainingMutation,
} from '../../redux/trainings/trainingText/trainingText.actions';
import { TrainingModel } from '../../redux/trainings/trainingText/trainingText.model';
import SmallLoading from '../loadings/smallLoading/SmallLoading';
import DeleteModal from '../modals/delete/Delete.modal';
import styles from './TrainingText.module.css';
import trainingTableColumns from './TrainingText.table';

const TrainingText = () => {
  const { id } = useParams();
  const placeHolders = ['ID', 'Text'];
  const navigate = useNavigate();
  const trainingId = parseInt(id as string);
  const dispatch = useDispatch();
  const globalPage = useSelector((state: RootState) => state.pagination.pages.trainingTextCurrentPage);
  const [form] = Form.useForm();

  const initialState = {
    text: '',
    training_id: trainingId,
    id: trainingId,
    training_prompt_category_id: '',
    trainingPromptCategory: { category: { id: 1, name: 'Default' } },
  };

  const [openModal, setOpenModal] = useState(false);
  const [textId, setTextId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [trainingState, setTrainingState] = useState({
    data: initialState,
    type: 'create',
    inputValues: initialState,
  });
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  useBackNavigation('trainingTextCurrentPage');
  const text = inputValues['Text'];
  const searchTextId = inputValues['ID'];
  const { data: trainingData, isLoading } = useGetTrainingPromptQuery({
    id: trainingId,
    searchTextId,
    text,
    page: globalPage,
  });
  const [categoryPage, setCategoryPage] = useState(1);
  const { data: trainingCategory } = useGetAllTrainingPromptCategoryQuery(categoryPage);
  const [deleteTraining] = useDeleteTrainingMutation();
  const [postTraining] = usePostTrainingMutation();
  const [updateTraining] = useUpdateTrainingMutation();

  const loadedCategoryData = trainingCategory?.items;
  const [categories, setCategories] = useState(loadedCategoryData || []);

  useEffect(() => {
    if (loadedCategoryData) {
      const newLoadedData = loadedCategoryData.filter((item) => {
        return !categories.some((categories) => categories.id === item.id);
      });
      if (newLoadedData.length > 0) {
        setCategories((prevData) => [...prevData, ...newLoadedData]);
      }
    }
  }, [loadedCategoryData]);

  useEffect(() => {
    if (openModal) {
      form.setFieldsValue(trainingState.type === 'create' ? trainingState.inputValues : trainingState.data);
    }
  }, [openModal]);

  const onCategoryScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.target as HTMLDivElement;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
      if (loadedCategoryData && loadedCategoryData.length > 0) {
        const nextPage = categoryPage + 1;
        setCategoryPage(nextPage);
      }
    }
  };

  const handleTextSubmit = async (data: CategoryType) => {
    const { type } = trainingState;
    if (type === 'create') {
      await postTraining({
        text: data?.text,
        training_id: trainingId,
        training_prompt_category_id: data?.training_category?.value,
      }).unwrap();
      setOpenModal(false);
    } else if (type === 'edit') {
      await updateTraining({
        id: textId,
        text: data?.text,
        training_id: trainingId,
        training_prompt_category_id: data?.training_category?.value,
      }).unwrap();
      setOpenModal(false);
    }
    form.resetFields();
  };

  const onPaginate = (page: number) => {
    dispatch(setCurrentPage({ pageNumber: page, pageName: 'trainingTextCurrentPage' }));
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const onCreateClick = () => {
    setOpenModal(true);
    setTrainingState({ ...trainingState, type: 'create' });
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onClickDelete = () => {
    deleteTraining(textId);
    setOpenDeleteModal(false);
  };

  const onActionClick = (data: TrainingModel, type: string) => {
    if (type === 'delete') {
      setTextId(data.id);
      setOpenDeleteModal(true);
    } else if (type === 'edit') {
      setTextId(data.id);
      setTrainingState({ ...trainingState, data, type });
      setOpenModal(true);
    }
  };

  useEffect(() => {
    if (trainingState.type === 'edit') {
      if (form) {
        form.setFieldValue('training_category', {
          value: trainingState?.data?.trainingPromptCategory?.category?.id,
          name: trainingState?.data?.trainingPromptCategory?.category?.name,
          key: trainingState?.data?.trainingPromptCategory?.category?.id,
        });
      }
    }
  }, [trainingState.type, form, openModal, loadedCategoryData]);

  const onIconClick = (id: number, type: string) => {
    if (type === 'rules') {
      navigate(`requirements/${id}`);
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <span className={styles.vector} />
          <Col>
            <div className={styles.subHeader}>
              <p className={styles.tools}>Tools /</p>
              <p className={styles.training}>Training</p>
              <p className={styles.details}>/ Training Text</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>
              Tools <span className={styles.trainingText}> - Training Text </span>
            </p>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <Col>
            <p className={styles.closeModelText}>Close</p>
          </Col>
        </Row>
        <Row className={styles.search}>
          <Col className={styles.inputsColumn}>
            <img alt={'Search'} src={searchIcon} className={styles.searchIconImage} />
            <span>Search</span>
          </Col>
          {placeHolders.map((placeHolder, i) => (
            <Col key={i}>
              <input
                className={styles.inputs}
                placeholder={placeHolder}
                onChange={(e) => {
                  setInputValues((prevInputValues) => ({
                    ...prevInputValues,
                    [placeHolder]: e.target.value,
                  }));
                }}
              />
            </Col>
          ))}
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={trainingTableColumns(onActionClick, onIconClick)}
          className={styles.table}
          dataSource={trainingData?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: globalPage,
            pageSize: trainingData?.meta?.itemsPerPage ?? 0,
            total: trainingData?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
        <Row>
          <Tooltip title="Add Text" color="#23a89e">
            <FloatButton
              shape="circle"
              className={'floatingButton'}
              icon={<PlusOutlined className={'plusIcon'} />}
              onClick={onCreateClick}
            />
          </Tooltip>
          <TrainingTextModal
            form={form}
            open={openModal}
            cancel={handleCancel}
            inputValues={trainingState.type === 'create' ? trainingState.inputValues : trainingState.data}
            handleTextSubmit={handleTextSubmit}
            onCategoryScroll={onCategoryScroll}
            categories={categories}
          ></TrainingTextModal>
          <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
        </Row>
      </Row>
    </Row>
  );
};

export default TrainingText;
