import { Button, Col, Form, Input, Row, Select, Spin, Switch, Table } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import ResponseModal from '../../../component/modals/responseModal/ResponseModal';
import { useFilterAndSetData } from '../../../customHooks/usePaginateData';
import {
  useCreateExperimentMutation,
  useGetPromptCategoryQuery,
  useGetPromptQuery,
  useGetProvidersQuery,
} from '../../../redux/lab/lab.actions';
import { Prompt } from '../../../redux/lab/lab.model';
import { CreateLabPayload } from '../../../redux/lab/lab.model';
import { useGetModelsQuery } from '../../../redux/models/models.actions';
import { Models } from '../../../redux/models/models.model';
import { useGetTrainingQuery } from '../../../redux/trainings/training/training.actions';
import { dispatchPageUpdate, handleScroll } from '../../../utils/helpers';
import styles from '../../blanks/Blanks.module.css';
import columns from '../../models/Models.table';
import styling from './CreateLab.module.css';
const CreateExperiment = () => {
  const { id } = useParams();
  const labId = Number(id);

  const [pages, setPages] = useState({
    promptCategoryPage: 1,
    modelsPage: 1,
    promptPage: 1,
    providersPage: 1,
    trainingTypePage: 1,
  });
  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const [trainingType, setTrainingType] = useState(0);
  const [promptCategoryId, setPromptCategoryId] = useState(0);
  const { data: prompts, isFetching: isPromptFetching } = useGetPromptQuery({
    trainingType: trainingType,
    promptPage: pages.promptPage,
    trainingPromptCategoryId: promptCategoryId,
  });
  const { data: modelTypes, isFetching: isModelTypesFetching } = useGetTrainingQuery({ page: pages.trainingTypePage });
  const { data: provider, isFetching: isProviderFetching } = useGetProvidersQuery({ page: pages.providersPage });
  const { data: models, isLoading } = useGetModelsQuery({ trainingType: trainingType, page: pages.modelsPage });
  const { data: promptCategory, isFetching: isPromptCategoryFetching } = useGetPromptCategoryQuery({
    trainingId: trainingType,
    page: pages.promptCategoryPage,
  });
  const [addTrigger, { isLoading: isAddLoading }] = useCreateExperimentMutation();
  const [responseModal, setResponseModal] = useState({
    open: false,
    message: '',
  });
  const loadedProviders = provider?.items || [];
  const [providers, setProviders] = useState(loadedProviders || []);
  const loadedPrompts = prompts?.items || [];
  const [trainingPrompts, setTrainingPrompts] = useState(loadedPrompts || []);
  const loadedTrainingTypes = modelTypes?.items || [];
  const [trainingTypes, setTrainingTypes] = useState(loadedTrainingTypes || []);
  const loadedPromptCategory = promptCategory?.items || [];
  const [promptCategories, setPromptCategories] = useState(loadedPromptCategory || []);
  const [isSwitchClicked, setIsSwitchClicked] = useState(false);
  const [form] = Form.useForm();
  const [selectedModelIds, setSelectedModelIds] = useState<number[]>([]);
  const [promptReq, setPromptReq] = useState<Omit<CreateLabPayload, 'models'>>({
    labId: labId,
    width: -1,
    height: -1,
    seed: '',
    samples: -1,
    scheduler: '',
    guidanceScale: -1,
    enhancePrompt: true,
    negativePrompt: '',
    numInferenceSteps: -1,
    prompt: '',
    name: '',
    description: '',
    providerId: -1,
  });
  const prevTrainingType = useRef(trainingType);
  const prevPromptCategoryId = useRef(promptCategoryId);
  const prevLoadedPrompts = useRef(loadedPrompts);

  useEffect(() => {
    if (
      (trainingType !== prevTrainingType.current || promptCategoryId !== prevPromptCategoryId.current) &&
      loadedPrompts !== prevLoadedPrompts.current
    ) {
      setTrainingPrompts([]);
    }

    if (!isPromptFetching) {
      prevTrainingType.current = trainingType;
      prevPromptCategoryId.current = promptCategoryId;
      prevLoadedPrompts.current = loadedPrompts;
    }
  }, [loadedPrompts, isPromptFetching]);

  useFilterAndSetData<Prompt>(prompts?.items || [], setTrainingPrompts, trainingPrompts, 'id');
  useFilterAndSetData(modelTypes?.items || [], setTrainingTypes, trainingTypes, 'id');
  useFilterAndSetData(provider?.items || [], setProviders, providers, 'id');
  useFilterAndSetData(promptCategory?.items || [], setPromptCategories, promptCategories, 'category');

  const handlePromptsScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isPromptFetching) {
      const dispatch = dispatchPageUpdate(setPages, 'promptPage');
      handleScroll(e, loadedPrompts, dispatch, pages.promptPage);
    }
    setTarget(e.target as HTMLDivElement);
  };

  const handleTrainingTypesScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isModelTypesFetching) {
      const dispatch = dispatchPageUpdate(setPages, 'trainingTypePage');
      handleScroll(e, loadedTrainingTypes, dispatch, pages.trainingTypePage);
    }
  };

  const handleProvidersScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isProviderFetching) {
      const dispatch = dispatchPageUpdate(setPages, 'providersPage');
      handleScroll(e, loadedProviders, dispatch, pages.providersPage);
    }
  };

  const handlePromtCategoriesScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isPromptCategoryFetching) {
      const dispatch = dispatchPageUpdate(setPages, 'promptCategoryPage');
      handleScroll(e, loadedPromptCategory, dispatch, pages.promptCategoryPage);
    }
  };

  const onPaginate = (page: number) => {
    setPages((prevState) => ({
      ...prevState,
      modelsPage: page,
    }));
    window.scrollTo(0, 0);
  };

  const handleRowClick = (record: Models) => {
    setSelectedModelIds((prevSelectedModelIds) => {
      const modelId = record.id;
      const isSelected = prevSelectedModelIds.includes(modelId);

      if (isSelected) {
        return prevSelectedModelIds.filter((id) => id !== modelId);
      } else {
        return [...prevSelectedModelIds, modelId];
      }
    });
  };

  const handleSubmit = async () => {
    try {
      const apiResponse = await addTrigger({
        ...promptReq,
        models: selectedModelIds,
      });

      let message: string | undefined;

      if ('data' in apiResponse) {
        message = 'Success';
      }
      if ('error' in apiResponse) {
        if ('data' in apiResponse.error) {
          message = apiResponse.error.data.message;
        }
      }
      setResponseModal((prevState) => ({
        ...prevState,
        open: true,
        message: message ?? '',
      }));
    } catch (error) {
      // Handle error
    }
  };

  const handleModalClose = () => {
    setResponseModal((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const fetchPrompts = () => {
    setIsSwitchClicked((prevIsSelectClicked) => !prevIsSelectClicked);
  };

  const handleSelectChange = (selectedPromptId: string | number) => {
    const selectedPrompt = trainingPrompts.find((item) => item.text === selectedPromptId);

    if (selectedPrompt && selectedPrompt.trainingPromptReqs.length > 0) {
      setPromptReq((prevFormValues) => ({
        ...prevFormValues,
        width: selectedPrompt.trainingPromptReqs[0].width,
        height: selectedPrompt.trainingPromptReqs[0].height,
        seed: selectedPrompt.trainingPromptReqs[0].seed.toString(),
        samples: selectedPrompt.trainingPromptReqs[0].samples,
        scheduler: selectedPrompt.trainingPromptReqs[0].scheduler,
        guidanceScale: selectedPrompt.trainingPromptReqs[0].guidance_scale,
        enhancePrompt: selectedPrompt.trainingPromptReqs[0].enhance_prompt,
        negativePrompt: selectedPrompt.trainingPromptReqs[0].negative_prompt,
        numInferenceSteps: selectedPrompt.trainingPromptReqs[0].num_inference_steps,
        prompt: selectedPrompt.text,
      }));

      form.setFieldsValue({
        width: selectedPrompt.trainingPromptReqs[0].width,
        height: selectedPrompt.trainingPromptReqs[0].height,
        seed: selectedPrompt.trainingPromptReqs[0].seed.toString(),
        samples: selectedPrompt.trainingPromptReqs[0].samples,
        scheduler: selectedPrompt.trainingPromptReqs[0].scheduler,
        guidanceScale: selectedPrompt.trainingPromptReqs[0].guidance_scale,
        enhancePrompt: selectedPrompt.trainingPromptReqs[0].enhance_prompt,
        negativePrompt: selectedPrompt.trainingPromptReqs[0].negative_prompt,
        numInferenceSteps: selectedPrompt.trainingPromptReqs[0].num_inference_steps,
      });
    }
  };

  const handleSelectOpen = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleSelectClose = () => {
    document.body.style.overflow = '';
    const select = target;
    if (select) {
      select.scrollTop = 0;
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <p className={styles.title}>Lab</p>
        <Col>
          <p className={styles.line}></p>
        </Col>
        <div className={styles.separator}></div>

        <SmallLoading isLoading={isLoading} />
        <div className={styling.subcontainers}>
          <Form
            onFinish={handleSubmit}
            onValuesChange={(changedValues) => {
              if (changedValues.trainingType) {
                setTrainingType(changedValues.trainingType);
                setTrainingPrompts([]);
                setSelectedModelIds([]);
                setPages((prevState) => ({
                  ...prevState,
                  promptPage: 1,
                  modelsPage: 1,
                }));

                setPromptCategories([]);
              }
              setPromptReq((prevFormValues) => {
                const { trainingType, ...otherValues } = changedValues;
                return {
                  ...prevFormValues,
                  ...otherValues,
                };
              });
            }}
            className={styling.form}
          >
            <Form.Item name="name" rules={[{ required: true, message: 'Name is required' }]} className={styling.items}>
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              name="description"
              rules={[{ required: true, message: 'Description is required' }]}
              className={styling.description}
            >
              <Input placeholder="Description" />
            </Form.Item>

            <Form.Item name="trainingType" className={styling.items}>
              <Select
                placeholder="Training Type"
                onPopupScroll={handleTrainingTypesScroll}
                onDropdownVisibleChange={(open) => (open ? handleSelectOpen() : handleSelectClose())}
              >
                {trainingTypes.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
                {isModelTypesFetching && <Select.OptGroup label={<Spin className={styling.loading} />} />}
              </Select>
            </Form.Item>

            <Form.Item
              name="providerId"
              rules={[{ required: true, message: 'Provider is required' }]}
              className={styling.items}
            >
              <Select
                placeholder="Provider"
                onPopupScroll={handleProvidersScroll}
                onDropdownVisibleChange={(open) => (open ? handleSelectOpen() : handleSelectClose())}
              >
                {providers.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
                {isProviderFetching && <Select.OptGroup label={<Spin className={styling.loading} />} />}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div className={styling.subcontainers}>
          <Form
            initialValues={{
              enhancePrompt: true,
            }}
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            onValuesChange={(changedValues) => {
              setPromptReq((prevFormValues) => ({
                ...prevFormValues,
                ...changedValues,
              }));
            }}
            className={styling.form}
          >
            <Form.Item label="Width" name="width" rules={[{ required: true, message: 'Width is required' }]}>
              <Input placeholder="Width" type="number" />
            </Form.Item>

            <Form.Item label="Height" name="height" rules={[{ required: true, message: 'Height is required' }]}>
              <Input placeholder="Height" type="number" />
            </Form.Item>

            <Form.Item label="Seed" name="seed" rules={[{ required: true, message: 'Seed is required' }]}>
              <Input placeholder="Seed" />
            </Form.Item>

            <Form.Item label="Samples" name="samples" rules={[{ required: true, message: 'Samples is required' }]}>
              <Input placeholder="Samples" type="number" />
            </Form.Item>

            <Form.Item
              label="Scheduler"
              name="scheduler"
              rules={[{ required: true, message: 'Scheduler is required' }]}
            >
              <Input placeholder="Scheduler" />
            </Form.Item>

            <Form.Item
              label="Guidance scale"
              name="guidanceScale"
              rules={[{ required: true, message: 'Guidance scale is required' }]}
            >
              <Input placeholder="Guidance scale" type="number" />
            </Form.Item>

            <Form.Item
              label="Enhance prompt"
              name="enhancePrompt"
              rules={[{ required: true, message: 'Enhance prompt is required' }]}
            >
              <Select placeholder="Select enhance prompt">
                <Select.Option value={true}>True</Select.Option>
                <Select.Option value={false}>False</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Negative prompt" name="negativePrompt">
              <Input placeholder="Negative prompt" />
            </Form.Item>

            <Form.Item
              label="Num inference steps"
              name="numInferenceSteps"
              rules={[{ required: true, message: 'Num inference steps is required' }]}
            >
              <Input placeholder="Num inference steps" type="number" />
            </Form.Item>
          </Form>
        </div>

        <div className={styling.promptContainer}>
          <div className={styling.promptFormContainer}>
            <Form
              onFinish={handleSubmit}
              onValuesChange={(changedValues) => {
                setPromptReq((prevPromptReq) => ({
                  ...prevPromptReq,
                  ...changedValues,
                }));
              }}
            >
              {!isSwitchClicked ? (
                <Form.Item name="prompt" rules={[{ required: true, message: 'Prompt is required' }]}>
                  <TextArea placeholder="Prompt" autoSize className={styling.promptFormItems} />
                </Form.Item>
              ) : (
                <Form.Item name="prompt" rules={[{ required: true, message: 'Prompt is required' }]}>
                  <Select
                    placeholder="Select Prompt"
                    onPopupScroll={handlePromptsScroll}
                    onChange={handleSelectChange}
                    onDropdownVisibleChange={(open) => (open ? handleSelectOpen() : handleSelectClose())}
                    className={styling.promptFormItems}
                  >
                    {trainingPrompts.map((item, index) => (
                      <Select.Option key={index} value={item.text}>
                        {item.text}
                      </Select.Option>
                    ))}
                    {isPromptFetching && <Select.OptGroup label={<Spin className={styling.loading} />} />}
                  </Select>
                </Form.Item>
              )}
            </Form>
          </div>
          <div className={styling.convertButton}>
            <Switch checkedChildren="select" unCheckedChildren="input" onClick={fetchPrompts} />
          </div>
          <div className={styling.promptCategories}>
            <Form
              onValuesChange={(changedValues) => {
                setPromptCategoryId(changedValues.promptCategory);
                setTrainingPrompts([]);
                setPages((prevState) => ({
                  ...prevState,
                  promptPage: 1,
                }));
              }}
            >
              <Form.Item name="promptCategory">
                <Select
                  placeholder="Select Prompt category"
                  onPopupScroll={handlePromtCategoriesScroll}
                  onDropdownVisibleChange={(open) => (open ? handleSelectOpen() : handleSelectClose())}
                  disabled={trainingType === 0}
                >
                  {promptCategories.map((item, index) => (
                    <Select.Option key={index} value={item.category.id}>
                      {item.category.name}
                    </Select.Option>
                  ))}
                  {isPromptCategoryFetching && <Select.OptGroup label={<Spin className={styling.loading} />} />}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className={styling.tools}>
          <Button
            type="primary"
            htmlType="submit"
            className={styling.button}
            onClick={handleSubmit}
            disabled={
              selectedModelIds.length === 0 ||
              isAddLoading ||
              Object.entries(promptReq).some(
                ([name, value]) => name !== 'negativePrompt' && (value === -1 || value === '' || value === undefined),
              )
            }
          >
            {isAddLoading ? <Spin className={styles.spin} /> : 'Submit'}
          </Button>
        </div>

        <ResponseModal
          open={responseModal.open}
          cancel={handleModalClose}
          message={responseModal.message}
          messageHeader={responseModal.message}
        />
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedModelIds.map((modelId) => modelId),
            onSelect: (record) => handleRowClick(record),
            hideSelectAll: true,
          }}
          rowKey="id"
          columns={columns()}
          className={styles.table}
          dataSource={models?.items as Models[]}
          scroll={{ x: 500 }}
          pagination={{
            current: pages.modelsPage,
            pageSize: models?.meta?.itemsPerPage ?? 0,
            total: models?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
      </Row>
    </Row>
  );
};

export default CreateExperiment;
