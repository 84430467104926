import { Tooltip } from 'antd';

import DeleteIcon from '../../assets/icon _close circle_.svg';
import editIcon from '../../assets/icon _edit_.svg';
import { OfferTypes } from '../../redux/offerTypes/offerTypes.model';
import { formatDate } from '../../utils/helpers';
import styles from '../blanks/Blanks.module.css';

const columns = (onActionClick: (_property?: OfferTypes | undefined, _type?: string | undefined) => void) => {
  {
    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <p>{text}</p>;
        },
      },

      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: '',
        key: 'edit',
        render: (offerType: OfferTypes) => {
          return (
            <Tooltip title="Edit" color="#23a89e">
              <img
                alt={'Edit'}
                style={{ cursor: 'pointer' }}
                src={editIcon}
                className={styles.editIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(offerType, 'edit');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'Delete',
        render: (offerType: OfferTypes) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={DeleteIcon}
                className={styles.deleteIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(offerType, 'delete');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};

export default columns;
