import { OrderLineItem } from './OrderLineItem.types';
import styles from './OrderLineItemTable.module.css';
const columns = (onActionClick: (_productVariantId?: number) => void) => {
  return [
    {
      title: 'Blank',
      key: 'image',
      render: (orderLineItem: OrderLineItem) => {
        const orderLineItemSpec = Array.isArray(orderLineItem?.orderLineItemSpecs)
          ? orderLineItem.orderLineItemSpecs[0]
          : orderLineItem.orderLineItemSpecs;

        const imageUrl = orderLineItemSpec?.productVariant?.blankVariant?.blank?.image || '';
        return <img src={imageUrl} alt="Product Image" className={styles.img} />;
      },
    },
    {
      title: 'Blank Name',
      key: 'name',
      render: (orderLineItem: OrderLineItem) => {
        const orderLineItemSpec = Array.isArray(orderLineItem?.orderLineItemSpecs)
          ? orderLineItem.orderLineItemSpecs[0]
          : orderLineItem.orderLineItemSpecs;

        const blankName = orderLineItemSpec?.productVariant?.blankVariant?.blank?.name || '';
        return <p>{blankName}</p>;
      },
    },
    {
      title: 'Product Variant',
      key: 'product_variant',
      render: (orderLineItem: OrderLineItem) => {
        const orderLineItemSpec = Array.isArray(orderLineItem?.orderLineItemSpecs)
          ? orderLineItem.orderLineItemSpecs[0]
          : orderLineItem.orderLineItemSpecs;
        const productVariantId = orderLineItemSpec?.productVariantId;
        return (
          <a
            onClick={() => {
              onActionClick(productVariantId);
            }}
          >
            View details
          </a>
        );
      },
    },
    {
      title: 'Order Line Item Id',
      key: 'id',
      render: (orderLineItem: OrderLineItem) => <p>{orderLineItem.id}</p>,
    },
    {
      title: 'Order Line Item Payment Id',
      key: 'orderLineItemPaymentId',
      render: (orderLineItem: OrderLineItem) => <p>{orderLineItem.orderLineItemPayment.id}</p>,
    },

    {
      title: 'Quantity',
      key: 'quantity',
      render: (orderLineItem: OrderLineItem) => <p>{orderLineItem.orderLineItemPayment.quantity}</p>,
    },
    {
      title: 'Unit Price',
      key: 'unit_price',
      render: (orderLineItem: OrderLineItem) => <p>{orderLineItem.orderLineItemPayment.unit_price}</p>,
    },
    {
      title: 'Discount',
      key: 'discount',
      render: (orderLineItem: OrderLineItem) => <p>{orderLineItem.orderLineItemPayment.discount}</p>,
    },
    {
      title: 'Total',
      key: 'total',
      render: (orderLineItem: OrderLineItem) => <p>{orderLineItem.orderLineItemPayment.total}</p>,
    },
  ];
};
export default columns;
