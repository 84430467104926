import './Gallery.pagination.modal.css';
import './Custom.css';

import { Col, Image, Modal, Row } from 'antd';
import Pagination from 'rc-pagination';
import { useState } from 'react';

import { useGetModelDiffusionFilesQuery, useGetModelFilesQuery } from '../../../redux/details/user/user.actions';
import styles from './Gallery.modal.module.css';
import { ImagesModalTypes } from './ImagesModalTypes';
const ImagesModal = ({ openModal, cancel, modelId, type }: ImagesModalTypes) => {
  const [page, setPage] = useState(1);
  const { data: modelFiles } =
    type === 'Gallery'
      ? useGetModelFilesQuery({ id: modelId, page })
      : useGetModelDiffusionFilesQuery({ id: modelId, page });

  const onPaginate = (page: number) => {
    setPage(page);
  };

  return (
    <Modal
      title={<div className={styles.title}>{type}</div>}
      bodyStyle={{ width: '60vw' }}
      className="custom-modal"
      style={{ display: 'inline-flex', justifyContent: 'center' }}
      centered
      open={openModal}
      onCancel={cancel}
      footer={[
        <Row key="child1" className={styles.paginationContainer}>
          <div key="child2">
            <Pagination
              onChange={onPaginate}
              current={page}
              pageSize={modelFiles?.meta?.itemsPerPage ?? 0}
              total={modelFiles?.meta?.totalItems ?? 0}
              itemRender={() => <div />}
            />
          </div>
        </Row>,
      ]}
    >
      <Row key="child3" gutter={[16, 16]}>
        {modelFiles?.items.map((item, i) => (
          <Col span={4} key={i}>
            <Image src={item?.path} className={styles.image}></Image>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default ImagesModal;
