import React from 'react';

export const AiImageIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.5" y="3.49316" width="19" height="18.9275" rx="2.5" stroke="black" />
      <rect x="6.49902" y="6.48145" width="13" height="12.9493" rx="2.5" stroke="black" />
      <path d="M1 6.97852H3" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M25 19H23" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M19.001 1L19.001 2.99275" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M19.001 22.9199L19.001 24.9127" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 9.96777H3" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M25 16H23" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M16.001 1L16.001 2.99275" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M16.001 22.9199L16.001 24.9127" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 12.9561H3" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M25 13H23" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M13 1L13 2.99275" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M13 22.9199L13 24.9127" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 15.9453H3" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M25 10H23" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M9.99805 1L9.99805 2.99275" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M9.99805 22.9199L9.99805 24.9127" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 18.9346H3" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M25 7H23" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M6.99902 1L6.99902 2.99275" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M6.99902 22.9199L6.99902 24.9127" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M13.1592 15.9746H14.4131L12.4521 10.3379H11.0654L9.1084 15.9746H10.3193L10.749 14.6074H12.7295L13.1592 15.9746ZM11.7256 11.416H11.7529L12.4756 13.7246H11.0029L11.7256 11.416ZM16.3309 15.9746V10.3379H15.1512V15.9746H16.3309Z"
        fill="black"
      />
    </svg>
  );
};
