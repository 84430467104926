import '../../users/models/Antd.css';

import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import searchIcon from '../../../assets/searchIcon.svg';
import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import TrainingRaffleAddModal from '../../../component/modals/trainingRaffle/TrainingRaffleAddModal';
import TrainingRaffleEditModal from '../../../component/modals/trainingRaffle/TrainingRaffleEditModal';
import { useGetTrainingQuery } from '../../../redux/trainings/training/training.actions';
import {
  useCreateTrainingRaffleMutation,
  useGetAllTrainingRaffleQuery,
  useUpdateTrainingRaffleMutation,
} from '../../../redux/trainings/trainingRaffle/training.raffle.actions';
import { TrainingRaffleData } from '../../../redux/trainings/trainingRaffle/trainingRaffleData';
import { useGetAllTrainingPromptCategoryQuery } from '../../../redux/trainings/trainingText/trainingText.actions';
import styles from '../../users/models/TrainingModels.module.css';
import columns from './Training.raffle.table';
const TrainingRaffle = () => {
  const { id } = useParams();
  const trainingId = parseInt(id as string);
  const [form] = Form.useForm();

  const [raffleId, setRaffleId] = useState(0);
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(1);
  const [categoriesPage, setCategoriesPage] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const { data, isLoading } = useGetAllTrainingRaffleQuery({ id: trainingId, page: page });
  const [updateTrigger] = useUpdateTrainingRaffleMutation();
  const [creatRaffle] = useCreateTrainingRaffleMutation();
  const { data: category } = useGetAllTrainingPromptCategoryQuery(categoriesPage);
  const { data: training } = useGetTrainingQuery({ page: page });

  const loadedCategoryData = category?.items;

  const [categories, setCategories] = useState(loadedCategoryData || []);

  useEffect(() => {
    if (loadedCategoryData) {
      const newLoadedData = loadedCategoryData.filter((item) => {
        return !categories.some((categories) => categories.id === item.id);
      });
      if (newLoadedData.length > 0) {
        setCategories((prevData) => [...prevData, ...newLoadedData]);
      }
    }
  }, [loadedCategoryData]);

  const onCategoryRaffleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.target as HTMLDivElement;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
      if (loadedCategoryData && loadedCategoryData.length > 0) {
        const nextPage = categoriesPage + 1;
        setCategoriesPage(nextPage);
      }
    }
  };
  const initialState = {
    count: 0,
    category_id: '',
    category: { name: 'Food', id: 1 },
    training_id: '',
    training: { name: 'Cat', id: 1 },
  };
  const [raffleState, setRaffleState] = useState({
    inputValues: initialState,
  });
  const onPaginate = (page: number) => {
    setPage(page);
  };

  const onCloseModal = () => {
    setOpenEditModal(false);
  };

  const onCreateClick = () => {
    setOpenAddModal(true);
  };

  const handleCancel = () => {
    setOpenAddModal(false);
  };

  const handleRaffleSubmit = async (data: TrainingRaffleData) => {
    await creatRaffle({ count: 0, training_id: trainingId.toString(), category_id: data.category.value });
    setOpenAddModal(false);
    setRaffleState({ ...raffleState });
    form.resetFields();
  };

  const onActionClick = (data: TrainingRaffleData) => {
    setLength(data.category.trainingPromptCategory[0].trainingPrompt.length);
    setRaffleId(data.id);
    setOpenEditModal(true);
    if (data) {
      form.setFieldValue('quantity_selected', data.count);
    }
  };

  const onChangeInput = (e: any) => {
    setQuantity(e);
  };

  const onSubmit = (_data: TrainingRaffleData) => {
    updateTrigger({ id: raffleId, count: parseInt(String(quantity)) });
    setOpenEditModal(false);
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.trainingSubHeader}>
              <p className={styles.mainTitle}> Tools</p>
              <p className={styles.trainingSection}> / Training</p>
              <p className={styles.mainTitle}>/ Category / Pull</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.trainingTitle}>
            <div className={styles.titleContainer}>
              <p>Tools - </p> <span className={styles.subTitle}>Training</span>
            </div>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <Col>
            <p className={styles.closeModelText}>Close</p>
          </Col>
        </Row>
        <Row className={styles.search}>
          <Col className={styles.inputsColumn}>
            <img alt={'Search'} src={searchIcon} />
            <span>Search</span>
          </Col>
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={data?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: data?.meta?.itemsPerPage ?? 0,
            total: data?.meta?.itemsPerPage ?? 0,
            onChange: onPaginate,
          }}
        />
        <Tooltip title="Add Text" color="#23a89e">
          <FloatButton
            shape="circle"
            className={'floatingButton'}
            icon={<PlusOutlined className={'plusIcon'} />}
            onClick={onCreateClick}
          />
        </Tooltip>
        <TrainingRaffleAddModal
          training={training}
          categories={categories}
          open={openAddModal}
          cancel={handleCancel}
          onSubmit={handleRaffleSubmit}
          form={form}
          inputValues={raffleState.inputValues}
          onCategoryRaffleScroll={onCategoryRaffleScroll}
        />
        <TrainingRaffleEditModal
          form={form}
          open={openEditModal}
          total={length}
          cancel={onCloseModal}
          onChangeInput={onChangeInput}
          onSubmit={onSubmit}
        ></TrainingRaffleEditModal>
      </Row>
    </Row>
  );
};

export default TrainingRaffle;
