import React from 'react';

import { TrainingStatuses } from '../../redux/trainings/training/training.model';
import styles from './PiePercentage.module.css';
export const PiePercentage = ({ total, data }: TrainingStatuses) => {
  if (total === undefined) {
    return null; // or return some error message
  }

  return (
    <>
      <div className={styles.container}>
        {data.map((item, i) => {
          return (
            <div className={styles.type} style={{ color: item.fill }} key={i}>
              {((item.value / total) * 100).toFixed(2)}%
            </div>
          );
        })}
      </div>
    </>
  );
};
