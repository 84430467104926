import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../../utils/axiosBaseQuery';
import { ModelFileDataType, UserDataType } from './data.type';

interface argsType {
  id: number;
  page: number;
}
export const userModelsApi = createApi({
  reducerPath: 'userModels',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['UserModel', 'ModelFilesMeta', 'DiffusionFiles'],
  endpoints: (builder) => ({
    //Get all models
    getAllDetails: builder.query<UserDataType, number>({
      query: (page = 1) => ({
        headers: { 'content-type': 'application/json' },
        url: `/models?limit=10&page=${page}`,
        method: 'get',
      }),
      providesTags: ['UserModel'],
    }),
    //Get models by users id
    getUserModels: builder.query<UserDataType, argsType>({
      query: (object) => ({
        headers: { 'content-type': 'application/json' },
        url: `/models/user/${object.id}?limit=10&page=${object.page}`,
        method: 'get',
      }),
      providesTags: ['UserModel'],
    }),
    //Delete users model
    deleteUserModel: builder.mutation<UserDataType, number>({
      query: (id) => ({
        headers: { 'content-type': 'application/json' },
        url: `/models/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserModel'],
    }),

    //Get the files of the model
    getModelFiles: builder.query<ModelFileDataType, argsType>({
      query: (object) => ({
        headers: { 'content-type': 'application/json' },
        url: `/model-files/${object.id}?limit=24&page=${object.page}`,
        method: 'get',
      }),
      providesTags: ['ModelFilesMeta'],
    }),
    getModelDiffusionFiles: builder.query<ModelFileDataType, argsType>({
      query: (object) => ({
        headers: { 'content-type': 'application/json' },
        url: `/diffusion-files/model/${object.id}?limit=24&page=${object.page}`,
        method: 'get',
      }),
      providesTags: ['DiffusionFiles'],
    }),
  }),
});

export const {
  useGetModelDiffusionFilesQuery,
  useGetAllDetailsQuery,
  useDeleteUserModelMutation,
  useGetModelFilesQuery,
  useGetUserModelsQuery,
} = userModelsApi;
