import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { BlankPrintLocationImageRulesModel } from './blankPrintLocationImageRules.model';
export const blankPrintLocationImageRulesApi = createApi({
  reducerPath: 'blankPrintLocationImageRulesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['blankPrintLocationImageRules'],
  endpoints: (builder) => ({
    getBlankPrintLocationImageRules: builder.query<
      BlankPrintLocationImageRulesModel,
      { blankPrintLocationId: number; page: number }
    >({
      query: (data: { blankPrintLocationId: number; page: number }) => ({
        url: `/blank-print-location-image-rules/blank-print-location/${data.blankPrintLocationId}?limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['blankPrintLocationImageRules'],
    }),
    updateBlankPrintLocationImageRulesStatus: builder.mutation<
      { response: string },
      { isActive: boolean | undefined; blankPrintLocationImageRulesId: number | undefined }
    >({
      query: ({ isActive, blankPrintLocationImageRulesId }) => ({
        url: `/blank-print-location-image-rules/${blankPrintLocationImageRulesId}`,
        method: 'patch',
        data: { active: isActive },
      }),
      invalidatesTags: ['blankPrintLocationImageRules'],
    }),
  }),
});

export const { useGetBlankPrintLocationImageRulesQuery, useUpdateBlankPrintLocationImageRulesStatusMutation } =
  blankPrintLocationImageRulesApi;
