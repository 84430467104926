import { PagesState } from './helpers.types';

export default function passwordValidator(password: string) {
  return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*~`_+=(){}:;,.]{8,16}$/.test(password);
}

export const getStatusText = (status: string, type: string): string => {
  switch (parseInt(status, 10)) {
    case 0:
      return type === 'order' ? 'HOLD' : 'ON_HOLD';
    case 2:
      return 'OUT_OF_STOCK';
    case 5:
      return 'PLATFORM_PAYMENT_HOLD';
    case 10:
      return type === 'model' ? 'PROCESSING' : 'PENDING';
    case 20:
      return type === 'model' ? 'PROCESSING_DEPLOYING_GPU' : 'PROCESSING_PAYMENT';
    case 30:
      return type === 'model' ? 'PROCESSING_TRAINING_STARTED' : 'PAID';
    case 40:
      return type === 'model' ? 'PROCESSING_TRAINING_COMPRESSING' : 'PROCESSING_PRODUCTION';
    case 45:
      return 'PROCESSING_PRINT_FILES';
    case 50:
      return type === 'model' ? 'PROCESSING_TRAINING_UPLOAD_COMPRESSING' : type === 'lab' ? 'FAILED' : 'PRODUCTION';
    case 60:
      return 'PARTIAL_SHIPPED';
    case 70:
      return type === 'model' ? 'TRAINING_UPLOADED' : 'SHIPPED';
    case 80:
      return type === 'model' ? 'TRAINING_DEPLOYING' : 'PROCESSING_FULFILLMENT';
    case 90:
      return type === 'model' ? 'TRAINING_SUCCESS' : 'FULFILLED';
    case 100:
      return type === 'order' ? 'CANCELLED' : type === 'lab' ? 'SUCCESS' : 'COMPLETED';
    case 110:
      return type === 'model' ? 'MODEL_IN_USE' : 'IGNORED';
    case 120:
      return type === 'model' ? 'MODEL_IS_STABLE' : 'PARTIAL_REFUND';
    case 130:
      return 'FULL_REFUND';
    case 200:
      return 'FAILED';
    case 210:
      return 'FAILED_INTERNAL_PROCESSING';
    default:
      return 'UNKNOWN';
  }
};

export const formatDate = (text: string) => {
  const date = new Date(text);
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;

  return formattedDate;
};

export const dispatchPageUpdate =
  (setPages: React.Dispatch<React.SetStateAction<PagesState>>, pageKey: keyof PagesState) =>
  (page: React.SetStateAction<number>) => {
    setPages((prevState) => ({
      ...prevState,
      [pageKey]: typeof page === 'function' ? page(prevState[pageKey]) : page,
    }));
  };

export const handleScroll = <T>(
  e: React.UIEvent<HTMLDivElement>,
  loadedData: T[],
  setPage: React.Dispatch<React.SetStateAction<number>>,
  currentPage: number,
) => {
  const container = e.target as HTMLDivElement;
  if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
    if (loadedData && loadedData.length > 0) {
      const nextPage = currentPage + 1;
      setPage(nextPage);
    }
  }
};
