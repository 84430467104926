import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table, UploadFile } from 'antd';
import FormData from 'form-data';
import React, { SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import EditBlankPropertyModal from '../../component/modals/addEditBlankPropertyModal/addEditBlankPropertyModal.modal';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import {
  useCreateBlankPropertiesMutation,
  useDeleteBlankPropertiesMutation,
  useEditBlanksPropertiesMutation,
  useGetBlankPropertiesQuery,
  useUploadBlankPropertyImageMutation,
} from '../../redux/blankProperties/blankProperties.action';
import { BlanksProperties } from '../../redux/blankProperties/blanksProperties.model';
import styles from '../blanks/Blanks.module.css';
import columns from './BlankProperties.table';

const BlankProperties = () => {
  const [form] = Form.useForm();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const blank_id = Number(id);
  const [IsLoading, setIsLoading] = useState(false);
  const [Property, setProperty] = useState<BlanksProperties | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageUploaded, setImageUploaded] = useState(false);
  const initialState: { blank_id: number; key: string; value: string } = {
    blank_id: blank_id,
    key: '',
    value: '',
  };
  const [blankPropertyState, setBlankPropertyState] = useState({
    data: initialState,
    type: 'create',
    openAddEditModal: false,
  });
  const [uploadImage] = useUploadBlankPropertyImageMutation();
  const [deleteTrigger] = useDeleteBlankPropertiesMutation();
  const { data: blanksProperties, isLoading } = useGetBlankPropertiesQuery({ blank_id, page });
  const [addTrigger] = useCreateBlankPropertiesMutation();
  const [editTrigger] = useEditBlanksPropertiesMutation();

  const onActionClick = async (property: BlanksProperties | undefined, type: string | undefined) => {
    if (type === 'delete' && property && property.blank_id) {
      setCurrentId(property.id);
      setOpenDeleteModal(true);
    } else if (type === 'edit' && property && property.blank_id) {
      setCurrentId(property.id);
      setProperty(property);

      if (property?.key === 'image') {
        form.setFieldValue(['option'], 'image');
      } else {
        form.setFieldValue(['option'], 'other options');
      }

      form.setFieldValue(['key'], property?.key);
      form.setFieldValue(['value'], property?.value);
      setBlankPropertyState((prevState) => ({
        ...prevState,
        type: type,
        openAddEditModal: true,
      }));
    } else if (type === 'add' && property && property.blank_id) {
      setCurrentId(property.id);
    }
  };

  const onPaginate = (page: number) => {
    setPage(page);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onClickDelete = async () => {
    setIsLoading(true);
    await deleteTrigger(currentId);
    setIsLoading(false);
    setOpenDeleteModal(false);
  };

  const onCreateClick = () => {
    form.setFieldsValue({
      key: '',
      value: '',
    });

    setBlankPropertyState(() => ({
      data: initialState,
      type: 'create',
      openAddEditModal: true,
    }));
  };

  const closeAddEditModal = () => {
    setBlankPropertyState({ ...blankPropertyState, openAddEditModal: false });
  };
  const handleImageUpload = async (id: number) => {
    const formData = new FormData();
    if (typeof fileList[0] === 'undefined') {
      return;
    } else {
      formData.append('image', fileList[0].originFileObj);
      const imageUploadResponse = await uploadImage({ formData, id });
      setFileList([]);
      setImageUploaded(false);
      return imageUploadResponse;
    }
  };

  const onEditClick = async () => {
    try {
      if (blankPropertyState.type === 'create') {
        setIsLoading(true);

        if (form.getFieldValue(['options']) === 'image') {
          const imageUploadResponse = await handleImageUpload(blank_id);

          if (imageUploadResponse && 'data' in imageUploadResponse && 'url' in imageUploadResponse.data) {
            await addTrigger({
              blank_id: blank_id,
              key: form.getFieldValue(['options']),
              value: imageUploadResponse.data.url as string,
            });
          }

          setIsLoading(false);
          closeAddEditModal();
        } else {
          setIsLoading(true);
          await addTrigger({
            blank_id: blank_id,
            key: form.getFieldValue(['key']),
            value: form.getFieldValue(['value']),
          });

          setIsLoading(false);
          closeAddEditModal();
        }
      } else {
        setIsLoading(true);

        if (form.getFieldValue(['option']) === 'image') {
          const imageUploadResponse = await handleImageUpload(blank_id);

          if (imageUploadResponse && 'data' in imageUploadResponse && 'url' in imageUploadResponse.data) {
            await editTrigger({
              id: currentId,
              key: form.getFieldValue(['option']),
              value: imageUploadResponse.data.url as string,
            });
          }

          setIsLoading(false);
          closeAddEditModal();
        } else {
          await editTrigger({
            id: currentId,
            key: form.getFieldValue(['key']),
            value: form.getFieldValue(['value']),
          });
          setIsLoading(false);
          closeAddEditModal();
        }
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleFileChange = (event: { fileList: SetStateAction<UploadFile[]> }) => {
    setFileList(event?.fileList);
    setImageUploaded(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const data = { ...blankPropertyState.data, [name]: value };
    setBlankPropertyState({ ...blankPropertyState, data: data });
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.blanksSubHeader}>
              <p className={styles.users}>Blanks</p>
              <p className={styles.details}>/ Properties</p>
            </div>
          </Col>
        </Row>
        <Col className={styles.title}>
          <p>Blank Properties</p>
        </Col>
        <div className={styles.separator}></div>
        <Col>
          <p className={styles.line}></p>
        </Col>
        <div className={styles.separator}></div>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={blanksProperties?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: blanksProperties?.meta?.itemsPerPage ?? 0,
            total: blanksProperties?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
        <FloatButton
          shape="circle"
          className={'floatingButton'}
          icon={<PlusOutlined className={'plusIcon'} />}
          onClick={onCreateClick}
        />
      </Row>
      <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} loading={IsLoading} />

      <EditBlankPropertyModal
        form={form}
        onChange={handleInputChange}
        action={onEditClick}
        cancel={closeAddEditModal}
        open={blankPropertyState.openAddEditModal}
        type={blankPropertyState.type}
        loading={IsLoading}
        Property={Property || { blank_id: 0, key: '', value: '', id: 0 }}
        handleChangeImage={handleFileChange}
        imageUploaded={imageUploaded}
      />
    </Row>
  );
};

export default BlankProperties;
