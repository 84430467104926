import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../../utils/axiosBaseQuery';
import { TrainingCategoryModel } from './data.model';
import { TrainingCategoryData } from './training.category.model';

export const trainingCategoryApi = createApi({
  reducerPath: 'trainingCategory',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['TrainingCategoryModel', 'TrainingCategory'],
  endpoints: (builder) => ({
    getTrainingCategory: builder.query<TrainingCategoryModel, number>({
      query: (page) => ({ url: `/category?limit=10&page=${page}`, method: 'get' }),
      providesTags: ['TrainingCategoryModel'],
    }),
    deleteTrainingCategory: builder.mutation<string, number>({
      query: (id: number) => ({ url: `/category/${id}`, method: 'delete' }),
      invalidatesTags: ['TrainingCategoryModel'],
    }),
    createTrainingCategory: builder.mutation<TrainingCategoryData, Partial<TrainingCategoryData>>({
      query: (data: TrainingCategoryData) => ({ url: `/category`, method: 'POST', data: data }),
      invalidatesTags: ['TrainingCategoryModel'],
    }),
    updateTrainingCategory: builder.mutation<TrainingCategoryData, Partial<TrainingCategoryData>>({
      query: (data: TrainingCategoryData) => ({
        url: `/category/${data.id}`,
        method: 'PATCH',
        data: data,
      }),
      invalidatesTags: ['TrainingCategoryModel'],
    }),
  }),
});

export const {
  useGetTrainingCategoryQuery,
  useCreateTrainingCategoryMutation,
  useDeleteTrainingCategoryMutation,
  useUpdateTrainingCategoryMutation,
} = trainingCategoryApi;
