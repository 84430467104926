import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoginModel } from '../login/login.model';

const initialState: LoginModel = {
  accessToken: '',
  refreshToken: '',
};

const authenticationSlice = createSlice({
  name: 'authenticate',
  initialState,
  reducers: {
    // demo to write a custom reducer after firing the increment function
    updateTokens: (state, action: PayloadAction<LoginModel>) => {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    },
  },
});

export const { updateTokens } = authenticationSlice.actions;
export default authenticationSlice.reducer;
