import { Tooltip } from 'antd';

import deleteIcon from '../../assets/close_circle.svg';
import editIcon from '../../assets/icon _edit.svg';
import rulesIcon from '../../assets/rules_icon.svg';
import { TrainingModel } from '../../redux/trainings/trainingText/trainingText.model';
import styles from './TrainingText.module.css';

const trainingTableColumns = (
  onActionClick: (_id: TrainingModel, _type: string) => void,
  onIconClick: (_id: number, _type: string) => void,
) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => {
        return <a className={styles.id}>{text}</a>;
      },
    },
    {
      title: 'Category',
      dataIndex: 'trainingPromptCategory',
      key: 'trainingPromptCategory',
      render: (text: Record<string, Record<string, string>>) => {
        return <p>{text.category.name}</p>;
      },
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      render: (text: string) => {
        return <p>{text}</p>;
      },
    },

    {
      title: '',
      key: 'edit',
      render: (record: TrainingModel) => {
        return (
          <Tooltip title="Edit" color="#23a89e">
            <img
              alt={'Edit'}
              src={editIcon}
              className={styles.editIcon}
              onClick={() => {
                onActionClick(record, 'edit');
              }}
            />
          </Tooltip>
        );
      },
    },

    {
      title: '',
      key: 'rules',
      render: (record: { id: number }) => {
        return (
          <Tooltip title="Requirements" color="#23a89e">
            <img
              alt={'Requirements'}
              style={{ cursor: 'pointer' }}
              src={rulesIcon}
              className={styles.icon}
              onClick={() => {
                onIconClick(record.id, 'rules');
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'trash',
      render: (record: TrainingModel) => {
        return (
          <Tooltip title="Delete" color="#23a89e">
            <img
              alt={'Delete'}
              src={deleteIcon}
              className={styles.deleteIcon}
              onClick={() => {
                onActionClick(record, 'delete');
              }}
            />
          </Tooltip>
        );
      },
    },
  ];
};

export default trainingTableColumns;
