import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { ModelsModel } from './models.model';

export const modelsApi = createApi({
  reducerPath: 'models',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['models'],
  endpoints: (builder) => ({
    getModels: builder.query<ModelsModel, { trainingType?: number; page: number }>({
      query: (object) => {
        const { trainingType, page } = object;
        let url = `/models?limit=10&page=${page}`;
        if (trainingType && trainingType !== 0) {
          url = `/models?type=${trainingType}&limit=10&page=${page}`;
        }
        return { url, method: 'get' };
      },
      providesTags: ['models'],
    }),
  }),
});
export const { useGetModelsQuery } = modelsApi;
