import '../../../component/modals/gallery/Gallery.modal.module.css';

import { Col, Row, Table } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import searchIcon from '../../../assets/searchIcon.svg';
import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../../component/modals/delete/Delete.modal';
import ImagesModal from '../../../component/modals/gallery/ImagesModal';
import columns from '../../../data/UserModels.table';
import { useDeleteUserModelMutation, useGetUserModelsQuery } from '../../../redux/details/user/user.actions';
import styles from './TrainingModels.module.css';
const TrainingModels = () => {
  const { id } = useParams();
  const userId = parseInt(id as string);
  const [page, setPage] = useState(1);
  const [imagesModalState, setImagesModalState] = useState({
    modelId: 0,
    type: '',
    openModal: false,
  });
  const { data: userDetails, isLoading } = useGetUserModelsQuery({ id: userId, page });
  const [trigger] = useDeleteUserModelMutation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const placeHolders = ['ID', 'Status', 'Type', 'Model'];

  const onActionClick = (id: number, type: string) => {
    if (type === 'delete') {
      setImagesModalState({
        ...imagesModalState,
        modelId: id,
      });
      setOpenDeleteModal(true);
    } else if (type === 'gallery') {
      setImagesModalState({
        type: 'Gallery',
        openModal: true,
        modelId: id,
      });
    } else if (type === 'diffusionFiles') {
      setImagesModalState({
        type: 'Diffusion Files',
        openModal: true,
        modelId: id,
      });
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeGalleryModal = () => {
    setImagesModalState({
      ...imagesModalState,
      openModal: false,
    });
  };

  const onClickDelete = () => {
    trigger(imagesModalState.modelId);
    setOpenDeleteModal(false);
  };

  const onPaginate = (page: number) => {
    setPage(page);
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <span className={styles.headerSpace}></span>
          <Col>
            <div className={styles.subHeader}>
              <p className={styles.users}>Users</p>
              <p className={styles.details}>/ User Models</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>User Models</p>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <Col>
            <p className={styles.closeModelText}>Close Model</p>
          </Col>
        </Row>
        <Row className={styles.search}>
          <Col className={styles.inputsColumn}>
            <img alt={'Search'} src={searchIcon} />
            <div className={styles.space}></div>
            <span>Search</span>
          </Col>
          {placeHolders.map((placeHolder, i) => (
            <Col key={i}>
              <input className={styles.inputs} placeholder={placeHolder} />
            </Col>
          ))}
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={userDetails?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: userDetails?.meta?.itemsPerPage ?? 0,
            total: userDetails?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        ></Table>
        <ImagesModal {...imagesModalState} cancel={closeGalleryModal} />
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
      </Row>
    </Row>
  );
};

export default TrainingModels;
