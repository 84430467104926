import React from 'react';

import { ExperimentProperties } from '../../../redux/lab/lab.model';
const experimentPropertiesColumns = () => {
  {
    return [
      {
        title: 'Key',
        key: 'key',
        render: (property: ExperimentProperties) => <p>{property.key}</p>,
      },
      {
        title: 'Value',
        key: 'value',
        render: (property: ExperimentProperties) => <p>{property.value}</p>,
      },
    ];
  }
};
export default experimentPropertiesColumns;
