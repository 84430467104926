import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { BlankPrintLocations } from './blankPrintLocations.model';
export const blankPrintLocationsApi = createApi({
  reducerPath: 'blankPrintLocationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['blankPrintLocationsApi'],
  endpoints: (builder) => ({
    getBlankPrintLocations: builder.query<BlankPrintLocations[], { blank_id: number }>({
      query: (data: { blank_id: number; page: number }) => ({
        url: `/blank-print-locations/blank/${data.blank_id}`,
        method: 'get',
      }),
      providesTags: ['blankPrintLocationsApi'],
    }),
    updateBlankPrintLocationsStatus: builder.mutation<
      { response: string },
      { isActive: boolean | undefined; blankPrintLocationsId: number | undefined }
    >({
      query: ({ isActive, blankPrintLocationsId }) => ({
        url: `/blank-print-locations/${blankPrintLocationsId}`,
        method: 'patch',
        data: { active: isActive },
      }),
      invalidatesTags: ['blankPrintLocationsApi'],
    }),
  }),
});

export const { useGetBlankPrintLocationsQuery, useUpdateBlankPrintLocationsStatusMutation } = blankPrintLocationsApi;
