import './Menu.css';

import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { HelpLogoIcon } from '../../assets/icons/HelpLogoIcon';
import { updateTokens } from '../../redux/auth/auth.reducer';
import { useAppDispatch } from '../../redux/hooks';
import DashboardData from './Dashboard.table';
import styles from './DashboardMenu.module.css';
const DashboardMenu = () => {
  const location = useLocation();
  const pathParts = location?.pathname?.split('/');
  const selectedKey = pathParts[2];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const Section: MenuProps['onClick'] = (event) => {
    navigate(event.key);
  };

  const HelpIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={HelpLogoIcon} {...props} />;

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>BarkParty</h1>
        </div>
        <div className={styles.menuContainer}>
          <Menu
            className={'dashboardMenu'}
            defaultSelectedKeys={[selectedKey]}
            selectedKeys={[selectedKey]}
            defaultOpenKeys={['dashboard']}
            mode="inline"
            items={DashboardData()}
            onSelect={Section}
          ></Menu>
        </div>
        <div className={styles.helpContainer}>
          <HelpIcon className={styles.helpIcon} />
          <span className={styles.help}>Help</span>

          <span
            onClick={() => {
              localStorage.setItem('tokens', JSON.stringify({ accessToken: '', refresh_token: '' }));
              dispatch(updateTokens({ accessToken: '', refreshToken: '' }));
              navigate('/');
            }}
            className={styles.logout}
          >
            Logout
          </span>
        </div>
      </div>
    </>
  );
};

export default DashboardMenu;
