import React from 'react';

import { Pie } from '../pieChart/Pie.type';
import styles from './PieTitle.module.css';

export const PieTitle = ({ title }: Pie) => {
  return (
    <>
      <div className={styles.titleContainer}>{title}</div>
      <div className={styles.underLineTitle} />
    </>
  );
};
