import './App.css';
import './interceptors';

import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { i18nInstance } from './i18n/i18n';
import Navigator from './navigations/Navigator';
import { store } from './redux/store';

const persistor = persistStore(store);

function App() {
  return (
    <I18nextProvider i18n={i18nInstance}>
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <Navigator />
        </Provider>
      </PersistGate>
    </I18nextProvider>
  );
}

export default App;
