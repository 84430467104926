import closeIcon from '../../../assets/icon _close circle_.svg';
import editIcon from '../../../assets/icon _edit_.svg';
import { TrainingImageRulesData } from '../../../redux/trainings/trainingImageRules/training.image.rules.model';
import styles from '../../users/models/TrainingModels.module.css';

const columns = (onActionClick: (_data: TrainingImageRulesData, _type: string) => void) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <a>{text}</a>;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Image type',
        key: 'imageTypes',
        dataIndex: 'imageTypes',
        render: (text: Record<string, string>) => {
          return <p>{text.name}</p>;
        },
      },
      {
        title: 'Image resize method',
        key: 'imageResizeMethods',
        dataIndex: 'imageResizeMethods',
        render: (text: Record<string, string>) => {
          return <p>{text.name}</p>;
        },
      },
      {
        title: '',
        key: 'edit',
        render: (data: TrainingImageRulesData) => {
          return (
            <img
              alt={'Edit'}
              style={{ cursor: 'pointer' }}
              src={editIcon}
              className={styles.icon}
              onClick={() => {
                onActionClick(data, 'edit');
              }}
            />
          );
        },
      },
      {
        title: '',
        key: 'close',
        render: (data: TrainingImageRulesData) => {
          return (
            <img
              alt={'Delete'}
              src={closeIcon}
              className={styles.icon}
              onClick={() => {
                onActionClick(data, 'delete');
              }}
            />
          );
        },
      },
    ];
  }
};
export default columns;
