import { Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import React from 'react';

import styles from '../blanksOffersModal/BlankOffersModal.modal.module.css';
import { CreateLabModalType } from './CreateLabModal.modal.types';

const CreateLabModal = ({
  open,
  cancel,
  form,
  action,
  isLabTypeFetching,
  loading,
  labType,
  onScroll,
}: CreateLabModalType) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> Create New Lab </h1>
          </div>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={() => action('add')}>
            <Form.Item
              label="Lab Type Id "
              name="labTypeId"
              rules={[{ required: true, message: 'Lab type id is required' }]}
            >
              <Select placeholder="Lab Type Id" onPopupScroll={onScroll}>
                {labType.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
                {isLabTypeFetching && <Select.OptGroup label={<Spin />} />}
              </Select>
            </Form.Item>

            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
              <Input className={styles.input} name="name" type={'text'} min={0} placeholder="Enter name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Description is required' }]}
            >
              <Input
                className={styles.input}
                name="description"
                type={'text'}
                min={0}
                placeholder="Enter description"
              />
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Spin className={styles.spin} /> : 'create'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateLabModal;
