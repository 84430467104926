import { DollarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import DeleteIcon from '../../assets/icon _close circle_.svg';
import { Order } from '../../redux/orders/orders.model';
import { getStatusText } from '../../utils/helpers';
import styles from '../blanks/Blanks.module.css';

const columns = (onActionClick: (_order?: Order | undefined, _type?: string | undefined) => void) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        const statusText = getStatusText(text, 'order');
        return <p>{statusText}</p>;
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render: (text: string | null) => <p>{text !== null ? text : '-'}</p>,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: '',
      key: 'Refund',
      render: (order: Order) => {
        return (
          <Tooltip title="Refund" color="#23a89e">
            <DollarOutlined
              className={styles.informationIcon}
              onClick={() => {
                onActionClick(order, 'refund');
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'Order information',
      render: (order: Order) => {
        return (
          <Tooltip title="Order Information" color="#23a89e">
            <InfoCircleOutlined
              className={styles.informationIcon}
              onClick={() => {
                onActionClick(order, 'showInfo');
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'Delete',
      render: (order: Order) => {
        return (
          <Tooltip title="Delete" color="#23a89e">
            <img
              alt={'Delete'}
              style={{ cursor: 'pointer' }}
              src={DeleteIcon}
              className={styles.deleteIcon}
              onClick={() => {
                onActionClick(order, 'delete');
              }}
            />
          </Tooltip>
        );
      },
    },
  ];
};
export default columns;
