import editIcon from '../../../assets/icon _edit_.svg';
import { TrainingRaffleData } from '../../../redux/trainings/trainingRaffle/trainingRaffleData';
import styles from '../../users/models/TrainingModels.module.css';

const columns = (onActionClick: (_data: TrainingRaffleData) => void) => {
  {
    return [
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        render: (category: Record<string, string>) => {
          return <p>{category.name}</p>;
        },
      },
      {
        title: 'Total',
        dataIndex: 'category',
        key: 'total',
        render: (total: Record<string, Record<string, Record<string, string>>>) => {
          return <p>{total.trainingPromptCategory[0].trainingPrompt.length}</p>;
        },
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        render: (count: number) => {
          return <p>{count}</p>;
        },
      },
      {
        title: '',
        key: 'edit',
        render: (data: TrainingRaffleData) => {
          return (
            <img
              alt={'Edit'}
              style={{ cursor: 'pointer' }}
              src={editIcon}
              className={styles.icon}
              onClick={() => {
                onActionClick(data);
              }}
            />
          );
        },
      },
    ];
  }
};
export default columns;
