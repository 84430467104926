import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';

import { AddEditMockupProviderModalTypes } from './AddEditMockupProviderModal.modal.types';
import styles from './AddEditMockupProviderModal.module.css';
const AddEditMockupProviderModal = ({ open, cancel, form, action, type, loading }: AddEditMockupProviderModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> {type === 'create' ? 'Add' : 'Edit'} Blank Variant Provider </h1>
          </div>
          <Form
            form={form}
            layout={'vertical'}
            autoComplete="off"
            name="basic"
            onFinish={type === 'create' ? () => action('add') : () => action('edit')}
          >
            <Form.Item
              label="name"
              name="name"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input className={styles.input} name="name" type={'text'} min={0} placeholder="Enter name" />
            </Form.Item>

            <Form.Item
              label="key"
              name="key"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input className={styles.input} name="key" type={'text'} min={0} placeholder="Enter key" />
            </Form.Item>

            <Form.Item
              label="description"
              name="description"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input
                className={styles.input}
                name="description"
                type={'text'}
                min={0}
                placeholder="Enter description"
              />
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Spin className={styles.spin} /> : type === 'create' ? 'Add' : 'Edit'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddEditMockupProviderModal;
