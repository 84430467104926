import React from 'react';

export const HelpLogoIcon = () => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.87107 21C6.58605 21 6.29083 20.9287 6.02617 20.7862C5.44594 20.4809 5.08968 19.8701 5.08968 19.2186V17.7732C2.01552 17.4577 0 15.1978 0 11.9607V5.85314C0 2.35143 2.35143 0 5.85314 0H16.0325C19.5342 0 21.8856 2.35143 21.8856 5.85314V11.9607C21.8856 15.4624 19.5342 17.8139 16.0325 17.8139H12.1949L7.85844 20.7049C7.56324 20.8983 7.21717 21 6.87107 21ZM5.85314 1.51672C3.22686 1.51672 1.52691 3.21667 1.52691 5.84295V11.9506C1.52691 14.5769 3.22686 16.2769 5.85314 16.2769C6.27049 16.2769 6.61659 16.623 6.61659 17.0403V19.2085C6.61659 19.3409 6.69803 19.4019 6.74892 19.4325C6.79983 19.463 6.90162 19.4936 7.0136 19.4223L11.5434 16.4092C11.6656 16.3278 11.8182 16.2769 11.9709 16.2769H16.0427C18.669 16.2769 20.3689 14.5769 20.3689 11.9506V5.84295C20.3689 3.21667 18.669 1.51672 16.0427 1.51672H5.85314Z"
        fill="#292D32"
        fillOpacity="0.5"
      />
      <path
        d="M10.9434 10.6068C10.5261 10.6068 10.18 10.2607 10.18 9.84339V9.62962C10.18 8.44881 11.0452 7.86858 11.371 7.64463C11.7476 7.39015 11.8697 7.21711 11.8697 6.95245C11.8697 6.44348 11.4524 6.02608 10.9434 6.02608C10.4345 6.02608 10.0171 6.44348 10.0171 6.95245C10.0171 7.3698 9.671 7.7159 9.25365 7.7159C8.83633 7.7159 8.49023 7.3698 8.49023 6.95245C8.49023 5.59859 9.58957 4.49918 10.9434 4.49918C12.2973 4.49918 13.3966 5.59859 13.3966 6.95245C13.3966 8.11289 12.5416 8.69312 12.226 8.90688C11.829 9.17155 11.7069 9.3446 11.7069 9.62962V9.84339C11.7069 10.2709 11.3608 10.6068 10.9434 10.6068Z"
        fill="#292D32"
        fillOpacity="0.5"
      />
      <path
        d="M10.9431 13.1417C10.5156 13.1417 10.1797 12.7956 10.1797 12.3783C10.1797 11.9609 10.5258 11.6148 10.9431 11.6148C11.3605 11.6148 11.7066 11.9609 11.7066 12.3783C11.7066 12.7956 11.3707 13.1417 10.9431 13.1417Z"
        fill="#292D32"
        fillOpacity="0.5"
      />
    </svg>
  );
};
