import { Col, Row } from 'antd';
import React from 'react';

import styles from './PersonalDetails.module.css';
import { PersonalDetailsProps } from './personalDetails.type';

const ReadOnlyPersonalDetails = ({ emailValue, firstNameValue, lastNameValue, phone }: PersonalDetailsProps) => {
  return (
    <div>
      <Row>
        <Col span={1}></Col>

        <Col span={22}>
          <p className={styles.personalDetailsTitle}>Personal Details</p>
          <Row className={styles.rowContainer}>
            <Col sm={{ span: 24 }} md={{ span: 12 }} className={styles.inputSection}>
              <div className={styles.label}>Email Address</div>
              <div className={styles.inputContainer}>{emailValue}</div>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <div className={styles.label}>First Name</div>
              <div className={styles.inputContainer}>{firstNameValue}</div>
            </Col>
          </Row>
          <Row className={styles.rowContainer}>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <div className={styles.label}>Phone Number</div>
              <div className={styles.inputContainer}>{phone}</div>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <div className={styles.label}>Last Name</div>
              <div className={styles.inputContainer}>{lastNameValue}</div>
            </Col>
          </Row>
        </Col>
        <Col span={1}></Col>
      </Row>
    </div>
  );
};

export default ReadOnlyPersonalDetails;
