import { Button, Col, Modal, Row, Spin } from 'antd';

import styles from './Delete.modal.module.css';
import { DeleteModalTypes } from './Delete.modal.types';

const DeleteModal = ({ openModal, cancel, onDelete, loading }: DeleteModalTypes) => {
  return (
    <Modal
      width={'400px'}
      centered
      open={openModal}
      onCancel={cancel}
      closable={false}
      footer={[
        <Row key="child1" gutter={8} className={styles.footerDiv}>
          <Col key="child2">
            <Button key="cancel" className={styles.cancelBtn} onClick={cancel}>
              Cancel
            </Button>
          </Col>
          <Col key="child3">
            <Button key="delete" className={styles.deleteBtn} onClick={onDelete} disabled={loading}>
              {loading ? <Spin className={styles.spin} /> : 'Delete'}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <p key="child4" className={styles.text}>
        Are you sure you want to delete?
      </p>
    </Modal>
  );
};

export default DeleteModal;
