import { Tooltip } from 'antd';

import DeleteIcon from '../../assets/icon _close circle_.svg';
import editIcon from '../../assets/icon _edit_.svg';
import { BlanksProperties } from '../../redux/blankProperties/blanksProperties.model';
import styles from './BlankProperties.module.css';

const columns = (onActionClick: (_property?: BlanksProperties | undefined, _type?: string | undefined) => void) => {
  {
    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <p>{text}</p>;
        },
      },

      {
        title: 'Key',
        dataIndex: 'key',
        key: 'Key',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',

        render: (text: string, record: BlanksProperties) => {
          if (record.key.trim() === 'image') {
            return (
              <img
                alt={text}
                height={40}
                width={40}
                src={text}
                className={styles.image}
                onError={(e) => {
                  e.currentTarget.style.display = 'none';
                }}
              />
            );
          } else if (record.key.trim() !== '' && record.key.trim() !== 'image') {
            return <p>{text}</p>;
          }
        },
      },
      {
        title: '',
        key: 'edit',
        render: (property: BlanksProperties) => {
          return (
            <Tooltip title="Edit" color="#23a89e">
              <img
                alt={'Edit'}
                style={{ cursor: 'pointer' }}
                src={editIcon}
                className={styles.editIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(property, 'edit');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'Delete',
        render: (property: BlanksProperties) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={DeleteIcon}
                className={styles.deleteIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(property, 'delete');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};

export default columns;
