import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

import PropertyIcon from '../../../assets/icon _blank property_.svg';
import DeleteIcon from '../../../assets/icon _close circle_.svg';
import { BleuGalleryImage } from '../../../assets/icons/BleuGalleryIcon';
import { GalleryImage } from '../../../assets/icons/GalleryIcon';
import { Experiment, ExperimentModelProperties } from '../../../redux/lab/lab.model';
import { formatDate, getStatusText } from '../../../utils/helpers';
import styling from '../../models/Models.table.module.css';
import styles from '../CreateExperiment/CreateLab.module.css';
const columns = (
  onActionClick: (
    _type?: string | undefined,
    _experiment?: Experiment | undefined,
    _labModelProperties?: ExperimentModelProperties[] | undefined,
  ) => void,
) => {
  {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Status',
        key: 'status',
        render: (experiment: Experiment) => {
          const statusText = getStatusText(experiment.status.toString(), 'lab');
          return <p>{statusText}</p>;
        },
      },
      {
        title: 'Result',
        dataIndex: 'result',
        key: 'result',
        render: (text: string, lab: Experiment) =>
          Number(lab.status) === 100 ? (
            <Icon
              component={BleuGalleryImage}
              className={styles.tableIcon}
              onClick={(event) => {
                event.stopPropagation();
                onActionClick('viewOutputImages', lab);
              }}
            />
          ) : (
            <Icon
              component={GalleryImage}
              className={styles.tableIcon}
              onClick={(event) => {
                event.stopPropagation();
                onActionClick('viewOutputImages', lab);
              }}
            />
          ),
      },
      {
        title: 'Experiment Properties',
        key: 'experimentProperties',
        render: (text: string, record: Experiment) => {
          return (
            <img
              alt={'Properties'}
              style={{ cursor: 'pointer' }}
              src={PropertyIcon}
              onClick={(event) => {
                event.stopPropagation();
                onActionClick('viewProperties', record);
              }}
            />
          );
        },
      },
      {
        title: 'Model Properties',
        key: 'experimentModelProperties',
        render: (text: string, record: Experiment) => {
          return (
            <div style={{ display: 'flex' }}>
              {record?.models.map((model, index) => (
                <div key={index} className={styling.imgContainer}>
                  <img
                    height={40}
                    width={40}
                    src={model?.modelFiles[0]?.path}
                    className={styling.img}
                    onClick={(event) => {
                      event.stopPropagation();
                      const experimentModelProperties = record?.experimentModelsMapping?.flatMap(
                        (experimentModel) => experimentModel?.experimentModelProperties || [],
                      );
                      onActionClick('viewExperimentModelProperties', record, experimentModelProperties);
                    }}
                  />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: '',
        key: 'Delete',
        render: (text: string, record: Experiment) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={DeleteIcon}
                className={styles.deleteIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick('deleteExperiment', record);
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};
export default columns;
