import { Col, Row, Table } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetOrderByIdQuery } from '../../redux/orders/orders.actions';
import styles from '../blankInformation/BlankInformation.module.css';
import columns from './OrderLineItem.table';

const OrderLineItems = () => {
  const { id } = useParams();
  const orderId = Number(id);
  const navigate = useNavigate();
  const { data: OrderInfo } = useGetOrderByIdQuery(orderId);
  const OrderLineItemsData = OrderInfo?.orderLineItems;
  const onActionClick = (productVariantId: number | undefined) => {
    navigate(`/main/orders/order-information/order-line-item/product-variant/${orderId}/${productVariantId}`);
  };

  return (
    <>
      <Row className={styles.mainContainer}>
        <Row className={styles.container}>
          <Row className={styles.header}>
            <Col>
              <div className={styles.subHeader}>
                <p className={styles.users}>Orders</p>
                <p className={styles.details}>/ Order Information </p>
                <p className={styles.details}>/ Order Line Items </p>
              </div>
            </Col>
          </Row>
          <Row className={styles.detailsTitle}>
            <Col className={styles.title}>
              <p>Order Line Items</p>
            </Col>
            <Col>
              <p className={styles.line}></p>
            </Col>
            <div className={styles.margins}></div>
          </Row>
          <Row className={styles.tableContainer}>
            <Table
              rowKey="id"
              columns={columns(onActionClick)}
              className={styles.table}
              dataSource={OrderLineItemsData ? OrderLineItemsData : []}
              scroll={{ x: 500 }}
            />
          </Row>
        </Row>
      </Row>
    </>
  );
};
export default OrderLineItems;
