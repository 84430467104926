import { Button } from 'antd';

export const layerColumns = (onDeleteClick: (_index?: number | undefined) => void) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      key: 'Delete',
      render: (index: number) => (
        <Button type="link" onClick={() => onDeleteClick(index)}>
          Delete
        </Button>
      ),
    },
  ];
};
