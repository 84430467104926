import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { GimpTypes } from './gimp.model';
export const gimpApi = createApi({
  reducerPath: 'gimp',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['gimp'],
  endpoints: (builder) => ({
    // To Do : refactor this to match the api call
    createGimp: builder.mutation<GimpTypes, Partial<GimpTypes>>({
      query: (data: GimpTypes) => ({ url: ``, method: 'POST', data: data }),
      invalidatesTags: ['gimp'],
    }),
  }),
});

export const { useCreateGimpMutation } = gimpApi;
