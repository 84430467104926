import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { faq, FaqModel } from './faq.model';

export const FaqApi = createApi({
  reducerPath: 'FAQ',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['questions'],
  endpoints: (builder) => ({
    getQuestions: builder.query<FaqModel, number>({
      query: (page) => ({ url: `/faq?limit=10&page=${page}`, method: 'get' }),

      providesTags: ['questions'],
    }),
    deleteQuestion: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/faq/${id}`, method: 'delete' };
      },
      invalidatesTags: ['questions'],
    }),
    editQuestion: builder.mutation<faq, Partial<faq>>({
      query: (data: faq) => {
        return { url: `/faq/${data.id}`, method: 'PATCH', data: data };
      },
      invalidatesTags: ['questions'],
    }),
    createQuestion: builder.mutation<faq, Partial<faq>>({
      query: (data: faq) => {
        return { url: `/faq`, method: 'POST', data: data };
      },
      invalidatesTags: ['questions'],
    }),
  }),
});

export const { useGetQuestionsQuery, useDeleteQuestionMutation, useEditQuestionMutation, useCreateQuestionMutation } =
  FaqApi;
