import { createSlice } from '@reduxjs/toolkit';

import { User } from '../../containers/auth/login/Login.type';
import { loginApi } from './login.actions';

const initialState: User = {
  accessToken: '',
  refreshToken: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(loginApi.endpoints.postUser.matchFulfilled, (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    });
  },
  reducers: {
    SaveAccessToken(state) {
      state.accessToken?.toString();
    },
    SaveRefreshToken(state) {
      state.refreshToken?.toString();
    },
  },
});

export const { SaveAccessToken } = userSlice.actions;
export default userSlice.reducer;
