import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import {
  useDeleteBlankMutation,
  useLazyGetBlanksQuery,
  useUpdateBlankMutation,
} from '../../redux/blanks/blanks.actions';
import { Blanks as BlanksType } from '../../redux/blanks/blanks.model';
import { setCurrentPage } from '../../redux/navigations/navigations.reducer';
import { RootState } from '../../redux/navigations/navigations.type'; // Import your state type
import styles from './Blanks.module.css';
import columns from './Blanks.table';

const Blanks = () => {
  const dispatch = useDispatch();
  const globalPage = useSelector((state: RootState) => state.pagination.pages.blanksCurrentPage);
  const globalBlanksState = useSelector((state: RootState) => state.pagination.isBlankActive);
  const placeHolders = ['Name', 'Active'];
  const [selectedValue, setSelectedValue] = useState(globalBlanksState ? 'true' : 'false');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteTrigger] = useDeleteBlankMutation();
  const [valueOfName, setValueOfName] = useState('');
  const [name, setName] = useState('');
  const [isBlankActive, setBlankActive] = useState<{ [key: string]: boolean }>({});
  const [currentId, setCurrentId] = useState(0);
  const navigate = useNavigate();
  const [fetchBlanks, { data: blanks, isLoading }] = useLazyGetBlanksQuery();
  const [trigger] = useUpdateBlankMutation();

  useEffect(() => {
    fetchBlanks({ page: globalPage, name, isActive: globalBlanksState });
  }, [globalPage, name, globalBlanksState]);

  useEffect(() => {
    if (blanks && blanks.items) {
      const newState: { [key: string]: boolean } = {};
      blanks.items.forEach((blank) => {
        if (blank.id && blank.is_active) newState[blank.id.toString()] = blank.is_active;
      });
      setBlankActive(newState);
    }
  }, [blanks]);

  const onPaginate = (page: number) => {
    dispatch(setCurrentPage({ pageNumber: page, pageName: 'blanksCurrentPage' }));
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  // Function to handle checkBox click
  const onCheckBoxClick = (blankId: number) => {
    const isActive = { ...isBlankActive };
    isActive[blankId.toString()] = !isBlankActive[blankId.toString()];
    setBlankActive(isActive);

    // Update the server with the new checkBox state
    trigger({ blankId, isActive: isActive[blankId.toString()] });
  };

  const onActionClick = (blank: BlanksType | undefined, type: string | undefined) => {
    dispatch(setCurrentPage({ previousPageUrl: '/main/Blanks', previousPageName: 'previousPageOfBlankVariants' }));
    if (type === 'activation' && blank) {
      if (blank.id) onCheckBoxClick(blank.id);
    } else if (type === 'delete' && blank && blank.id) {
      setCurrentId(blank.id);
      setOpenDeleteModal(true);
    } else if (type === 'mockup' && blank && blank.id) {
      navigate(`/main/sharp/${blank?.id}`);
    } else if (type === 'showInfo' && blank && blank.id) {
      navigate(`/main/blank-information/${blank?.id}`);
    } else if (type === 'property' && blank && blank.id) {
      navigate(`/main/blank-properties/${blank?.id}`);
    } else if (type === 'offers' && blank && blank.id) {
      navigate(`/main/blank-offers/${blank?.id}`);
    } else if (type === 'showBlankPrintLocations' && blank && blank.id) {
      navigate(`/main/blank-print-locations/${blank?.id}`);
    } else {
      navigate(`/main/blank-variants/${blank?.id}`);
    }
  };

  const onClickDelete = () => {
    deleteTrigger(currentId);
    setOpenDeleteModal(false);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;

    setSelectedValue(newValue);

    if (newValue === 'true') {
      dispatch(setCurrentPage({ isActive: true }));
    } else if (newValue === 'false') {
      dispatch(setCurrentPage({ isActive: false }));
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <p className={styles.title}> Blanks</p>
        <Col>
          <p className={styles.line}></p>
        </Col>
        <div className={styles.separator}></div>
        <Row className={styles.search}>
          {placeHolders.map((placeHolder, i) => (
            <Col key={i}>
              {placeHolder === 'Active' ? ( // Check if placeHolder is 'Active'
                <select
                  className={`${styles.inputs} ${styles.select}`}
                  value={selectedValue} // Set the selected value based on the state variable
                  onChange={handleSelectChange}
                >
                  <option value="false">Inactive</option>
                  <option value="true">Active</option>
                </select>
              ) : (
                <input
                  className={styles.inputs}
                  placeholder={placeHolder}
                  onChange={(event) => {
                    setValueOfName(event.target.value);
                  }}
                />
              )}
            </Col>
          ))}

          <Button
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => {
              setName(valueOfName);
              fetchBlanks({ page: globalPage, name, isActive: globalBlanksState });
            }}
          />
        </Row>

        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick, isBlankActive)}
          className={styles.table}
          dataSource={blanks?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: globalPage,
            pageSize: blanks?.meta?.itemsPerPage ?? 0,
            total: blanks?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
          onRow={(Blank: BlanksType) => {
            return {
              onClick: () => {
                onActionClick(Blank, 'blankProvider');
              },
              style: { cursor: 'pointer' },
            };
          }}
        />
      </Row>
      <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
    </Row>
  );
};

export default Blanks;
