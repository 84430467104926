import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { DataType } from './data.type';

export const imagesApi = createApi({
  reducerPath: 'Diffusion',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['Images'],
  endpoints: (builder) => ({
    getDiffusionFiles: builder.query<DataType, { page: number }>({
      query: (object) => ({ url: `/diffusion-files?limit=24&page=${object.page}`, method: 'get' }),
      providesTags: ['Images'],
    }),
    getGallery: builder.query<DataType, { page: number }>({
      query: (object) => ({ url: `/gallery?limit=24&page=${object.page}`, method: 'get' }),
      providesTags: ['Images'],
    }),
    deleteGallery: builder.mutation<string, number[]>({
      query: (ids: number[]) => ({ url: `/gallery`, method: 'delete', data: { ids: ids } }),
      invalidatesTags: ['Images'],
    }),
    copyDiffusionFiles: builder.mutation<string, number[]>({
      query: (ids: number[]) => ({ url: `/diffusion-files/copy`, method: 'post', data: { ids: ids } }),
      invalidatesTags: ['Images'],
    }),
  }),
});

export const {
  useGetDiffusionFilesQuery,
  useGetGalleryQuery,
  useDeleteGalleryMutation,
  useCopyDiffusionFilesMutation,
} = imagesApi;
