import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { Order, OrderModel } from './orders.model';

interface refundObject {
  orderId: number;
  refundAmount: number;
  refundType: string;
}

export const ordersApi = createApi({
  reducerPath: 'orders',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['order'],
  endpoints: (builder) => ({
    getOrders: builder.query<OrderModel, number>({
      query: (page) => ({ url: `/orders?limit=10&page=${page}`, method: 'get' }),
      providesTags: ['order'],
    }),
    getOrderByUserId: builder.query<OrderModel, { user_id: number; page: number }>({
      query: (data: { user_id: number; page: number }) => ({
        url: `/orders/user/${data.user_id}?limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['order'],
    }),
    getOrderById: builder.query<Order, number>({
      query: (orderId: number) => ({
        url: `/orders/${orderId}`,
        method: 'get',
      }),
      providesTags: ['order'],
    }),
    orderRefund: builder.mutation<string, refundObject>({
      query: (data: { orderId: number; refundAmount: number; refundType: string }) => ({
        url: `/orders/${data.orderId}/refund?refund=${data.refundType}`,
        method: 'Post',
        data: { amount: data.refundAmount },
      }),
      invalidatesTags: ['order'],
    }),
    deleteOrder: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/orders/${id}`, method: 'delete' };
      },
      invalidatesTags: ['order'],
    }),
  }),
});

export const {
  useGetOrderByUserIdQuery,
  useOrderRefundMutation,
  useGetOrderByIdQuery,
  useGetOrdersQuery,
  useDeleteOrderMutation,
} = ordersApi;
