import { ProCard } from '@ant-design/pro-components';
import { Col, Row } from 'antd';
import React from 'react';

import { TrainingStatuses } from '../../redux/trainings/training/training.model';
import styles from './PieDescription.module.css';
import { PiePercentage } from './PiePercentage';

export const PieDescription = ({ total, data }: TrainingStatuses) => {
  return (
    <div className={styles.descriptionContainer}>
      <ProCard ghost className={styles.totalNumber}>
        <ProCard bordered className={styles.subContainer}>
          Total Number: {total}
        </ProCard>
      </ProCard>
      <PiePercentage total={total ?? 100} data={data} />
      <ProCard gutter={8} className={styles.subContainer}>
        {data.map((item, i) => {
          return (
            <Row key={i}>
              <Col style={{ backgroundColor: item.fill }} className={styles.circle}></Col>
              <Col className={styles.typeName}>{item.name}</Col>
            </Row>
          );
        })}
      </ProCard>
    </div>
  );
};
