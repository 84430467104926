import './Loading.css';

import { Spin } from 'antd';
import React from 'react';

const Loading = () => {
  return (
    <div className={'loadingContainer'}>
      <Spin tip="Loading..." className={'loading'} size={'large'}></Spin>
    </div>
  );
};

export default Loading;
