import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NavigationsType } from './navigations.type';

const initialState: NavigationsType = {
  pages: {
    blanksCurrentPage: 1,
    blankVariantsCurrentPage: 1,
    blankVariantMockupsCurrentPage: 1,
    usersCurrentPage: 1,
    trainingTextCurrentPage: 1,
    ordersCurrentPage: 1,
    trainingCurrentPage: 1,
  },
  previousPages: {
    previousPageOfBlankVariants: '',
    previousPageOfMockupsFromBV: '',
    previousPageOfMockupsFromBVM: '',
    previousPageOfBlankVariantMockups: '',
  },
  isBlankActive: false,
  isUserSelected: false,
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setCurrentPage: (
      state,
      action: PayloadAction<{
        pageNumber?: number;
        pageName?: keyof NavigationsType['pages'];
        isActive?: boolean;
        previousPageUrl?: string;
        previousPageName?: keyof NavigationsType['previousPages'];
        isUserSelected?: boolean;
      }>,
    ) => {
      const { pageNumber, pageName, isUserSelected, isActive, previousPageUrl, previousPageName } = action.payload;
      if (pageName !== undefined) {
        return {
          ...state,
          pages: {
            ...state.pages,
            [pageName]: pageNumber,
          },
        };
      } else if (isActive !== undefined) {
        return {
          ...state,
          isBlankActive: isActive,
          pages: {
            ...state.pages,
            blanksCurrentPage: 1,
          },
        };
      } else if (isUserSelected != undefined) {
        return {
          ...state,
          isUserSelected: isUserSelected,
          pages: {
            ...state.pages,
            usersCurrentPage: 1,
          },
        };
      } else if (previousPageName !== undefined) {
        return {
          ...state,
          previousPages: {
            ...state.previousPages,
            [previousPageName]: previousPageUrl,
          },
        };
      }
      return state;
    },
  },
});
export const { setCurrentPage } = paginationSlice.actions;
export default paginationSlice.reducer;
