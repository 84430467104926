import Icon, { AimOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { MenuProps } from 'antd';
import React from 'react';

import { AiImageIcon } from '../../assets/icons/AiImageIcon';
import { BlanksIcon } from '../../assets/icons/BlanksIcon';
import { DashboardLogoIcon } from '../../assets/icons/DashboardLogoIcon';
import { FAQIcon } from '../../assets/icons/FAQIcon';
import { GalleryImage } from '../../assets/icons/GalleryIcon';
import { OffersIcon } from '../../assets/icons/OffersIcon';
import { OrdersIcon } from '../../assets/icons/OrderIcon';
import { ToolsIcon } from '../../assets/icons/ToolsIcon';
import { UserProfileIcon } from '../../assets/icons/UserProfileIcon';
import styles from './DashboardMenu.module.css';

const ProfileIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={UserProfileIcon} {...props} />;
const DashboardIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={DashboardLogoIcon} {...props} />;
const ToolIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={ToolsIcon} {...props} />;
const AiImagesIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={AiImageIcon} {...props} />;
const GalleryIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={GalleryImage} {...props} />;

const BlankIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={BlanksIcon} {...props} />;
const FaqIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={FAQIcon} {...props} />;

const OrderIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={OrdersIcon} {...props} />;

const OfferIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={OffersIcon} {...props} />;

const DashboardData = () => {
  type MenuItem = Required<MenuProps>['items'][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    className?: string | undefined,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      className,
      children,
      label,
      type,
    } as MenuItem;
  }
  {
    return [
      getItem('Dashboard', 'dashboard', <DashboardIcon className={styles.dashboardIcon} />, styles.dashboard),
      getItem('Users', 'users', <ProfileIcon className={styles.icon} />, styles.users),
      getItem('Models', 'models', <AimOutlined className={styles.modelIcon} />, styles.users),
      getItem('Orders', 'orders', <OrderIcon className={styles.icon} />, styles.galleryIcon),
      getItem('Blanks', 'Blanks', <BlankIcon className={styles.icon} />, styles.galleryIcon),
      getItem('Offers', 'offers', <OfferIcon className={styles.offerIcon} />, styles.tools, [
        getItem('Types', 'offerType', '', styles.subTool),
      ]),
      getItem('AI Images', 'aiImages', <AiImagesIcon className={styles.icon} />, styles.aiImage),
      getItem('Gallery', 'gallery', <GalleryIcon className={styles.icon} />, styles.galleryIcon),
      getItem('Tools', 'tools', <ToolIcon className={styles.icon} />, styles.tools, [
        getItem('Image Type', 'imageType', '', styles.subTool),
        getItem('Training', 'training', '', styles.subTool),
        getItem('Mockup Provider', 'mockupProvider', '', styles.subTool),
      ]),
      getItem('Lab', 'lab', <DeploymentUnitOutlined className={styles.modelIcon} />, styles.users),
      getItem('FAQ', 'FAQ', <FaqIcon className={styles.faq} />, styles.galleryIcon),
    ];
  }
};

export default DashboardData;
