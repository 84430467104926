import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { BlankVariantsModel } from './blankVariants.model';
export const blankVariantsApi = createApi({
  reducerPath: 'blankVariants',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['blankVariants'],
  endpoints: (builder) => ({
    getBlankById: builder.query<BlankVariantsModel, { blank_id: number; page: number }>({
      query: (data: { blank_id: number; page: number }) => ({
        url: `/blank-variants/${data.blank_id}?limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['blankVariants'],
    }),
    updateBlankVariantRetailPrice: builder.mutation<
      BlankVariantsModel,
      { retailPrice: number | undefined; blankVariantId: number | undefined }
    >({
      query: ({ retailPrice, blankVariantId }) => ({
        url: `/blank-variants/${blankVariantId}`,
        method: 'patch',
        data: { retailPrice: retailPrice },
      }),
      invalidatesTags: ['blankVariants'],
    }),
      updateBlankVariantActivation: builder.mutation<
          BlankVariantsModel,
          { isActive: boolean | undefined; blankVariantId: number | undefined }
      >({
          query: ({ isActive, blankVariantId }) => ({
              url: `/blank-variants/${blankVariantId}`,
              method: 'patch',
              data: { active: isActive },
          }),
          invalidatesTags: ['blankVariants'],
      }),
  }),
});

export const { useGetBlankByIdQuery, useUpdateBlankVariantRetailPriceMutation, useUpdateBlankVariantActivationMutation } = blankVariantsApi;
