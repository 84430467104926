import { Tooltip } from 'antd';
import React from 'react';

import closeIcon from '../../../assets/icon _close circle_.svg';
import editIcon from '../../../assets/icon _edit_.svg';
import imageRulesIcon from '../../../assets/image_rules.svg';
import raffleIcon from '../../../assets/raffle.svg';
import rulesIcon from '../../../assets/rules_icon.svg';
import textIcon from '../../../assets/text_model.svg';
import { TrainingModel } from '../../../redux/trainings/training/training.model';
import { formatDate } from '../../../utils/helpers';
import styles from '../../users/models/TrainingModels.module.css';
const columns = (
  onActionClick: (_id: TrainingModel, _type: string) => void,
  onIconClick: (_id: number, _type: string) => void,
) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <a>{text}</a>;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Value',
        key: 'value',
        dataIndex: 'value',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Active',
        key: 'active',
        dataIndex: 'active',
        render: (text: boolean) => {
          return <p>{text.toString()}</p>;
        },
      },
      {
        title: 'Created at',
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: '',
        key: 'edit',
        render: (data: TrainingModel) => {
          return (
            <Tooltip title="Edit" color="#23a89e">
              <img
                alt={'Edit'}
                style={{ cursor: 'pointer' }}
                src={editIcon}
                className={styles.editIcon}
                onClick={() => {
                  onActionClick(data, 'edit');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'text',
        render: (record: { id: number }) => {
          return (
            <Tooltip title="Text" color="#23a89e">
              <img
                alt={'Text'}
                src={textIcon}
                className={styles.icon}
                onClick={() => {
                  onIconClick(record.id, 'text');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'rules',
        render: (record: { id: number }) => {
          return (
            <Tooltip title="Rules" color="#23a89e">
              <img
                alt={'Rules'}
                style={{ cursor: 'pointer' }}
                src={rulesIcon}
                className={styles.icon}
                onClick={() => {
                  onIconClick(record.id, 'rules');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'image_rules',
        render: (record: { id: number }) => {
          return (
            <Tooltip title="Image Rules" color="#23a89e">
              <img
                alt={'Image rules'}
                style={{ cursor: 'pointer' }}
                src={imageRulesIcon}
                className={styles.icon}
                onClick={() => {
                  onIconClick(record.id, 'image_rules');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'raffle',
        render: (record: { id: number }) => {
          return (
            <Tooltip title="Raffle" color="#23a89e">
              <img
                alt={'Raffle'}
                style={{ cursor: 'pointer' }}
                src={raffleIcon}
                className={styles.deleteIcon}
                onClick={() => {
                  onIconClick(record.id, 'raffle');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'close',
        render: (data: TrainingModel) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={closeIcon}
                className={styles.deleteIcon}
                onClick={() => {
                  onActionClick(data, 'delete');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};
export default columns;
