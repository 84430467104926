import { InfoCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { Switch, Tooltip } from 'antd';

import OfferIcon from '../../assets/icon _blank offer_.svg';
import PropertyIcon from '../../assets/icon _blank property_.svg';
import DeleteIcon from '../../assets/icon _close circle_.svg';
import { Blanks } from '../../redux/blanks/blanks.model';
import { formatDate } from '../../utils/helpers';
import styles from './Blanks.module.css';

const columns = (
  onActionClick: (_blank?: Blanks | undefined, _type?: string | undefined, _active?: boolean) => void,
  setBlankActive: { [key: string]: boolean },
) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Blank',
        dataIndex: 'image',
        key: 'Image',
        render: (img: string) => {
          return (
            <img
              alt={img}
              height={40}
              width={40}
              src={img}
              className={styles.image}
              onError={(e) => {
                e.currentTarget.style.display = 'none';
              }}
            />
          );
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'description',
        dataIndex: 'description',
        key: 'description',

        render: (text: string) => {
          const tempElement = document.createElement('div');
          tempElement.innerHTML = text;
          const textContent = tempElement.textContent;
          return <p>{textContent}</p>;
        },
      },
      {
        title: 'Active',
        key: 'is_active',
        render: (blank: Blanks) => {
          let isActive = false;
          if (blank.id) {
            isActive = setBlankActive[blank.id.toString()];
          }
          return (
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Switch
                checked={isActive}
                onChange={() => {
                  onActionClick(blank, 'activation');
                }}
              />
            </div>
          );
        },
      },
      {
        title: 'Created at',
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: '',
        key: 'Blank Property',
        render: (blank: Blanks) => {
          return (
            <Tooltip title="Properties" color="#23a89e">
              <img
                alt={'Property'}
                style={{ cursor: 'pointer' }}
                src={PropertyIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(blank, 'property');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'Blank Offer',
        render: (blank: Blanks) => {
          return (
            <Tooltip title="Blank Offers" color="#23a89e">
              <img
                alt={'Offers'}
                style={{ cursor: 'pointer' }}
                src={OfferIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(blank, 'offers');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'Blank Information',
        render: (blank: Blanks) => {
          return (
            <Tooltip title="Show blank Information" color="#23a89e">
              <InfoCircleOutlined
                className={styles.informationIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(blank, 'showInfo');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'Blank Print Locations',
        render: (blank: Blanks) => {
          return (
            <Tooltip title="Show blank print locations" color="#23a89e">
              <PrinterOutlined
                className={styles.printerIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(blank, 'showBlankPrintLocations');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'Delete',
        render: (blank: Blanks) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={DeleteIcon}
                className={styles.deleteIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick(blank, 'delete');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};
export default columns;
