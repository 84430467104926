import { RightCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

import DeleteIcon from '../../assets/icon _close circle_.svg';
import { Blanks } from '../../redux/blanks/blanks.model';
import styles from '../blanks/Blanks.module.css';
const columns = (
  onActionClick: (_blankVariantMockup?: Blanks | undefined, _type?: string | undefined, _active?: boolean) => void,
) => {
  {
    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (text: string) => <p>{text}</p>,
      },
      {
        title: 'Provider name',
        dataIndex: ['blankVariantMockupProvider', 'name'],
        key: 'name',
        render: (text: string) => <p>{text}</p>,
      },

      {
        title: 'Number of stages',
        dataIndex: 'blankVariantMockupStages',
        key: 'stages',
        render: (stages: any[]) => <p>{stages.length}</p>,
      },
      {
        title: 'Mockup',
        key: 'Blank Information',
        render: (blankVariantMockup: Blanks) => {
          return (
            <Tooltip title="Edit Mockup" color="#23a89e">
              <RightCircleOutlined
                className={styles.informationIcon}
                onClick={() => {
                  onActionClick(blankVariantMockup, 'mockup');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'Delete',
        key: 'Blank variant mockup',
        render: (blankVariantMockup: Blanks) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={DeleteIcon}
                className={styles.deleteIcon}
                onClick={() => {
                  onActionClick(blankVariantMockup, 'delete');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};
export default columns;
