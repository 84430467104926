import { EyeOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Switch, Tooltip } from 'antd';
import React from 'react';

import editIcon from '../../assets/icon _edit_.svg';
import { BlankVariants } from '../../redux/blanks/blanks.model';
import styles from '../blanks/Blanks.module.css';
const columns = (
  onActionClick: (_blankVariant?: BlankVariants | undefined, _type?: string | undefined, _active?: boolean) => void,
) => {
  {
    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (text: string) => <p>{text}</p>,
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (text: string) => <p>{text}</p>,
      },
      {
        title: 'Retail Price',
        dataIndex: 'retail_price',
        key: 'retail_price',
        render: (text: string) => <p>{text}</p>,
      },

      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text: string) => <p>{text}</p>,
      },
      {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
        render: (text: string) => <p>{text}</p>,
      },
      {
        title: 'Out Of Stock',
        dataIndex: 'is_out_of_stock',
        key: 'is_out_of_stock',
        render: (status: boolean) => <p>{status ? 'Yes' : 'No'}</p>,
      },
      {
        key: 'updateRetailPrice',
        render: (blankVariant: BlankVariants) => {
          return (
            <Tooltip title="Update Retail Price" color="#23a89e">
              <img
                alt={'Edit'}
                style={{ cursor: 'pointer' }}
                src={editIcon}
                className={styles.editIcon}
                onClick={() => {
                  onActionClick(blankVariant, 'updateRetailPrice');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'Active',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (status: boolean, blankVariant: BlankVariants) => (
          <Switch
            checked={status}
            onChange={() => {
              onActionClick(blankVariant, 'activation');
            }}
          />
        ),
      },
      {
        title: 'View',
        key: 'Blank Information',
        render: (blankVariant: BlankVariants) => {
          return (
            <Tooltip title="View Mockups" color="#23a89e">
              <EyeOutlined
                className={styles.informationIcon}
                onClick={() => {
                  onActionClick(blankVariant, 'viewMockups');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'Sharp',
        key: 'Blank Information',
        render: (blankVariant: BlankVariants) => {
          return (
            <Tooltip title="Create Sharp" color="#23a89e">
              <RightCircleOutlined
                className={styles.informationIcon}
                onClick={() => {
                  onActionClick(blankVariant, 'sharp');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'GIMP',
        key: 'Blank Information',
        render: (blankVariant: BlankVariants) => {
          return (
            <Tooltip title="Create GIMP" color="#23a89e">
              <RightCircleOutlined
                className={styles.informationIcon}
                onClick={() => {
                  onActionClick(blankVariant, 'gimp');
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }
};
export default columns;
