import {Image} from "antd";
import React from 'react';

import EmptyLogo from '../../assets/EmptyLogo.svg'
import styles from './Empty.module.css';



const Empty = () => {
    return (
        <div className={styles.emptyContainer}>
             <Image src={EmptyLogo} preview={false} className={styles.emptyLogo}/>
        </div>
    );
};

export default Empty;
