import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../redux/hooks';
import AuthNav from './AuthNav';
import UnAuthNav from './UnAuthNav';

const Navigator = () => {
  const tokens = useAppSelector((state) => state.authenticate);

  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (tokens) {
      if (tokens.accessToken !== '') {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    }
  }, [tokens, isAuth]);

  return <>{isAuth ? <AuthNav /> : <UnAuthNav />}</>;
};

export default Navigator;
