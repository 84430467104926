import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Dashboard from '../component/dashboard/Dashboard';
import DashboardMenu from '../component/dashboard/DashboardMenu';
import TrainingText from '../component/trainingText/TrainingText';
import Login from '../containers/auth/login/Login';
import BlankInformation from '../containers/blankInformation/BlankInformation';
import BlankOffers from '../containers/blankOffers/BlankOffers';
import BlankPrintLocationImageRules from '../containers/blankPrintLocationImageRules/BlankPrintLocationImageRules';
import BlankPrintLocations from '../containers/blankPrintLocations/BlankPrintLocations';
import BlankProperties from '../containers/blankProperties/BlankProperties';
import Blanks from '../containers/blanks/Blanks';
import BlankVariantMockups from '../containers/blankVariantMockups/BlankVariantMockups';
import BlankVariants from '../containers/blankVariants/BlankVariants';
import Diffusion from '../containers/diffusion/Diffusion';
import Faq from '../containers/faq/Faq';
import Gallery from '../containers/gallery/Gallery';
import Gimp from '../containers/gimp/Gimp';
import CreateExperiment from '../containers/lab/CreateExperiment/CreateExperiment';
import Lab from '../containers/lab/Lab/Lab';
import MockupProvider from '../containers/mockupProvider/MockupProvider';
import Mockups from '../containers/mockups/Mockups';
import Models from '../containers/models/Models';
import OfferType from '../containers/offerType/OfferType';
import OrderInformation from '../containers/orderInformation/OrderInformation';
import OrderLineItems from '../containers/orderLineItem/OrderLineItem';
import Order from '../containers/orders/Order';
import ProductVariant from '../containers/productVariant/ProductVariant';
import Training from '../containers/trainings/training/Training';
import TrainingCategory from '../containers/trainings/trainingCategory/Training.category';
import TrainingImageRules from '../containers/trainings/trainingImageRules/Training.image.rules';
import TrainingRaffle from '../containers/trainings/trainingRaffle/Training.raffle';
import TrainingRequirements from '../containers/trainings/trainingRequirements/Training.requirements';
import TrainingRules from '../containers/trainings/trainingRules/Training.rules';
import TrainingModels from '../containers/users/models/TrainingModels';
import Profile from '../containers/users/profile/Profile';
import Users from '../containers/users/users/Users';
import styles from './AuthNav.module.css';
const AuthNav = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/main/*"
            element={
              <div className={styles.authNav}>
                <DashboardMenu></DashboardMenu>
                <Routes>
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/models/:id" element={<TrainingModels />} />
                  <Route path="/users/profile/:id" element={<Profile />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/training" element={<Training />} />
                  <Route path="/aiImages" element={<Diffusion />} />
                  <Route path="/gallery" element={<Gallery />} />
                  <Route path="/training/category" element={<TrainingCategory />} />
                  <Route path="/training/category/raffle/:id" element={<TrainingRaffle />} />
                  <Route path="/training/trainingText/:id" element={<TrainingText />} />
                  <Route path="/training/text/:id" element={<TrainingText />} />
                  <Route path="/training/rules/:id" element={<TrainingRules />} />
                  <Route path="/training/text/:id/requirements/:id" element={<TrainingRequirements />} />
                  <Route path="/training/image/rules/training/:id" element={<TrainingImageRules />} />
                  <Route path="/blanks" element={<Blanks />} />
                  <Route path="/blank-properties/:id" element={<BlankProperties />} />
                  <Route path="/blank-variants/:id" element={<BlankVariants />} />
                  <Route path="/sharp" element={<Mockups />} />
                  <Route path="/blank-information/:id" element={<BlankInformation />} />
                  <Route path="/sharp/:blankVariantId/:mockupId?" element={<Mockups />} />
                  <Route path="/blank-variant-mockups/:id" element={<BlankVariantMockups />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/users/order/:id" element={<Order />} />
                  <Route path="/orders" element={<Order />} />
                  <Route path="/orders/order-information/:id" element={<OrderInformation />} />
                  <Route path="/blank-offers/:id" element={<BlankOffers />} />
                  <Route path="gimp/:id" element={<Gimp />} />
                  <Route path="/blank-print-locations/:id" element={<BlankPrintLocations />} />
                  <Route path="/blank-print-location-image-rules/:id" element={<BlankPrintLocationImageRules />} />
                  <Route path="/offerType" element={<OfferType />} />
                  <Route path="/mockupProvider" element={<MockupProvider />} />
                  <Route path="/orders/order-information/order-line-item/:id" element={<OrderLineItems />} />
                  <Route
                    path="/orders/order-information/order-line-item/product-variant/:id/:productVariantId"
                    element={<ProductVariant />}
                  />
                  <Route path="/models" element={<Models />} />
                  <Route path="/lab" element={<Lab />} />
                  <Route path="/lab/add-experiment/:id" element={<CreateExperiment />} />
                </Routes>
              </div>
            }
          />
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AuthNav;
