import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { BlanksModel } from './blanks.model';

interface argsType {
  name?: string | undefined;
  isActive?: boolean | undefined;
  page: number;
}

export const blanksApi = createApi({
  reducerPath: 'blanks',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['blanks', 'userProfile'],
  endpoints: (builder) => ({
    getBlanks: builder.query<BlanksModel, argsType>({
      query: (object) => {
        const { name, isActive, page } = object;
        let url = `/blanks?limit=10&page=${page}`;

        if (name) {
          url = `/blanks?limit=10&page=1&name=${name}`;
        }
        if (isActive) {
          url += `&active=${isActive}`;
        }

        return { url, method: 'get' };
      },
      providesTags: ['blanks'],
    }),

    updateBlank: builder.mutation<BlanksModel, { isActive: boolean | undefined; blankId: number | undefined }>({
      query: ({ isActive, blankId }) => ({ url: `/blanks/${blankId}`, method: 'patch', data: { is_active: isActive } }),
      invalidatesTags: ['blanks'],
    }),
    deleteBlank: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/blanks/${id}`, method: 'delete' };
      },
      invalidatesTags: ['blanks'],
    }),
  }),
});
export const { useLazyGetBlanksQuery, useUpdateBlankMutation, useDeleteBlankMutation } = blanksApi;
