import { Col, Row, Table } from 'antd';
import React, { useState } from 'react';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import { useGetModelsQuery } from '../../redux/models/models.actions';
import styles from '../blanks/Blanks.module.css';
import columns from './Models.table';
const Models = () => {
  const [page, setPage] = useState(1);
  const { data: models, isLoading } = useGetModelsQuery({ page });
  const onPaginate = (page: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <p className={styles.title}> Models</p>
        <Col>
          <p className={styles.line}></p>
        </Col>
        <div className={styles.separator}></div>

        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns()}
          className={styles.table}
          dataSource={models?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: models?.meta?.itemsPerPage ?? 0,
            total: models?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
      </Row>
    </Row>
  );
};

export default Models;
