import { Image } from 'antd';
import React from 'react';

import styles from '../orderLineItem/OrderLineItemTable.module.css';
import { ProductVariant } from './ProductVariant.types';
const columns = () => {
  return [
    {
      title: 'Product Variant Id',
      key: 'product_variant_id',
      render: (productVariant: ProductVariant) => <p>{productVariant.id}</p>,
    },
    {
      title: 'Mockups',
      key: 'image',
      render: (productVariant: ProductVariant) => {
        const productImages = productVariant.productVariantMockups.map((mockup, index) => (
          <Image
            key={index}
            src={mockup.path}
            alt={`Product Image ${index + 1}`}
            height={40}
            width={40}
            className={styles.mockups}
          />
        ));

        return <div className={styles.imageContainer}>{productImages}</div>;
      },
    },
    {
      title: 'Art file',
      key: 'image',
      render: (productVariant: ProductVariant) => {
        const productImages = productVariant.productVariantArtFiles.map((artFile, index) => (
          <Image
            key={index}
            src={artFile.path}
            alt={`Product Image ${index + 1}`}
            height={40}
            width={40}
            className={styles.mockups}
          />
        ));

        return <div className={styles.imageContainer}>{productImages}</div>;
      },
    },
    {
      title: 'Print file',
      key: 'image',
      render: (productVariant: ProductVariant) => {
        const productImages = productVariant.productVariantPrintFiles.map((printFile, index) => (
          <Image
            key={index}
            src={printFile.path}
            alt={`Product Image ${index + 1}`}
            height={40}
            width={40}
            className={styles.mockups}
          />
        ));

        return <div className={styles.imageContainer}>{productImages}</div>;
      },
    },
  ];
};
export default columns;
