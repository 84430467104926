import './TrainingTextModal.css';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Row, Select } from 'antd';
import { Col } from 'antd/lib/grid';
import React from 'react';

import styles from './TrainingTextModal.module.css';
import { TrainingTextModalType } from './TrainingTextModal.type';
const { Option } = Select;

const TrainingTextModal = ({
  open,
  cancel,
  inputValues,
  handleTextSubmit,
  form,
  onCategoryScroll,
  categories,
}: TrainingTextModalType) => {
  return (
    <div>
      <Modal
        centered
        open={open}
        onCancel={cancel}
        footer={null}
        style={{ display: 'inline-flex', justifyContent: 'center' }}
        className="custom-modal"
        closeIcon={
          <div className={styles.closeBtnContainer}>
            <CloseOutlined className={styles.closeBtn} />
          </div>
        }
      >
        <Row>
          <Col span={24} className={styles.textModalContainer}>
            <div>
              <p className={styles.title}>Text</p>
            </div>
            <Form initialValues={{ remember: true }} form={form} onFinish={handleTextSubmit}>
              <Form.Item name="text" htmlFor="text" rules={[{ required: true, message: 'Please enter your text !' }]}>
                <Input
                  id="text"
                  name="text"
                  placeholder="Enter Text... "
                  className={styles.textContainer}
                  bordered={false}
                  value={inputValues.text}
                />
              </Form.Item>
              <div>
                <Form.Item
                  label="Category"
                  name={'training_category'}
                  rules={[{ required: true, message: 'Please enter text category!' }]}
                >
                  <Select
                    labelInValue
                    size={'middle'}
                    placeholder={'Select category'}
                    onPopupScroll={onCategoryScroll}
                    listHeight={120}
                  >
                    {categories?.map((data) => {
                      return (
                        <Option key={data.name} value={data.id} id={data.id}>
                          {data.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <Form.Item className={styles.enterContainer}>
                <Button type="primary" htmlType="submit" className={styles.enter}>
                  Enter
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default TrainingTextModal;
