import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { ExampleModel } from './example.model';

export const exampleApi = createApi({
  reducerPath: 'example',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getExample: builder.query<Array<ExampleModel>, string>({
      query: () => ({ url: '/restaurants', method: 'get' }),
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      providesTags: (result) => {
        //to provide tags is used to reformat the data before caching so it handle the data before re-fetching
        //kindly check the documentation to see how it works. The documentation is so clear
        return [];
      },
    }),
  }),
});

export const { useGetExampleQuery } = exampleApi;
