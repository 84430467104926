import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../../utils/axiosBaseQuery';
import { TrainingRaffleModel } from './data.model';
import { TrainingRaffleData } from './trainingRaffleData';

interface argsType {
  id: number;
  page: number;
}

export const trainingRaffleApi = createApi({
  reducerPath: 'trainingRaffle',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['TrainingRaffleModel'],
  endpoints: (builder) => ({
    getAllTrainingRaffle: builder.query<TrainingRaffleModel, argsType>({
      query: (object) => ({
        url: `/training/raffle/${object.id}?limit=10&page=${object.page}`,
        method: 'get',
      }),
      providesTags: ['TrainingRaffleModel'],
    }),
    updateTrainingRaffle: builder.mutation<TrainingRaffleData, Partial<TrainingRaffleData>>({
      query: (data: TrainingRaffleData) => ({
        url: `/training/raffle/${data.id}`,
        method: 'PATCH',
        data: data,
      }),
      invalidatesTags: ['TrainingRaffleModel'],
    }),
    createTrainingRaffle: builder.mutation<TrainingRaffleData, Partial<TrainingRaffleData>>({
      query: (data: TrainingRaffleData) => ({ url: `/training/raffle`, method: 'POST', data: data }),
      invalidatesTags: ['TrainingRaffleModel'],
    }),
  }),
});

export const { useGetAllTrainingRaffleQuery, useUpdateTrainingRaffleMutation, useCreateTrainingRaffleMutation } =
  trainingRaffleApi;
