import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../../component/modals/delete/Delete.modal';
import TrainingRequirementsAddModal from '../../../component/modals/trainingRequirements/TrainingRequirementsAddModal';
import {
  useCreateTrainingRequirementsMutation,
  useDeleteTrainingRequirementsMutation,
  useGetTrainingRequirementsByTrainingPromptIdQuery,
  useUpdateTrainingRequirementsMutation,
} from '../../../redux/trainings/trainingRequirements/training.requirements.action';
import { TrainingRequirementsModel } from '../../../redux/trainings/trainingRequirements/training.requirements.model';
import styles from '../../users/models/TrainingModels.module.css';
import columns from './Training.requirements.table';

const TrainingRequirements = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const trainingPromptId = parseInt(id as string);
  const [page, setPage] = useState(1);

  const { data, isLoading } = useGetTrainingRequirementsByTrainingPromptIdQuery({ id: trainingPromptId, page });
  const [createTrainingRequirements] = useCreateTrainingRequirementsMutation();
  const [deleteTrigger] = useDeleteTrainingRequirementsMutation();
  const [updateTrainingRequirements] = useUpdateTrainingRequirementsMutation();
  const initialState = {
    width: '',
    seed: '',
    height: '',
    samples: '',
    scheduler: '',
    guidance_scale: '',
    enhance_prompt: '',
    negative_prompt: '',
    training_prompt_id: trainingPromptId,
    num_inference_steps: '',
  };
  const [trainingRulesId, setTrainingRequirementsId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [trainingRequirements, setTrainingRequirements] = useState({
    data: initialState,
    type: 'create',
    openModal: false,
    inputValues: initialState,
  });

  useEffect(() => {
    if (trainingRequirements.openModal) {
      form.setFieldsValue(
        trainingRequirements.type === 'create' ? trainingRequirements.inputValues : trainingRequirements.data,
      );
    }
  }, [trainingRequirements.openModal]);

  const onPaginate = (page: number) => {
    setPage(page);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeEditModal = () => {
    setTrainingRequirements({ ...trainingRequirements, openModal: false, inputValues: initialState });
  };

  const onClickDelete = () => {
    deleteTrigger(trainingRulesId);
    setOpenDeleteModal(false);
  };

  const onActionClick = (data: TrainingRequirementsModel, type: string) => {
    if (type === 'delete') {
      if (data.id) {
        setTrainingRequirementsId(data.id);
      }
      setOpenDeleteModal(true);
    } else if (type === 'edit') {
      setTrainingRequirements({ ...trainingRequirements, data, type, openModal: true });
    }
  };

  const updateTrainingRulesState = (
    trainingInputs: TrainingRequirementsModel,
    trainingData: TrainingRequirementsModel,
  ) => {
    if (trainingRequirements.type === 'create') {
      setTrainingRequirements({ ...trainingRequirements, inputValues: trainingInputs });
    } else if (trainingRequirements.type === 'edit') {
      setTrainingRequirements({ ...trainingRequirements, data: trainingData });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const trainingData = { ...trainingRequirements.data, [name]: value };
    const trainingInputs = { ...trainingRequirements.inputValues, [name]: value };

    updateTrainingRulesState(trainingInputs, trainingData);
  };

  const onCreateClick = () => {
    setTrainingRequirements({ ...trainingRequirements, type: 'create', openModal: true });
  };

  const onSubmit = () => {
    const { type, data: trainingData } = trainingRequirements;

    if (type === 'create') {
      createTrainingRequirements(trainingRequirements.inputValues);
      setTrainingRequirements({ ...trainingRequirements, inputValues: initialState, openModal: false });
    } else if (type === 'edit') {
      updateTrainingRequirements(trainingData);
      setTrainingRequirements({ ...trainingRequirements, openModal: false });
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.trainingSubHeader}>
              <p className={styles.mainTitle}> Tools</p>
              <p className={styles.trainingSection}> / Training</p>
              <p className={styles.mainTitle}>/ Training Text</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>Tools - </p> <span className={styles.subTitle}>Training</span>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <Col>
            <p className={styles.closeModelText}>Close</p>
          </Col>
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={data?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: data?.meta?.itemsPerPage ?? 0,
            total: data?.meta?.itemsPerPage ?? 0,
            onChange: onPaginate,
          }}
        />
        {data?.items?.length === 0 ? (
          <FloatButton
            shape="circle"
            className={'floatingButton'}
            icon={<PlusOutlined className={'plusIcon'} />}
            onClick={onCreateClick}
          />
        ) : null}
        <TrainingRequirementsAddModal
          form={form}
          open={trainingRequirements.openModal}
          cancel={closeEditModal}
          onChangeInput={handleInputChange}
          onSubmit={onSubmit}
        ></TrainingRequirementsAddModal>
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
      </Row>
    </Row>
  );
};

export default TrainingRequirements;
