import '../training/AddModal.css';

import { Button, Col, Form, Input, Modal, Row } from 'antd';

import { TrainingModalTypes } from '../training/Training.modal.types';

const TrainingRulesAddModal = ({ onChangeInput, onSubmit, open, cancel, form }: TrainingModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} footer={null} className="training-modal">
      <Row>
        <Col span={24} className={'textContainer'}>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={onSubmit}>
            <Row className={'row'}>
              <div>
                <Col span={22}>
                  <Form.Item
                    label="Learning rate unet"
                    name="learning_rate_unet"
                    rules={[
                      { required: true, message: 'Please input your learning rate unet!' },
                      {
                        pattern: /^(0(\.\d{1,9})?|1(\.0{1,9})?)$/,
                        message: 'Valid value between 0.000001 and 1.',
                      },
                    ]}
                  >
                    <Input
                      className="input"
                      name="learning_rate_unet"
                      type="text"
                      pattern="[0-9]*[.]?[0-9]*"
                      onChange={onChangeInput}
                      inputMode={'numeric'}
                      placeholder="Enter learning rate unet"
                      onKeyPress={(e) => {
                        const isNumeric = /^[0-9.]*$/.test(e.key);
                        if (!isNumeric) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </div>
              <div>
                <Col span={24}>
                  <Form.Item
                    label="Learning rate text encoder"
                    name="learning_rate_text_encoder"
                    rules={[
                      { required: true, message: 'Please input your learning rate text encoder!' },
                      {
                        pattern: /^(0(\.\d{1,9})?|1(\.0{1,9})?)$/,
                        message: 'Valid value between 0.000001 and 1.',
                      },
                    ]}
                  >
                    <Input
                      className="input"
                      name="learning_rate_text_encoder"
                      type="text"
                      pattern="[0-9]*[.]?[0-9]*"
                      inputMode={'numeric'}
                      onChange={onChangeInput}
                      onKeyPress={(e) => {
                        const isNumeric = /^[0-9.]*$/.test(e.key);
                        if (!isNumeric) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Enter learning rate text encoder"
                    />
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Row className={'row'}>
              <div>
                <Col span={22}>
                  <Form.Item
                    label="Seed"
                    name="seed"
                    rules={[
                      { required: true, message: 'Please input your seed!' },
                      { pattern: /^[0-9]*\.?[0-9]*$/, message: 'Please enter a valid numeric value.' },
                    ]}
                  >
                    <Input
                      type="text"
                      pattern="[0-9]*[.]?[0-9]*"
                      onChange={onChangeInput}
                      className="input"
                      inputMode={'numeric'}
                      name="seed"
                      placeholder="Enter seed"
                      onKeyPress={(e) => {
                        const isNumeric = /^[0-9.]*$/.test(e.key);
                        if (!isNumeric) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </div>

              <div>
                <Col span={24}>
                  <Form.Item
                    label="Steps text encoder"
                    name="steps_text_encoder"
                    rules={[{ required: true, message: 'Please input your steps text encoder!' }]}
                  >
                    <Input
                      className="input"
                      name="steps_text_encoder"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter steps text encoder"
                    />
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Row className={'row'}>
              <div>
                <Col span={22}>
                  <Form.Item
                    label="Steps unet"
                    name="steps_unet"
                    rules={[{ required: true, message: 'Please input your steps unet!' }]}
                  >
                    <Input
                      className="input"
                      name="steps_unet"
                      type={'number'}
                      min={0}
                      onChange={onChangeInput}
                      placeholder="Enter steps unet"
                    />
                  </Form.Item>
                </Col>
              </div>
              <div>
                <Col span={24}>
                  <Form.Item
                    label="Base model id"
                    name="base_model_id"
                    rules={[{ required: true, message: 'Please input your base model id!' }]}
                  >
                    <Input
                      className="input"
                      name="base_model_id"
                      onChange={onChangeInput}
                      placeholder="Enter base mode id"
                    />
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Form.Item className={'enterContainer'}>
              <Button type={'primary'} htmlType="submit" className={'submitButton'}>
                Enter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default TrainingRulesAddModal;
