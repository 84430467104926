import { createSlice } from '@reduxjs/toolkit';

import { exampleApi } from './example.actions';
import { CounterState } from './example.type';

const initialState: CounterState = {
  value: 0,
  name: '',
};

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  extraReducers: (builder) => {
    //Just a demo to know how to save the data returned from the server in our reducer
    //So now when we call the function getExample it will be saved inside our localStorage
    // to be used inside all the other pages using the selector function
    builder.addMatcher(exampleApi.endpoints.getExample.matchFulfilled, (state, { payload }) => {
      state.name = payload[0].name;
    });
  },
  reducers: {
    // demo to write a custom reducer after firing the increment function
    incremented(state) {
      state.value++;
    },
    decremented(state) {
      state.value--;
    },
  },
});

export const { incremented } = counterSlice.actions;
export default counterSlice.reducer;
