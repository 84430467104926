import '../../users/models/Antd.css';

import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import arrow from '../../../assets/arrow.svg';
import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../../component/modals/delete/Delete.modal';
import TrainingCategoryAddModal from '../../../component/modals/trainingCategory/TrainingCategoryAddModal';
import {
  useCreateTrainingCategoryMutation,
  useDeleteTrainingCategoryMutation,
  useGetTrainingCategoryQuery,
  useUpdateTrainingCategoryMutation,
} from '../../../redux/trainings/trainingCategory/training.category.actions';
import { TrainingCategoryData } from '../../../redux/trainings/trainingCategory/training.category.model';
import styles from '../../users/models/TrainingModels.module.css';
import columns from './Training.category.table';
const TrainingCategory = () => {
  const [form] = Form.useForm();
  const [trainingCategoryId, setTrainingCategoryId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const initialState = { name: '' };
  const [page, setPage] = useState(1);
  const [trainingCategoryState, setTrainingCategoryState] = useState({
    data: initialState,
    type: 'create',
    openModal: false,
    inputValues: initialState,
  });

  const [createTrainingCategory] = useCreateTrainingCategoryMutation();
  const [deleteTrigger] = useDeleteTrainingCategoryMutation();
  const { data, isLoading } = useGetTrainingCategoryQuery(page);
  const [updateTrainingCategory] = useUpdateTrainingCategoryMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (trainingCategoryState.openModal) {
      form.setFieldsValue(
        trainingCategoryState.type === 'create' ? trainingCategoryState.inputValues : trainingCategoryState.data,
      );
    }
  }, [trainingCategoryState.openModal]);
  const onPaginate = (page: number) => {
    setPage(page);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onClickDelete = () => {
    deleteTrigger(trainingCategoryId);
    setOpenDeleteModal(false);
  };

  const onActionClick = (data: TrainingCategoryData, type: string) => {
    if (type === 'delete') {
      setTrainingCategoryId(data.id);
      setOpenDeleteModal(true);
    } else if (type === 'edit') {
      setTrainingCategoryId(data.id);
      setTrainingCategoryState({ ...trainingCategoryState, data, type, openModal: true });
    }
  };

  const onCreateClick = () => {
    setTrainingCategoryState({ ...trainingCategoryState, type: 'create', openModal: true });
  };

  const onCloseModal = () => {
    setTrainingCategoryState({ ...trainingCategoryState, openModal: false, inputValues: initialState });
  };
  const updateTrainingState = (trainingCategoryInputs: { name: string }, trainingCategoryData: { name: string }) => {
    if (trainingCategoryState.type === 'create') {
      setTrainingCategoryState({ ...trainingCategoryState, inputValues: trainingCategoryInputs });
    } else if (trainingCategoryState.type === 'edit') {
      setTrainingCategoryState({ ...trainingCategoryState, data: trainingCategoryData });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const trainingData = { ...trainingCategoryState.data, [name]: value };
    const trainingInputs = { ...trainingCategoryState.inputValues, [name]: value };

    updateTrainingState(trainingInputs, trainingData);
  };
  const onSubmit = (_data: TrainingCategoryData) => {
    const { type } = trainingCategoryState;

    if (type === 'create') {
      createTrainingCategory({
        name: _data.name,
      });
      setTrainingCategoryState({ ...trainingCategoryState, inputValues: initialState, openModal: false });
    } else if (type === 'edit') {
      updateTrainingCategory({ id: trainingCategoryId, name: _data.name });
      setTrainingCategoryState({ ...trainingCategoryState, openModal: false });
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <img alt={'Arrow'} src={arrow} className={styles.vector} onClick={() => navigate(-1)} />
          </Col>
          <Col>
            <div className={styles.trainingSubHeader}>
              <p className={styles.mainTitle}> Tools</p>
              <p className={styles.trainingSection}> / Training</p>
              <p className={styles.mainTitle}>/ Training Category</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>Training - </p> <span className={styles.subTitle}>Category</span>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={data?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: data?.meta?.itemsPerPage ?? 0,
            total: data?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
        <Tooltip title="Add Training" color="#23a89e">
          <FloatButton
            shape="circle"
            className={'floatingButton'}
            icon={<PlusOutlined className={'plusIcon'} />}
            onClick={onCreateClick}
          />
        </Tooltip>
        <TrainingCategoryAddModal
          form={form}
          open={trainingCategoryState.openModal}
          cancel={onCloseModal}
          inputValues={
            trainingCategoryState.type === 'create' ? trainingCategoryState.inputValues : trainingCategoryState.data
          }
          onChangeInput={handleInputChange}
          onSubmit={onSubmit}
        ></TrainingCategoryAddModal>
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
      </Row>
    </Row>
  );
};

export default TrainingCategory;
