import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../../utils/axiosBaseQuery';
import { DataModel } from './data.model';
import { ImageResizeMethod, ImageTypes, TrainingImageRulesData } from './training.image.rules.model';

interface argsType {
  id: number;
  page: number;
}

export const trainingImageRulesApi = createApi({
  reducerPath: 'trainingImageRules',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['TrainingImageRules', 'ImageTypes', 'ImageResizeMethod'],
  endpoints: (builder) => ({
    getTrainingImageRules: builder.query<DataModel, argsType>({
      query: (object) => ({
        url: `/training/image/rules/training/${object.id}?limit=10&page=${object.page}`,
        method: 'get',
      }),
      providesTags: ['TrainingImageRules'],
    }),
    deleteTrainingImageRules: builder.mutation<string, number>({
      query: (id: number) => ({ url: `/training/image/rules/delete/${id}`, method: 'delete' }),
      invalidatesTags: ['TrainingImageRules'],
    }),
    createTrainingImageRules: builder.mutation<TrainingImageRulesData, Partial<TrainingImageRulesData>>({
      query: (data: TrainingImageRulesData) => ({ url: `/training/image/rules/create`, method: 'POST', data: data }),
      invalidatesTags: ['TrainingImageRules'],
    }),
    updateTrainingImageRules: builder.mutation<TrainingImageRulesData, Partial<TrainingImageRulesData>>({
      query: (data: TrainingImageRulesData) => ({
        url: `/training/image/rules/update/${data.id}`,
        method: 'PATCH',
        data: data,
      }),
      invalidatesTags: ['TrainingImageRules'],
    }),
    getImageTypes: builder.query<Array<ImageTypes>, string>({
      query: () => ({
        url: `/image/types`,
        method: 'get',
      }),
      providesTags: ['ImageTypes'],
    }),
    getImageResizeMethod: builder.query<Array<ImageResizeMethod>, string>({
      query: () => ({
        url: `/image/resize/methods`,
        method: 'get',
      }),
      providesTags: ['ImageResizeMethod'],
    }),
  }),
});

export const {
  useGetTrainingImageRulesQuery,
  useCreateTrainingImageRulesMutation,
  useDeleteTrainingImageRulesMutation,
  useUpdateTrainingImageRulesMutation,
  useGetImageTypesQuery,
  useGetImageResizeMethodQuery,
} = trainingImageRulesApi;
