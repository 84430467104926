import { Switch } from 'antd';

import { BlankPrintLocationImageRules } from '../../redux/blankPrintLocationImageRules/blankPrintLocationImageRules.model';
import { formatDate } from '../../utils/helpers';

const columns = (
  onActionClick: (
    _blankPrintLocationImageRules?: BlankPrintLocationImageRules | undefined,
    _type?: string | undefined,
    _active?: boolean,
  ) => void,
  setBlankActive: { [key: string]: boolean },
) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Blank Print Location Id',
        dataIndex: 'blank_print_location_id',
        key: 'blank_print_location_id',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Min width',
        dataIndex: 'min_width',
        key: 'min_width',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Max width',
        dataIndex: 'max_width',
        key: 'max_width',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Min height',
        dataIndex: 'min_height',
        key: 'min_height',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Max height',
        dataIndex: 'max_height',
        key: 'max_height',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Active',
        key: 'is_active',
        render: (blankPrintLocationImageRules: BlankPrintLocationImageRules) => {
          let isActive = false;
          if (blankPrintLocationImageRules.id) {
            isActive = setBlankActive[blankPrintLocationImageRules.id.toString()];
          }
          return (
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Switch
                checked={isActive}
                onChange={() => {
                  onActionClick(blankPrintLocationImageRules, 'activation');
                }}
              />
            </div>
          );
        },
      },
      {
        title: 'Created at',
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: 'Updated at',
        key: 'updated_at',
        dataIndex: 'updated_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
    ];
  }
};
export default columns;
