import React from 'react';

export const GalleryImage = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99995 20.9999H13.9999C18.9999 20.9999 20.9999 18.9999 20.9999 13.9999V7.99995C20.9999 2.99999 18.9999 1 13.9999 1H7.99995C2.99999 1 1 2.99999 1 7.99995V13.9999C1 18.9999 2.99999 20.9999 7.99995 20.9999Z"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99999 8.99997C9.10458 8.99997 9.99997 8.10455 9.99997 6.99999C9.99997 5.89542 9.10458 5 7.99999 5C6.89542 5 6 5.89542 6 6.99999C6 8.10455 6.89542 8.99997 7.99999 8.99997Z"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66992 17.95L6.59989 14.64C7.38988 14.11 8.52987 14.17 9.23983 14.78L9.56982 15.07C10.3498 15.74 11.6098 15.74 12.3898 15.07L16.5498 11.5001C17.3298 10.8301 18.5898 10.8301 19.3698 11.5001L20.9997 12.9"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
