import { Col, Form, Row, Table } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import AddRetailPriceModal from '../../component/modals/addRetailPriceModal/AddRetailPriceModal.modal';
import useBackNavigation from '../../hooks/useBackNavigation';
import { Blanks as BlanksType } from '../../redux/blanks/blanks.model';
import {
  useGetBlankByIdQuery,
  useUpdateBlankVariantActivationMutation,
  useUpdateBlankVariantRetailPriceMutation,
} from '../../redux/blankVariants/blankVariants.actions';
import { setCurrentPage } from '../../redux/navigations/navigations.reducer';
import { RootState } from '../../redux/navigations/navigations.type';
import styles from './BlankVariants.module.css';
import columns from './BlankVariants.table';
const BlankVariants = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const blank_id = Number(id);
  const dispatch = useDispatch();
  const globalPage = useSelector((state: RootState) => state.pagination.pages.blankVariantsCurrentPage);
  const { data: blankVariants, isLoading } = useGetBlankByIdQuery({ blank_id, page: globalPage });
  const [updateBlankVariantRetailPrice, { isLoading: isEditLoading }] = useUpdateBlankVariantRetailPriceMutation();
  const [updateBlankVariantActivation] = useUpdateBlankVariantActivationMutation();
  const [openUpdateRetailPriceModal, setOpenUpdateRetailPriceModal] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [price, setPrice] = useState(0);
  useBackNavigation('blankVariantsCurrentPage');

  const onPaginate = (page: number) => {
    dispatch(setCurrentPage({ pageNumber: page, pageName: 'blankVariantsCurrentPage' }));
  };

  const onActionClick = async (blankVariant: BlanksType | undefined, type: string | undefined) => {
    if (type === 'sharp' && blankVariant && blankVariant.id) {
      navigate(`/main/sharp/${blankVariant?.id}`);
      dispatch(
        setCurrentPage({
          previousPageUrl: `/main/blank-variants/${blank_id}`,
          previousPageName: 'previousPageOfMockupsFromBV',
        }),
      );
    } else if (type === 'viewMockups' && blankVariant && blankVariant.id) {
      navigate(`/main/blank-variant-mockups/${blankVariant?.id}`);
      dispatch(
        setCurrentPage({
          previousPageUrl: `/main/blank-variants/${blank_id}`,
          previousPageName: 'previousPageOfBlankVariantMockups',
        }),
      );
    } else if (type === 'gimp' && blankVariant && blankVariant.id) {
      navigate(`/main/gimp/${blankVariant?.id}`);
      dispatch(
        setCurrentPage({
          previousPageUrl: `/main/blank-variants/${blank_id}`,
          previousPageName: 'previousPageOfMockupsFromBV',
        }),
      );
    } else if (type === 'activation' && blankVariant && blankVariant.id) {
      try {
        await updateBlankVariantActivation({
          isActive: !blankVariant.is_active,
          blankVariantId: blankVariant.id,
        });
      } catch (error) {
        //handle errors
      }
    } else if (type === 'updateRetailPrice' && blankVariant && blankVariant.id) {
      setCurrentId(blankVariant.id);
      const matchingBlankVariant = blankVariants?.items.find((item) => item.id === blankVariant.id);
      const retailPrice = matchingBlankVariant?.retail_price ?? null;
      const blankVariantPrice = matchingBlankVariant?.price ?? 0;
      setPrice(blankVariantPrice);
      form.setFieldValue(['retailPrice'], retailPrice);
      setOpenUpdateRetailPriceModal(true);
    }
  };

  const closeEditModal = () => {
    setOpenUpdateRetailPriceModal(false);
  };

  const onEditClick = async () => {
    try {
      await updateBlankVariantRetailPrice({
        blankVariantId: currentId,
        retailPrice: form.getFieldValue(['retailPrice']),
      });
      closeEditModal();
    } catch (error) {
      //handle error
    }
  };

  return (
    <>
      <Row className={styles.mainContainer}>
        <Row className={styles.container}>
          <Row className={styles.header}>
            <Col>
              <div className={styles.subHeader}>
                <p className={styles.users}>Blanks</p>
                <p className={styles.details}>/ Blank Variants</p>
              </div>
            </Col>
          </Row>
          <Row className={styles.detailsTitle}>
            <Col className={styles.title}>
              <p>Blank Variants</p>
            </Col>
            <Col>
              <p className={styles.line}></p>
            </Col>
            <div className={styles.margins}></div>
          </Row>
          <Row className={styles.tableContainer}>
            <SmallLoading isLoading={isLoading} />
            <Table
              rowKey="id"
              columns={columns(onActionClick)}
              className={styles.table}
              dataSource={blankVariants?.items}
              scroll={{ x: 500 }}
              pagination={{
                current: globalPage,
                pageSize: blankVariants?.meta?.itemsPerPage ?? 0,
                total: blankVariants?.meta?.totalItems ?? 0,
                onChange: onPaginate,
              }}
            />
          </Row>
        </Row>
        <AddRetailPriceModal
          open={openUpdateRetailPriceModal}
          cancel={closeEditModal}
          form={form}
          action={onEditClick}
          loading={isEditLoading}
          price={price}
        />
      </Row>
    </>
  );
};
export default BlankVariants;
