import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setCurrentPage } from '../redux/navigations/navigations.reducer';

const useBackNavigation = (currentPageName: string) => {
  //This hook is done so that if I am in page 2 in page B and go back to A then B for the pagination to be reset to 1
  const dispatch = useDispatch();

  const handlePopstate = (event: any) => {
    // Handle the back button press here
    if (event.state.usr === null) {
      dispatch(setCurrentPage({ pageNumber: 1, pageName: currentPageName as any }));
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);

    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', handlePopstate);
      }, 1000);
    };
  }, [currentPageName]);

  return;
};

export default useBackNavigation;
