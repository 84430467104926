import { FileImageOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

import galleryIcon from '../assets/galleryIcon.svg';
import refreshIcon from '../assets/refreshIcon.svg';
import trashIcon from '../assets/trashIcon.svg';
import styles from '../containers/users/models/TrainingModels.module.css';
import { getStatusText } from '../utils/helpers';

const columns = (onActionClick: (_id: number, _type: string) => void) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (id: number) => {
          return <a className={styles.id}>{id}</a>;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => {
          const statusText = getStatusText(text, 'model');
          return <p>{statusText}</p>;
        },
      },
      {
        title: 'Type',
        dataIndex: 'training',
        key: 'training',
        render: (type: Record<string, string>) => {
          return <p className={styles.columnTitle}>{type.name}</p>;
        },
      },
      {
        title: 'Model',
        key: 'trainer_id',
        dataIndex: 'trainer_id',
        render: (model: string) => {
          return <p className={styles.columnTitle}>{model}</p>;
        },
      },
      {
        title: '',
        key: 'gallery',
        render: (gallery: { id: number }) => {
          return (
            <Tooltip title="Gallery" color="#23a89e">
              <img
                alt={'Gallery'}
                style={{ cursor: 'pointer' }}
                src={galleryIcon}
                onClick={() => {
                  onActionClick(gallery.id, 'gallery');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'diffusionFiles',
        render: (diffusionFile: { id: number }) => {
          return (
            <Tooltip title="Diffusion files" color="#23a89e">
              <FileImageOutlined
                className={styles.informationIcon}
                onClick={() => {
                  onActionClick(diffusionFile.id, 'diffusionFiles');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'trash',
        render: (model: { id: number }) => {
          return (
            <Tooltip title="Delete" color="#23a89e">
              <img
                alt={'Delete'}
                style={{ cursor: 'pointer' }}
                src={trashIcon}
                onClick={() => {
                  onActionClick(model.id, 'delete');
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'refresh',
        render: () => {
          return (
            <Tooltip title="Refresh" color="#23a89e">
              <img alt={'Refresh'} src={refreshIcon} />
            </Tooltip>
          );
        },
      },
    ];
  }
};
export default columns;
