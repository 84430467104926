import '../training/AddModal.css';

import { Button, Col, Form, InputNumber, Modal, Row } from 'antd';

import { TrainingRaffleModalTypes } from './Training.raffle.modal.types';

const TrainingRaffleEditModal = ({ onChangeInput, onSubmit, open, cancel, form, total }: TrainingRaffleModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} footer={null} className="training-modal">
      <Row>
        <Col span={24} className={'textContainer'}>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={onSubmit}>
            <Form.Item
              label="Quantity selected"
              name="quantity_selected"
              rules={[{ required: true, message: 'Please enter your quantity!' }]}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <InputNumber
                className="input"
                name="quantity_selected"
                type={'number'}
                min={0}
                max={total}
                onChange={onChangeInput}
                placeholder="Enter quantity"
              />
            </Form.Item>

            <Form.Item className={'enterContainer'}>
              <Button type={'primary'} htmlType="submit" className={'submitButton'}>
                Enter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default TrainingRaffleEditModal;
