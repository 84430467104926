import { Col, Form, Row } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';

import PersonalDetails from '../../../component/personalDetails/PersonalDetails';
import { useGetUserProfileQuery } from '../../../redux/users/users.actions';
import styles from './Profile.module.css';

const Profile = () => {
  const { id } = useParams();
  const { data: currentUserData } = useGetUserProfileQuery(Number(id) || 0);
  const [form] = Form.useForm();

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.subHeader}>
              <p className={styles.users}>Users</p>
              <p className={styles.details}>/ User profile</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>User Profile</p>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <div className={styles.margins}></div>
        </Row>
        <PersonalDetails
          form={form}
          phone={currentUserData?.profile?.phone_number}
          emailValue={currentUserData?.email}
          firstNameValue={currentUserData?.profile?.name}
          lastNameValue={currentUserData?.profile?.last_name}
        />
      </Row>
    </Row>
  );
};

export default Profile;
