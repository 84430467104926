export const useFilterAndSetData = <T,>(
  loadedData: T[],
  setData: React.Dispatch<React.SetStateAction<T[]>>,
  existingData: T[],
  idKey: keyof T,
) => {
  if (loadedData) {
    const newLoadedData = loadedData.filter((item) => {
      return !existingData.some((existingItem) => existingItem[idKey] === item[idKey]);
    });
    if (newLoadedData.length > 0) {
      setData((prevData) => [...prevData, ...newLoadedData]);
    }
  }
};
