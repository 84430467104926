import React from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

import { TrainingStatuses } from '../../redux/trainings/training/training.model';
import styles from './Pie.module.css';

export const PieCharts = ({ data }: TrainingStatuses) => {
  return (
    <div className={styles.container}>
      <ResponsiveContainer className={styles.subContainer}>
        <PieChart className={styles.pieSize}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius="40%"
            outerRadius="65%"
            fill="#fff"
            label={({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
              const RADIAN = Math.PI / 180;
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              return (
                <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                  {data[index].name}
                </text>
              );
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
