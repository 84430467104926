import { Spin } from 'antd';
import React from 'react';

import styles from './SmallLoading.module.css';
import { LoadingProps } from './SmallLoading.type';

const SmallLoading = ({ isLoading }: LoadingProps) => {
  return isLoading ? (
    <div className={styles.loading}>
      <Spin />
    </div>
  ) : null;
};

export default SmallLoading;
