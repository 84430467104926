import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table } from 'antd';
import React, { useState } from 'react';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import EditOfferTypeModal from '../../component/modals/addEditOfferTypeModal/AddEditOfferTypeModal.modal';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import {
  useCreateOfferTypesMutation,
  useDeleteOfferTypesMutation,
  useEditOfferTypesMutation,
  useGetOfferTypesQuery,
} from '../../redux/offerTypes/offerTypes.action';
import { OfferTypes } from '../../redux/offerTypes/offerTypes.model';
import styles from '../blanks/Blanks.module.css';
import columns from './OfferType.table';

const OfferType = () => {
  const initialState: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  } = {
    id: -1,
    name: '',
    created_at: '',
    updated_at: '',
  };
  const [form] = Form.useForm();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [page, setPage] = useState(1);
  const [offerTypeState, setOfferTypeState] = useState({
    data: initialState,
    type: 'create',
    openAddEditModal: false,
  });
  const [deleteTrigger, { isLoading: isDeleteLoading }] = useDeleteOfferTypesMutation();
  const [addTrigger, { isLoading: isAddLoading }] = useCreateOfferTypesMutation();
  const [editTrigger, { isLoading: isEditLoading }] = useEditOfferTypesMutation();
  const { data: offerTypes, isLoading } = useGetOfferTypesQuery({ page });

  const onActionClick = async (offerType: OfferTypes | undefined, type: string | undefined) => {
    if (type === 'delete' && offerType && offerType.id) {
      setCurrentId(offerType.id);
      setOpenDeleteModal(true);
    } else if (type === 'edit' && offerType && offerType.id) {
      setCurrentId(offerType.id);
      form.setFieldValue(['name'], offerType?.name);
      setOfferTypeState((prevState) => ({
        ...prevState,
        type: type,
        openAddEditModal: true,
      }));
    }
  };

  const onPaginate = (page: number) => {
    setPage(page);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onClickDelete = async () => {
    await deleteTrigger(currentId);
    setOpenDeleteModal(false);
  };

  const onCreateClick = () => {
    form.setFieldsValue({
      quantity: '',
      value: '',
    });
    form.resetFields(['offerTypes']);
    setOfferTypeState(() => ({
      data: initialState,
      type: 'create',
      openAddEditModal: true,
    }));
  };
  const closeAddEditModal = () => {
    setOfferTypeState({ ...offerTypeState, openAddEditModal: false });
  };

  const onEditClick = async () => {
    try {
      if (offerTypeState.type === 'create') {
        await addTrigger({
          name: form.getFieldValue(['name']),
        });
        closeAddEditModal();
      } else {
        await editTrigger({
          id: currentId,
          name: form.getFieldValue(['name']),
        });
        closeAddEditModal();
      }
    } catch (error) {
      //handle error
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const data = { ...offerTypeState.data, [name]: value };
    setOfferTypeState({ ...offerTypeState, data: data });
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.blanksSubHeader}>
              <p className={styles.users}>Offer</p>
              <p className={styles.details}>/ Types</p>
            </div>
          </Col>
        </Row>
        <Col className={styles.title}>
          <p>Offer Types</p>
        </Col>
        <div className={styles.separator}></div>
        <Col>
          <p className={styles.line}></p>
        </Col>
        <div className={styles.separator}></div>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={offerTypes?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: offerTypes?.meta?.itemsPerPage ?? 0,
            total: offerTypes?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
        <FloatButton
          shape="circle"
          className={'floatingButton'}
          icon={<PlusOutlined className={'plusIcon'} />}
          onClick={onCreateClick}
        />
      </Row>
      <DeleteModal
        openModal={openDeleteModal}
        cancel={closeDeleteModal}
        onDelete={onClickDelete}
        loading={isDeleteLoading}
      />

      <EditOfferTypeModal
        open={offerTypeState.openAddEditModal}
        cancel={closeAddEditModal}
        form={form}
        action={onEditClick}
        onChange={handleInputChange}
        type={offerTypeState.type}
        loading={isAddLoading || isEditLoading}
      />
    </Row>
  );
};

export default OfferType;
