import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { MockupProviderModel, MockupProviderType } from './mockupProvider.model';

export const mockupProviderApi = createApi({
  reducerPath: 'mockupProvider',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['mockupProvider'],
  endpoints: (builder) => ({
    getMockupProvider: builder.query<MockupProviderModel, { page: number }>({
      query: (data: { page: number }) => ({
        url: `/blank-variant-providers?limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['mockupProvider'],
    }),
    createMockupProvider: builder.mutation<MockupProviderType, Partial<MockupProviderType>>({
      query: (data: MockupProviderType) => ({ url: `/blank-variant-providers`, method: 'POST', data: data }),
      invalidatesTags: ['mockupProvider'],
    }),

    deleteMockupProvider: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/blank-variant-providers/${id}`, method: 'delete' };
      },
      invalidatesTags: ['mockupProvider'],
    }),

    editMockupProvider: builder.mutation<MockupProviderType, Partial<MockupProviderType>>({
      query: (data: MockupProviderType) => {
        return { url: `/blank-variant-providers/${data.id}`, method: 'PATCH', data: data };
      },
      invalidatesTags: ['mockupProvider'],
    }),
  }),
});

export const {
  useCreateMockupProviderMutation,
  useDeleteMockupProviderMutation,
  useEditMockupProviderMutation,
  useGetMockupProviderQuery,
} = mockupProviderApi;
