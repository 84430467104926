import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import arrow from '../../assets/arrow.svg';
import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import EditFaqModal from '../../component/modals/editFaq/editFaqModal';
import {
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useEditQuestionMutation,
  useGetQuestionsQuery,
} from '../../redux/faq/faq.actions';
import { faq as FaqType } from '../../redux/faq/faq.model';
import styles from '../blanks/Blanks.module.css';
import columns from './Faq.table';

const Faq = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteTrigger] = useDeleteQuestionMutation();
  const [editTrigger] = useEditQuestionMutation();
  const [createTrigger] = useCreateQuestionMutation();
  const [currentId, setCurrentId] = useState(0);
  const [page, setPage] = useState(1);
  const { data: questions, isLoading } = useGetQuestionsQuery(page);
  const [IsLoading, setIsLoading] = useState(false);
  const initialState: { question_id: number; title: string; description: string } = {
    question_id: currentId,
    title: '',
    description: '',
  };
  const [questionState, setQuestionState] = useState({
    data: initialState,
    type: 'create',
    openAddEditModal: false,
  });

  const onPaginate = (page: number) => {
    setPage(page);
  };

  const onActionClick = (question: FaqType | undefined, type: string | undefined) => {
    if (type === 'delete' && question && question.id) {
      setCurrentId(question.id);
      setOpenDeleteModal(true);
    } else if (type === 'edit' && question && question.id) {
      setCurrentId(question.id);

      form.setFieldValue(['title'], question?.title);
      form.setFieldValue(['description'], question?.description);
      setQuestionState((prevState) => ({
        ...prevState,
        type: type,
        openAddEditModal: true,
      }));
    }
  };

  const onCreateClick = () => {
    form.setFieldsValue({
      title: '',
      description: '',
    });

    setQuestionState(() => ({
      data: initialState,
      type: 'create',
      openAddEditModal: true,
    }));
  };

  const onClickDelete = async () => {
    setIsLoading(true);
    await deleteTrigger(currentId);
    setIsLoading(false);
    setOpenDeleteModal(false);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeAddEditModal = () => {
    setQuestionState({ ...questionState, openAddEditModal: false });
  };

  const onEditClick = async () => {
    const updatedQuestion = {
      id: currentId,
      title: form.getFieldValue(['title']),
      description: form.getFieldValue(['description']),
    };
    try {
      if (questionState.type === 'create') {
        setIsLoading(true);
        await createTrigger({ title: updatedQuestion.title, description: updatedQuestion.description });
        setIsLoading(false);
        closeAddEditModal();
      } else {
        setIsLoading(true);
        await editTrigger(updatedQuestion);
        setIsLoading(false);
        closeAddEditModal();
      }
    } catch (error) {
      // Handle the error here
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const data = { ...questionState.data, [name]: value };
    setQuestionState({ ...questionState, data: data });
  };

  return (
    <>
      <Row className={styles.mainContainer}>
        <Row className={styles.container}>
          <p className={styles.title}> FAQ</p>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <div className={styles.separator}></div>
          <Row className={styles.tableContainer}>
            <SmallLoading isLoading={isLoading} />
            <Table
              rowKey="id"
              columns={columns(onActionClick)}
              className={styles.table}
              dataSource={questions?.items}
              scroll={{ x: 500 }}
              pagination={{
                current: page,
                pageSize: questions?.meta?.itemsPerPage ?? 0,
                total: questions?.meta?.totalItems ?? 0,
                onChange: onPaginate,
              }}
            />
          </Row>{' '}
          <FloatButton
            shape="circle"
            className={'floatingButton'}
            icon={<PlusOutlined className={'plusIcon'} />}
            onClick={onCreateClick}
          />
        </Row>
        <DeleteModal
          openModal={openDeleteModal}
          cancel={closeDeleteModal}
          onDelete={onClickDelete}
          loading={IsLoading}
        />
        <EditFaqModal
          form={form}
          onChange={handleInputChange}
          action={onEditClick}
          cancel={closeAddEditModal}
          open={questionState.openAddEditModal}
          type={questionState.type}
          loading={IsLoading}
        />
      </Row>
    </>
  );
};
export default Faq;
