import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, Spin, Upload } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';

import styles from './addEditBlankPropertyModal.modal.module.css';
import { EditBlankPropertyModalType } from './addEditBlankPropertyModal.modal.types';

const EditBlankPropertyModal = ({
  open,
  cancel,
  form,
  action,
  onChange,
  type,
  loading,
  Property,
  handleChangeImage,
  imageUploaded,
}: EditBlankPropertyModalType) => {
  const [option, setOption] = useState('Select an option');
  const [key, setKey] = useState('');

  useEffect(() => {
    if (Property?.key !== undefined) {
      setKey(Property?.key.toString());
    } else {
      setKey('');
    }
  }, [Property?.key]);

  useEffect(() => {
    if (open && type === 'edit') {
      if (Property?.key !== undefined) {
        setKey(Property?.key.toString());
      } else {
        setKey('');
      }
    }
  }, [open, type]);

  const resetOptionsField = () => {
    form.resetFields(['options']);
  };
  const clickHandler = () => {
    setOption('Select an option');
    setKey('');
  };

  const close = () => {
    setOption('Select an option');
    setKey('');
  };

  if (!open) {
    resetOptionsField();
  }
  return (
    <Modal
      centered
      open={open}
      onCancel={() => {
        cancel();
        close();
      }}
      closable={true}
      footer={[]}
    >
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> {type === 'create' ? 'Add' : 'Edit'} Blank Property </h1>
          </div>
          <Form
            form={form}
            layout={'vertical'}
            autoComplete="off"
            name="basic"
            onFinish={type === 'create' ? () => action('add') : () => action('edit')}
          >
            {type === 'edit' && (
              <Form.Item label="option" name="option" rules={[{ required: true, message: 'Please input an option' }]}>
                <Input
                  className={styles.input}
                  name="option"
                  type={'text'}
                  min={0}
                  placeholder="Enter option"
                  onChange={onChange}
                  disabled={true}
                />
              </Form.Item>
            )}
            {type === 'create' && (
              <Form.Item
                label="Options"
                name="options"
                rules={[{ required: true, message: 'Please select your choice' }]}
              >
                <Select
                  defaultValue={'Select an option'}
                  onChange={(value) => setOption(value)}
                  options={[
                    { value: 'image', label: 'Image' },
                    { value: 'other options', label: 'Other Options' },
                  ]}
                />
              </Form.Item>
            )}

            {key === 'image' ? (
              <Form.Item
                className={styles.uploadContainer}
                label="Image"
                name="image"
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload showUploadList={false} maxCount={1} action="" onChange={handleChangeImage}>
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                  {imageUploaded && <p className={styles.imageUploaded}>Image Uploaded!</p>}
                </Upload>
              </Form.Item>
            ) : (
              key !== '' && (
                <>
                  <Form.Item label="key" name="key" rules={[{ required: true, message: 'Please input the key' }]}>
                    <Input
                      className={styles.input}
                      name="key"
                      type="text"
                      min={0}
                      placeholder="Enter key"
                      onChange={onChange}
                    />
                  </Form.Item>

                  <Form.Item label="value" name="value" rules={[{ required: true, message: 'Please input the value' }]}>
                    <Input
                      className={styles.input}
                      name="value"
                      type="text"
                      min={0}
                      placeholder="Enter value"
                      onChange={onChange}
                    />
                  </Form.Item>
                </>
              )
            )}
            {option === 'image' ? (
              <Form.Item
                className={styles.uploadContainer}
                label="Image"
                name="image"
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload showUploadList={false} maxCount={1} action="" onChange={handleChangeImage}>
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                  {imageUploaded && <p className={styles.imageUploaded}>Image Uploaded!</p>}
                </Upload>
              </Form.Item>
            ) : (
              option === 'other options' && (
                <>
                  <Form.Item label="key" name="key" rules={[{ required: true, message: 'Please input the key' }]}>
                    <Input
                      className={styles.input}
                      name="key"
                      type="text"
                      min={0}
                      placeholder="Enter key"
                      onChange={onChange}
                    />
                  </Form.Item>

                  <Form.Item label="value" name="value" rules={[{ required: true, message: 'Please input the value' }]}>
                    <Input
                      className={styles.input}
                      name="value"
                      type="text"
                      min={0}
                      placeholder="Enter value"
                      onChange={onChange}
                    />
                  </Form.Item>
                </>
              )
            )}

            <Form.Item className={styles.addContainer}>
              <Button
                className={styles.submitButton}
                type="primary"
                htmlType="submit"
                disabled={
                  loading ||
                  (type === 'create' &&
                    option === 'other options' &&
                    (!form.getFieldValue('key') || !form.getFieldValue('value'))) ||
                  (option === 'image' && !imageUploaded)
                }
                onClick={clickHandler}
              >
                {loading ? <Spin className={styles.spin} /> : type === 'create' ? 'Add' : 'Edit'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditBlankPropertyModal;
