import { UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Upload } from 'antd';

import { UploadImageModalTypes } from './UploadImage.modal.types';
import styles from './UploadImage.module.css';

const UploadImageModal = ({ open, cancel, action, handleChangeImage, error, imageUploaded }: UploadImageModalTypes) => {
  return (
    <Modal width={'450px'} centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <h1>Update Image</h1>
      <br />
      <Upload showUploadList={false} maxCount={1} action="" onChange={handleChangeImage}>
        <Button icon={<UploadOutlined />}>Upload Image</Button>
        {imageUploaded && <p className={styles.imageUploaded}>Image Uploaded!</p>}
      </Upload>
      <br />
      {error && <p className={styles.error}>Please upload an image</p>}
      <br />
      <Button className={styles.submitButton} type="primary" onClick={() => action('editImage')}>
        Edit Image
      </Button>
    </Modal>
  );
};

export default UploadImageModal;
