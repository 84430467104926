import '../training/AddModal.css';

import { Button, Form, Modal, Row, Select } from 'antd';
import { Col } from 'antd/lib/grid';
import React from 'react';

import { TrainingRaffleModalTypes } from './Training.raffle.modal.types';
const { Option } = Select;

const TrainingRaffleAddModal = ({
  open,
  cancel,
  onSubmit,
  form,
  categories,
  onCategoryRaffleScroll,
}: TrainingRaffleModalTypes) => {
  return (
    <div>
      <Modal
        centered
        open={open}
        onCancel={cancel}
        footer={null}
        style={{ display: 'inline-flex', justifyContent: 'center' }}
        className="custom-modal"
      >
        <Row>
          <Col span={24} className={'textContainer'}>
            <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={onSubmit}>
              <div>
                <Form.Item
                  label="Category"
                  name={'category'}
                  rules={[{ required: true, message: 'Please enter category!' }]}
                >
                  <Select
                    labelInValue
                    size={'middle'}
                    placeholder={'Select category'}
                    onPopupScroll={onCategoryRaffleScroll}
                    listHeight={120}
                  >
                    {categories?.map((data) => {
                      return (
                        <Option key={data.name} value={data.id} id={data.id}>
                          {data.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <Form.Item className={'enterContainer'}>
                <Button type="primary" htmlType="submit" className={'submitButton'}>
                  Enter
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default TrainingRaffleAddModal;
