import { Button } from 'antd';
import React from 'react';

import { perspectiveData } from './Gimp.types';

export const perspectiveColumns = (onDeletePerspectiveClick: (_index?: number | undefined) => void) => {
  return [
    {
      title: 'Top Left',
      dataIndex: 'topLeft',
      key: 'topLeft',
      render: (text: string, record: perspectiveData) => `${record.topLeftX}, ${record.topLeftY}`,
    },
    {
      title: 'Top Right',
      dataIndex: 'topRight',
      key: 'topRight',
      render: (text: string, record: perspectiveData) => `${record.topRightX}, ${record.topRightY}`,
    },
    {
      title: 'Bottom Left',
      dataIndex: 'bottomLeft',
      key: 'bottomLeft',
      render: (text: string, record: perspectiveData) => `${record.bottomLeftX}, ${record.bottomLeftY}`,
    },
    {
      title: 'Bottom Right',
      dataIndex: 'bottomRight',
      key: 'bottomRight',
      render: (text: string, record: perspectiveData) => `${record.bottomRightX}, ${record.bottomRightY}`,
    },
    {
      title: '',
      key: 'Delete',
      render: (text: number, record: perspectiveData, index: number) => {
        return (
          <Button type="link" onClick={() => onDeletePerspectiveClick(index)}>
            Delete
          </Button>
        );
      },
    },
  ];
};
