import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import EditBlankOfferModal from '../../component/modals/blanksOffersModal/BlankOffersModal.modal';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import ResponseModal from '../../component/modals/responseModal/ResponseModal';
import {
  useCreateBlankOffersMutation,
  useDeleteBlankOfferMutation,
  useEditBlanksOfferMutation,
  useGetBlankOffersQuery,
  useGetBlankOfferTypesQuery,
} from '../../redux/blankOffers/blankOffers.action';
import { BlanksOffers } from '../../redux/blankOffers/blankOffers.model';
import styles from '../blanks/Blanks.module.css';
import columns from './BlankOffers.table';

const BlankOffers = () => {
  const { id } = useParams();
  const blank_id = Number(id);
  const initialState: {
    id: number;
    offer_type_id: number;
    blank_id: number;
    value: number;
    quantity: number;
    created_at: string;
    updated_at: string;
  } = {
    id: -1,
    offer_type_id: -1,
    blank_id: blank_id,
    value: -1,
    quantity: -1,
    created_at: '',
    updated_at: '',
  };
  const [form] = Form.useForm();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [page, setPage] = useState(1);
  const [blankOfferState, setBlankOfferState] = useState({
    data: initialState,
    type: 'create',
    openAddEditModal: false,
  });
  const [deleteTrigger, { isLoading: isDeleteLoading }] = useDeleteBlankOfferMutation();
  const { data: blanksOffers, isLoading } = useGetBlankOffersQuery({ blank_id });
  const [addTrigger, { isLoading: isAddLoading }] = useCreateBlankOffersMutation();
  const [editTrigger, { isLoading: isEditLoading }] = useEditBlanksOfferMutation();
  const { data: blankOffersTypes } = useGetBlankOfferTypesQuery({ page });
  const loadedData: BlanksOffers[] = blankOffersTypes?.items || [];
  const [offerTypes, setOfferTypes] = useState<BlanksOffers[]>(loadedData || []);

  const [modalData, setModalData] = useState({
    open: false,
    message: '',
    messageHeader: '',
  });

  useEffect(() => {
    if (loadedData) {
      const newLoadedData = loadedData.filter((item) => {
        return !offerTypes.some((offerType) => offerType.id === item.id);
      });
      if (newLoadedData.length > 0) {
        setOfferTypes((prevData) => [...prevData, ...newLoadedData]);
      }
    }
  }, [loadedData]);

  const handlePopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.target as HTMLDivElement;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
      if (loadedData && loadedData.length > 0) {
        const nextPage = page + 1;
        setPage(nextPage);
      }
    }
  };

  const onActionClick = async (offer: BlanksOffers | undefined, type: string | undefined) => {
    if (type === 'delete' && offer && offer.blank_id) {
      setCurrentId(offer.id);
      setOpenDeleteModal(true);
    } else if (type === 'edit' && offer && offer.blank_id) {
      setCurrentId(offer.id);
      form.setFieldValue(['value'], offer?.value);
      form.setFieldValue(['quantity'], offer?.quantity);
      setBlankOfferState((prevState) => ({
        ...prevState,
        type: type,
        openAddEditModal: true,
      }));
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onClickDelete = async () => {
    await deleteTrigger(currentId);
    setOpenDeleteModal(false);
  };

  const onCreateClick = () => {
    form.setFieldsValue({
      quantity: '',
      value: '',
    });
    form.resetFields(['offerTypes']);
    setBlankOfferState(() => ({
      data: initialState,
      type: 'create',
      openAddEditModal: true,
    }));
  };
  const closeAddEditModal = () => {
    setBlankOfferState({ ...blankOfferState, openAddEditModal: false });
  };

  const onEditClick = async () => {
    try {
      if (blankOfferState.type === 'create') {
        await addTrigger({
          blankId: blank_id,
          offerTypeId: form.getFieldValue(['offerTypes']),
          value: form.getFieldValue(['value']),
          quantity: form.getFieldValue(['quantity']),
        });
        closeAddEditModal();
      } else {
        const response = await editTrigger({
          id: currentId,
          value: form.getFieldValue(['value']),
          quantity: form.getFieldValue(['quantity']),
        });
        if ('error' in response) {
          if (response.error) {
            if ('data' in response.error) {
              if (response.error.data) {
                if ('message' in response.error.data) {
                  if (response.error.data.message) {
                    setModalData({
                      open: true,
                      message: response.error.data.message,
                      messageHeader: response.error.data.error,
                    });
                  }
                }
              }
            }
          }
        }
        closeAddEditModal();
      }
    } catch (error) {
      //handle error
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const data = { ...blankOfferState.data, [name]: value };
    setBlankOfferState({ ...blankOfferState, data: data });
  };

  const cancelResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.blanksSubHeader}>
              <p className={styles.users}>Blanks</p>
              <p className={styles.details}>/ Offers</p>
            </div>
          </Col>
        </Row>
        <Col className={styles.title}>
          <p>Blank Offers</p>
        </Col>
        <div className={styles.separator}></div>
        <Col>
          <p className={styles.line}></p>
        </Col>
        <div className={styles.separator}></div>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={Array.isArray(blanksOffers) ? blanksOffers : []}
          scroll={{ x: 500 }}
        />
        <FloatButton
          shape="circle"
          className={'floatingButton'}
          icon={<PlusOutlined className={'plusIcon'} />}
          onClick={onCreateClick}
        />
      </Row>
      <DeleteModal
        openModal={openDeleteModal}
        cancel={closeDeleteModal}
        onDelete={onClickDelete}
        loading={isDeleteLoading}
      />

      <EditBlankOfferModal
        open={blankOfferState.openAddEditModal}
        cancel={closeAddEditModal}
        form={form}
        action={onEditClick}
        onChange={handleInputChange}
        type={blankOfferState.type}
        loading={isAddLoading || isEditLoading}
        offerTypes={offerTypes}
        onScroll={handlePopupScroll}
      />
      <ResponseModal cancel={cancelResponseModal} {...modalData} />
    </Row>
  );
};

export default BlankOffers;
