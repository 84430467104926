import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';

import styles from './AddEditOfferTypeModal.modal.module.css';
import { EditOfferTypeModalTypes } from './AddEditOfferTypeModal.modal.types';
const EditOfferTypeModal = ({ open, cancel, form, action, onChange, type, loading }: EditOfferTypeModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> {type === 'create' ? 'Add' : 'Edit'} Offer Type </h1>
          </div>
          <Form
            form={form}
            layout={'vertical'}
            autoComplete="off"
            name="basic"
            onFinish={type === 'create' ? () => action('add') : () => action('edit')}
          >
            <Form.Item
              label="name"
              name="name"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input
                className={styles.input}
                name="name"
                type={'text'}
                min={0}
                placeholder="Enter name"
                onChange={onChange}
              />
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Spin className={styles.spin} /> : type === 'create' ? 'Add' : 'Edit'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditOfferTypeModal;
