import closeIcon from '../../../assets/icon _close circle_.svg';
import editIcon from '../../../assets/icon _edit_.svg';
import { TrainingRequirementsModel } from '../../../redux/trainings/trainingRequirements/training.requirements.model';
import styles from '../../users/models/TrainingModels.module.css';

const columns = (onActionClick: (_data: TrainingRequirementsModel, _type: string) => void) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <a>{text}</a>;
        },
      },
      {
        title: 'Width',
        dataIndex: 'width',
        key: 'width',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Height',
        dataIndex: 'height',
        key: 'height',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Seed',
        dataIndex: 'seed',
        key: 'seed',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Samples',
        dataIndex: 'samples',
        key: 'samples',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Scheduler',
        dataIndex: 'scheduler',
        key: 'scheduler',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Guidance scale',
        key: 'guidance_scale',
        dataIndex: 'guidance_scale',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Enhance prompt',
        key: 'enhance_prompt',
        dataIndex: 'enhance_prompt',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Negative prompt',
        key: 'negative_prompt',
        dataIndex: 'negative_prompt',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Training prompt id',
        key: 'training_prompt_id',
        dataIndex: 'training_prompt_id',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Num inference steps',
        key: 'num_inference_steps',
        dataIndex: 'num_inference_steps',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: '',
        key: 'edit',
        render: (data: TrainingRequirementsModel) => {
          return (
            <img
              alt={'Edit'}
              style={{ cursor: 'pointer' }}
              src={editIcon}
              className={styles.icon}
              onClick={() => {
                onActionClick(data, 'edit');
              }}
            />
          );
        },
      },
      {
        title: '',
        key: 'close',
        render: (data: TrainingRequirementsModel) => {
          return (
            <img
              alt={'Delete'}
              src={closeIcon}
              className={styles.icon}
              onClick={() => {
                onActionClick(data, 'delete');
              }}
            />
          );
        },
      },
    ];
  }
};
export default columns;
