import { Button, Col, Row, Table } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import useBackNavigation from '../../hooks/useBackNavigation';
import { Blanks as BlanksType } from '../../redux/blanks/blanks.model';
import { useDeleteBlankVariantMockupMutation, useGetBlankVariantMockupsQuery } from '../../redux/mockup/mockup.action';
import { setCurrentPage } from '../../redux/navigations/navigations.reducer';
import { RootState } from '../../redux/navigations/navigations.type';
import styles from './BlankVariantMockups.module.css';
import columns from './BlankVariantMockups.table';

const BlankVariantMockups = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalPage = useSelector((state: RootState) => state.pagination.pages.blankVariantMockupsCurrentPage);
  const blank_variant_id = Number(id);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteBlankVariantMockup] = useDeleteBlankVariantMockupMutation();
  const [deleteId, setDeleteId] = useState(-1);
  useBackNavigation('blankVariantMockupsCurrentPage');
  const { data: blankVariantMockups, isLoading } = useGetBlankVariantMockupsQuery({
    blank_variant_id,
    page: globalPage,
  });

  const onPaginate = (page: number) => {
    dispatch(setCurrentPage({ pageNumber: page, pageName: 'blankVariantMockupsCurrentPage' }));
  };

  const naviagteToCreateMockups = () => {
    navigate(`/main/sharp/${blank_variant_id}`, { state: { add: true } });
    dispatch(
      setCurrentPage({
        previousPageUrl: `/main/blank-variant-mockups/${blank_variant_id}`,
        previousPageName: 'previousPageOfMockupsFromBVM',
      }),
    );
  };

  const onActionClick = (blankVariantMockup: BlanksType | undefined, type: string | undefined) => {
    if (type === 'mockup' && blankVariantMockup && blankVariantMockup.id) {
      navigate(`/main/sharp/${id}/${blankVariantMockup?.id}`);
      dispatch(
        setCurrentPage({
          previousPageUrl: `/main/blank-variant-mockups/${blank_variant_id}`,
          previousPageName: 'previousPageOfMockupsFromBVM',
        }),
      );
    }
    if (type === 'delete' && blankVariantMockup && blankVariantMockup.id) {
      setOpenDeleteModal(true);
      setDeleteId(blankVariantMockup.id);
    }
  };

  const onClickDelete = () => {
    deleteBlankVariantMockup(deleteId);
    setOpenDeleteModal(false);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <Row className={styles.mainContainer}>
        <Row className={styles.container}>
          <Row className={styles.header}>
            <Col>
              <div className={styles.subHeader}>
                <p className={styles.users}>Blanks</p>
                <p className={styles.details}>/ Blank Variant </p>
                <p className={styles.details}>/ Blank Variant Mockups</p>
              </div>
            </Col>
          </Row>
          <Row className={styles.detailsTitle}>
            <Col className={styles.title}>
              <p>Blank Variant Mockups</p>
              <div className={styles.createMockupsContainer}>
                <Button className={styles.createMockupsBtn} onClick={naviagteToCreateMockups}>
                  Create Mockup
                </Button>
              </div>
            </Col>
            <Col>
              <p className={styles.line}></p>
            </Col>
            <div className={styles.margins}></div>
          </Row>
          <Row className={styles.tableContainer}>
            <SmallLoading isLoading={isLoading} />
            <Table
              rowKey="id"
              columns={columns(onActionClick)}
              className={styles.table}
              dataSource={blankVariantMockups?.items}
              scroll={{ x: 500 }}
              pagination={{
                current: globalPage,
                pageSize: blankVariantMockups?.meta?.itemsPerPage ?? 0,
                total: blankVariantMockups?.meta?.totalItems ?? 0,
                onChange: onPaginate,
              }}
            />
          </Row>
        </Row>
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
      </Row>
    </>
  );
};
export default BlankVariantMockups;
