import { createApi } from '@reduxjs/toolkit/query/react';
import FormData from 'form-data';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { BlankInformation, BlankInformationModel } from './blankInformation.model';

export interface ImageApiObject {
  formData: FormData;
  blankInfoId: number;
}

export const blankInformationApi = createApi({
  reducerPath: 'blankInformation',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['blankInformation'],
  endpoints: (builder) => ({
    getBlankInformation: builder.query<BlankInformationModel, { blank_id: number; page: number }>({
      query: (data: { blank_id: number; page: number }) => ({
        url: `/blank-information/${data.blank_id}?limit=7&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['blankInformation'],
    }),
    deleteBlankInformation: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/blank-information/${id}`, method: 'delete' };
      },
      invalidatesTags: ['blankInformation'],
    }),
    addBlankInformation: builder.mutation<BlankInformation, BlankInformation>({
      query: (data: BlankInformation) => {
        return { url: `/blank-information`, method: 'POST', data: data };
      },
      invalidatesTags: ['blankInformation'],
    }),
    uploadBlankInformationImage: builder.mutation<string, ImageApiObject>({
      query: (data: { formData: FormData; blankInfoId: number }) => ({
        headers: {
          'content-type': 'multipart/form-data',
          accept: '/',
        },
        url: `/blank-information/upload/image?blankInformationId=${data.blankInfoId}`,
        method: 'POST',
        data: data.formData,
      }),
      invalidatesTags: ['blankInformation'],
    }),
    editBlankInformation: builder.mutation<BlankInformation, Partial<BlankInformation>>({
      query: (data: BlankInformation) => {
        return { url: `/blank-information/${data.id}`, method: 'PATCH', data: data };
      },
      invalidatesTags: ['blankInformation'],
    }),
    updateBlankInformationImage: builder.mutation<string, ImageApiObject>({
      query: (data: { formData: FormData; blankInfoId: number }) => ({
        headers: {
          'content-type': 'multipart/form-data',
          accept: '/',
        },
        url: `/blank-information/update/image?blankInformationId=${data.blankInfoId}`,
        method: 'Patch',
        data: data.formData,
      }),
      invalidatesTags: ['blankInformation'],
    }),
  }),
});

export const {
  useGetBlankInformationQuery,
  useUploadBlankInformationImageMutation,
  useAddBlankInformationMutation,
  useDeleteBlankInformationMutation,
  useEditBlankInformationMutation,
  useUpdateBlankInformationImageMutation,
} = blankInformationApi;
