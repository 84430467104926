import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const i18nConfig = {
  lng: 'en',
  resources: {
    en: {
      translations: require('../locales/en.json'),
    },
    ar: {
      translations: require('../locales/ar.json'),
    },
  },
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);
export const i18nInstance = i18n;
export { I18nextProvider } from 'react-i18next';
