import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../../component/modals/delete/Delete.modal';
import TrainingRulesAddModal from '../../../component/modals/trainingRules/TrainingRulesAddModal';
import {
  useCreateTrainingRulesMutation,
  useDeleteTrainingRulesMutation,
  useGetTrainingRulesByTrainingIdQuery,
  useUpdateTrainingRulesMutation,
} from '../../../redux/trainings/trainingRules/training.rules.action';
import { TrainingRulesModel } from '../../../redux/trainings/trainingRules/training.rules.model';
import styles from '../../users/models/TrainingModels.module.css';
import columns from '../trainingRules/Training.rules.table';

const TrainingRules = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const trainingId = parseInt(id as string);
  const [page, setPage] = useState(1);

  const { data, isLoading } = useGetTrainingRulesByTrainingIdQuery({ id: trainingId, page });

  const [createTrainingRules] = useCreateTrainingRulesMutation();
  const [deleteTrigger] = useDeleteTrainingRulesMutation();
  const [updateTrainingRules] = useUpdateTrainingRulesMutation();
  const initialState = {
    training_id: trainingId,
    learning_rate_unet: '',
    learning_rate_text_encoder: '',
    seed: '',
    steps_text_encoder: '',
    steps_unet: '',
    base_model_id: '',
  };
  const [trainingRulesId, setTrainingRulesId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [trainingRulesState, setTrainingRulesState] = useState({
    data: initialState,
    type: 'create',
    openModal: false,
    inputValues: initialState,
  });

  useEffect(() => {
    if (trainingRulesState.openModal) {
      form.setFieldsValue(
        trainingRulesState.type === 'create' ? trainingRulesState.inputValues : trainingRulesState.data,
      );
    }
  }, [trainingRulesState.openModal]);
  const onPaginate = (page: number) => {
    setPage(page);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeEditModal = () => {
    setTrainingRulesState({ ...trainingRulesState, openModal: false, inputValues: initialState });
  };

  const onClickDelete = () => {
    deleteTrigger(trainingRulesId);
    setOpenDeleteModal(false);
  };

  const onActionClick = (data: TrainingRulesModel, type: string) => {
    if (type === 'delete') {
      if (data.id) {
        setTrainingRulesId(data.id);
      }
      setOpenDeleteModal(true);
    } else if (type === 'edit') {
      setTrainingRulesState({ ...trainingRulesState, data, type, openModal: true });
    }
  };

  const updateTrainingRulesState = (trainingInputs: TrainingRulesModel, trainingData: TrainingRulesModel) => {
    if (trainingRulesState.type === 'create') {
      setTrainingRulesState({ ...trainingRulesState, inputValues: trainingInputs });
    } else if (trainingRulesState.type === 'edit') {
      setTrainingRulesState({ ...trainingRulesState, data: trainingData });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const trainingData = { ...trainingRulesState.data, [name]: value };
    const trainingInputs = { ...trainingRulesState.inputValues, [name]: value };

    updateTrainingRulesState(trainingInputs, trainingData);
  };

  const onCreateClick = () => {
    setTrainingRulesState({ ...trainingRulesState, type: 'create', openModal: true });
  };

  const onSubmit = () => {
    const { type } = trainingRulesState;

    if (type === 'create') {
      createTrainingRules(trainingRulesState.inputValues);
      setTrainingRulesState({ ...trainingRulesState, inputValues: initialState, openModal: false });
    } else if (type === 'edit') {
      updateTrainingRules(trainingRulesState.data);
      setTrainingRulesState({ ...trainingRulesState, openModal: false });
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.trainingSubHeader}>
              <p className={styles.mainTitle}> Tools</p>
              <p className={styles.trainingSection}> / Training</p>
              <p className={styles.mainTitle}>/ Training Text</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>Tools - </p> <span className={styles.subTitle}>Training</span>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <Col>
            <p className={styles.closeModelText}>Close</p>
          </Col>
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={data?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: data?.meta?.itemsPerPage ?? 0,
            total: data?.meta?.itemsPerPage ?? 0,
            onChange: onPaginate,
          }}
        />
        {data?.items?.length === 0 ? (
          <FloatButton
            shape="circle"
            className={'floatingButton'}
            icon={<PlusOutlined className={'plusIcon'} />}
            onClick={onCreateClick}
          />
        ) : null}
        <TrainingRulesAddModal
          form={form}
          open={trainingRulesState.openModal}
          cancel={closeEditModal}
          onChangeInput={handleInputChange}
          onSubmit={onSubmit}
        ></TrainingRulesAddModal>
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
      </Row>
    </Row>
  );
};

export default TrainingRules;
