import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../../utils/axiosBaseQuery';
import { DataModel } from './data.model';
import { TrainingModel, TrainingStatuses } from './training.model';

interface argsType {
  id?: number | string | undefined;
  name?: string | undefined;
  key?: string | undefined;
  value?: string | undefined;
  active?: number | string | undefined;
  page: number;
}

export const trainingApi = createApi({
  reducerPath: 'training',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['Training'],
  endpoints: (builder) => ({
    getTraining: builder.query<DataModel, argsType>({
      query: (object) => {
        const { id, name, key, value, active, page } = object;
        let url = `/training?limit=10&page=${page}`;

        if (id) {
          url += `&id=${id}`;
        }
        if (name) {
          url += `&name=${name}`;
        }
        if (key) {
          url += `&key=${key}`;
        }
        if (value) {
          url += `&value=${value}`;
        }
        if (active) {
          url += `&active=${active}`;
        }

        return { url, method: 'get' };
      },
      providesTags: ['Training'],
    }),

    getTrainingStatuses: builder.query<Array<TrainingStatuses>, string>({
      query: () => ({ url: `/models/statistics/model`, method: 'get' }),
      providesTags: ['Training'],
    }),

    deleteTraining: builder.mutation<string, number>({
      query: (id: number) => ({ url: `/training/${id}`, method: 'delete' }),
      invalidatesTags: ['Training'],
    }),
    createTraining: builder.mutation<TrainingModel, Partial<TrainingModel>>({
      query: (data: TrainingModel) => ({ url: `/training`, method: 'POST', data: data }),
      invalidatesTags: ['Training'],
    }),
    updateTraining: builder.mutation<TrainingModel, Partial<TrainingModel>>({
      query: (data: TrainingModel) => ({ url: `/training/${data.id}`, method: 'PATCH', data: data }),
      invalidatesTags: ['Training'],
    }),
  }),
});

export const {
  useGetTrainingStatusesQuery,
  useGetTrainingQuery,
  useDeleteTrainingMutation,
  useCreateTrainingMutation,
  useUpdateTrainingMutation,
} = trainingApi;
