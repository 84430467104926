import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth/auth.reducer';
import { blankInformationApi } from './blankInformation/blankInformation.actions';
import { blanksOffersApi } from './blankOffers/blankOffers.action';
import { blankPrintLocationImageRulesApi } from './blankPrintLocationImageRules/blankPrintLocationImageRules.action';
import { blankPrintLocationsApi } from './blankPrintLocations/blankPrintLocations.action';
import { blanksPropertiesApi } from './blankProperties/blankProperties.action';
import { blanksApi } from './blanks/blanks.actions';
import { blankVariantsApi } from './blankVariants/blankVariants.actions';
import { userModelsApi } from './details/user/user.actions';
import { exampleApi } from './example/example.actions';
import counterReducer from './example/example.reducer';
import { FaqApi } from './faq/faq.actions';
import { gimpApi } from './gimp/gimp.action';
import { imagesApi } from './images/images.actions';
import { labApi } from './lab/lab.actions';
import { loginApi } from './login/login.actions';
import userReducer from './login/login.reducer';
import { previewApi } from './mockup/mockup.action';
import { mockupProviderApi } from './mockupProvider/mockupProvider.action';
import { modelsApi } from './models/models.actions';
import tablesPaginationReducer from './navigations/navigations.reducer';
import { offerTypesApi } from './offerTypes/offerTypes.action';
import { ordersApi } from './orders/orders.actions';
import { trainingApi } from './trainings/training/training.actions';
import { trainingCategoryApi } from './trainings/trainingCategory/training.category.actions';
import { trainingImageRulesApi } from './trainings/trainingImageRules/training.image.rules.action';
import { trainingRaffleApi } from './trainings/trainingRaffle/training.raffle.actions';
import { trainingRequirementsApi } from './trainings/trainingRequirements/training.requirements.action';
import { trainingRulesApi } from './trainings/trainingRules/training.rules.action';
import { trainingTextApi } from './trainings/trainingText/trainingText.actions';
import { usersApi } from './users/users.actions';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [
    trainingApi.reducerPath,
    trainingTextApi.reducerPath,
    trainingRulesApi.reducerPath,
    imagesApi.reducerPath,
    trainingCategoryApi.reducerPath,
    trainingRaffleApi.reducerPath,
    trainingImageRulesApi.reducerPath,
    userModelsApi.reducerPath,
    loginApi.reducerPath,
    trainingRequirementsApi.reducerPath,
    usersApi.reducerPath,
    previewApi.reducerPath,
    blanksApi.reducerPath,
    blankInformationApi.reducerPath,
    blankVariantsApi.reducerPath,
    usersApi.reducerPath,
    previewApi.reducerPath,
    blanksPropertiesApi.reducerPath,
    FaqApi.reducerPath,
    ordersApi.reducerPath,
    blanksOffersApi.reducerPath,
    offerTypesApi.reducerPath,
    blankPrintLocationsApi.reducerPath,
    blankPrintLocationImageRulesApi.reducerPath,
    mockupProviderApi.reducerPath,
    gimpApi.reducerPath,
    modelsApi.reducerPath,
    labApi.reducerPath,
  ],
};

export const rootReducers = combineReducers({
  counter: counterReducer,
  authentications: userReducer,
  authenticate: authReducer,
  pagination: tablesPaginationReducer,
  [exampleApi.reducerPath]: exampleApi.reducer,
  [trainingApi.reducerPath]: trainingApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [trainingTextApi.reducerPath]: trainingTextApi.reducer,
  [userModelsApi.reducerPath]: userModelsApi.reducer,
  [trainingRulesApi.reducerPath]: trainingRulesApi.reducer,
  [trainingRequirementsApi.reducerPath]: trainingRequirementsApi.reducer,
  [trainingImageRulesApi.reducerPath]: trainingImageRulesApi.reducer,
  [imagesApi.reducerPath]: imagesApi.reducer,
  [trainingCategoryApi.reducerPath]: trainingCategoryApi.reducer,
  [trainingRaffleApi.reducerPath]: trainingRaffleApi.reducer,
  [previewApi.reducerPath]: previewApi.reducer,
  [blanksApi.reducerPath]: blanksApi.reducer,
  [blankInformationApi.reducerPath]: blankInformationApi.reducer,
  [blankVariantsApi.reducerPath]: blankVariantsApi.reducer,
  [FaqApi.reducerPath]: FaqApi.reducer,
  [previewApi.reducerPath]: previewApi.reducer,
  [blanksPropertiesApi.reducerPath]: blanksPropertiesApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [blanksOffersApi.reducerPath]: blanksOffersApi.reducer,
  [offerTypesApi.reducerPath]: offerTypesApi.reducer,
  [blankPrintLocationsApi.reducerPath]: blankPrintLocationsApi.reducer,
  [blankPrintLocationImageRulesApi.reducerPath]: blankPrintLocationImageRulesApi.reducer,
  [mockupProviderApi.reducerPath]: mockupProviderApi.reducer,
  [gimpApi.reducerPath]: gimpApi.reducer,
  [modelsApi.reducerPath]: modelsApi.reducer,
  [labApi.reducerPath]: labApi.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(loginApi.middleware)
      .concat(usersApi.middleware)
      .concat(trainingTextApi.middleware)
      .concat(trainingApi.middleware)
      .concat(userModelsApi.middleware)
      .concat(trainingRulesApi.middleware)
      .concat(trainingRequirementsApi.middleware)
      .concat(trainingImageRulesApi.middleware)
      .concat(imagesApi.middleware)
      .concat(trainingCategoryApi.middleware)
      .concat(trainingRaffleApi.middleware)
      .concat(previewApi.middleware)
      .concat(blanksApi.middleware)
      .concat(blankVariantsApi.middleware)
      .concat(blankInformationApi.middleware)
      .concat(previewApi.middleware)
      .concat(blanksPropertiesApi.middleware)
      .concat(previewApi.middleware)
      .concat(FaqApi.middleware)
      .concat(previewApi.middleware)
      .concat(ordersApi.middleware)
      .concat(blanksOffersApi.middleware)
      .concat(blankPrintLocationsApi.middleware)
      .concat(blankPrintLocationImageRulesApi.middleware)
      .concat(mockupProviderApi.middleware)
      .concat(offerTypesApi.middleware)
      .concat(gimpApi.middleware)
      .concat(modelsApi.middleware)
      .concat(labApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
