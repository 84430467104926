import '../../containers/diffusion/Antd.css';

import { Button, Checkbox, Col, Image, Modal, Pagination, Row } from 'antd';
import React, { useState } from 'react';
import { LongPressCallbackMeta, LongPressEvent, useLongPress } from 'use-long-press';

import close from '../../assets/closeIcon.svg';
import trash from '../../assets/trashIcon.svg';
import styles from '../../containers/diffusion/Diffusion.module.css';
import Empty from '../empty/Empty';
import Loading from '../loadings/loading/Loading';
import { ImagesTypes } from './Images.type';
const Images = ({
  onActionClick,
  data,
  isLoading,
  onPaginate,
  page,
  type,
  onChangeHandler,
  onImageClickHandler,
  closeSaveContainer,
  selectedItem,
}: ImagesTypes) => {
  const [visible, setVisible] = useState(false);
  const [imagePath, setImagePath] = useState<string | any>('');

  const longPressEvent = useLongPress(
    (event: LongPressEvent<Element>, path: LongPressCallbackMeta<string | unknown>) => {
      setImagePath(path);
      setVisible(true);
    },
  );

  const handleCancel = () => {
    setVisible(false);
  };

  if (isLoading) {
    return (
      <div className={styles.mainContainer}>
        <Loading></Loading>
      </div>
    );
  }

  if (!data?.items?.length ?? 0 > 0) {
    return (
      <div className={styles.mainContainer}>
        <Empty></Empty>
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <div className={`${selectedItem?.length > 0 ? styles.saveContainer : styles.closeSaveContainer}`}>
        <div className={styles.contentContainer}>
          <Image
            src={close}
            preview={false}
            className={styles.closeIcon}
            onClick={() => {
              closeSaveContainer();
            }}
          />
          <p className={styles.selectedText}>{`${selectedItem?.length} selected`} </p>
        </div>
        {type === 'gallery' ? (
          <Image
            src={trash}
            preview={false}
            className={styles.trashIcon}
            onClick={() => {
              onActionClick();
            }}
          ></Image>
        ) : (
          <Button
            className={styles.saveButton}
            onClick={() => {
              onActionClick();
            }}
          >
            Save to Gallery
          </Button>
        )}
      </div>
      <div className={styles.subContainer}>
        <div className={styles.imagesContainer}>
          <Row gutter={[16, 30]}>
            {data?.items?.map(
              (item: { id: number; path: string; thumb: string | undefined }, i: React.Key | null | undefined) => (
                <Col span={4} key={i}>
                  <Checkbox
                    checked={selectedItem?.includes(item?.id)}
                    className={'customCheckbox'}
                    onChange={(e) => {
                      onChangeHandler(e, item?.id ?? 0);
                    }}
                  ></Checkbox>
                  <Image
                    src={type === 'gallery' ? item.thumb : item?.path}
                    className={`${styles.image} ${selectedItem?.includes(item?.id ?? 0) ? styles.selected : ''}`}
                    preview={false}
                    onClick={() => onImageClickHandler(item?.id ?? 0)}
                    {...longPressEvent(item.path ?? '')}
                  ></Image>
                </Col>
              ),
            )}
          </Row>
          <Pagination
            onChange={onPaginate}
            current={page}
            pageSize={data?.meta?.itemsPerPage ?? 0}
            total={data?.meta?.totalItems ?? 0}
            className={styles.paginationContainer}
          />
          <Modal
            centered
            open={visible}
            onCancel={handleCancel}
            footer={null}
            closable={false}
            className={'previewModal'}
          >
            <Image src={imagePath.context} preview={false} className={styles.previewImage} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Images;
