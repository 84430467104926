import '../training/AddModal.css';

import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';

import { TrainingImageRulesModalTypes } from './Training.image.rules.modal.types';
const { Option } = Select;

const TrainingImageRulesAddModal = ({
  inputValues,
  onSubmit,
  open,
  cancel,
  types,
  resizeMethods,
  form,
}: TrainingImageRulesModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} footer={null} className="training-modal">
      <Row>
        <Col span={24} className={'textContainer'}>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={onSubmit}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter name!' }]}>
              <Input value={inputValues.name} className="input" name="name" placeholder="Enter name" />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please enter description!' }]}
            >
              <Input className="input" name="description" placeholder="Enter description" />
            </Form.Item>

            <Row className={'row'} style={{ justifyContent: 'space-around' }}>
              <div>
                <Col>
                  <Form.Item
                    label="Image types"
                    name={'image_type_id'}
                    rules={[{ required: true, message: 'Please enter image type!' }]}
                  >
                    <Select labelInValue size={'middle'} placeholder={'Select image type'}>
                      {types?.map((type) => (
                        <Option id={type.id} key={type.name} value={type.id}>
                          {type.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </div>

              <div>
                <Col>
                  <Form.Item
                    label="Image resize method"
                    name={'image_resize'}
                    rules={[{ required: true, message: 'Please enter image resize method!' }]}
                  >
                    <Select labelInValue size={'middle'} placeholder={'Select image resize method'}>
                      {resizeMethods?.map((resize) => (
                        <Option key={resize.name} value={resize.id} id={resize.id}>
                          {resize.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </div>
            </Row>

            <Form.Item className={'enterContainer'}>
              <Button type={'primary'} htmlType="submit" className={'submitButton'}>
                Enter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default TrainingImageRulesAddModal;
