import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { OfferTypes } from '../offerTypes/offerTypes.model';
import {
  CreateLabPayload,
  CreateLabResult,
  ExperimentModel,
  GetLab,
  GetLabOutputImages,
  LabModel,
  LabTypeModel,
  PromptCategoryModel,
  PromptModel,
  ProviderModel,
} from './lab.model';

export const labApi = createApi({
  reducerPath: 'lab',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['lab'],
  endpoints: (builder) => ({
    createExperiment: builder.mutation<CreateLabResult, CreateLabPayload>({
      query: (data) => ({
        url: `/experiments`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['lab'],
    }),

    getPrompt: builder.query<
      PromptModel,
      { trainingType?: number; promptPage: number; trainingPromptCategoryId?: number }
    >({
      query: (object) => {
        const { trainingType, promptPage, trainingPromptCategoryId } = object;
        let url = `/training-prompt?limit=10&page=${promptPage}`;
        if (trainingType && trainingType !== 0) {
          url = `/training-prompt?type=${trainingType}&limit=10&page=${promptPage}`;
        }
        if (trainingPromptCategoryId && trainingPromptCategoryId !== 0) {
          url = `/training-prompt?type=${trainingType}&trainingPromptCategoryId=${trainingPromptCategoryId}&limit=10&page=${promptPage}`;
        }
        return { url, method: 'get' };
      },
      providesTags: ['lab'],
    }),

    getPromptCategory: builder.query<PromptCategoryModel, { trainingId: number; page: number }>({
      query: (data: { trainingId: number; page: number }) => ({
        url: `/training-prompt-categories?trainingId=${data.trainingId}&limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['lab'],
    }),

    getLab: builder.query<LabModel, { page: number }>({
      query: (object) => {
        const { page } = object;
        const url = `/labs?limit=10&page=${page}`;
        return { url, method: 'get' };
      },
      providesTags: ['lab'],
    }),

    getLabOutputImages: builder.query<GetLabOutputImages, number | null>({
      query: (id: number) => ({
        url: `/experiments/get/output/${id}`,
        method: 'get',
      }),
      providesTags: ['lab'],
    }),

    getLabType: builder.query<LabTypeModel, { page: number }>({
      query: (data: { page: number }) => ({
        url: `/labs/type?limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['lab'],
    }),

    deleteExperiment: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/experiments/${id}`, method: 'delete' };
      },
      invalidatesTags: ['lab'],
    }),

    deleteLab: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/labs/${id}`, method: 'delete' };
      },
      invalidatesTags: ['lab'],
    }),

    getAllExperiments: builder.query<ExperimentModel, { labId: number; page: number }>({
      query: (data: { labId: number; page: number }) => ({
        url: `/experiments/by/labId?labId=${data.labId}&limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['lab'],
    }),
    createLab: builder.mutation<GetLab, Partial<GetLab>>({
      query: (data: OfferTypes) => ({ url: `/labs`, method: 'POST', data: data }),
      invalidatesTags: ['lab'],
    }),
    getProviders: builder.query<ProviderModel, { page: number }>({
      query: (data: { page: number }) => ({
        url: `/model-trainer-providers?page=${data.page}&limit=10`,
        method: 'get',
      }),
      providesTags: ['lab'],
    }),
  }),
});
export const {
  useGetProvidersQuery,
  useCreateExperimentMutation,
  useGetPromptQuery,
  useGetLabTypeQuery,
  useGetLabQuery,
  useGetLabOutputImagesQuery,
  useGetPromptCategoryQuery,
  useDeleteExperimentMutation,
  useDeleteLabMutation,
  useCreateLabMutation,
  useGetAllExperimentsQuery,
} = labApi;
