import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Image, Input, Row, Select, Table } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import search from '../../../assets/searchIcon.png';
import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import AddAdminModal from '../../../component/modals/addAdminUserModal/AddAdminUserModal.modal';
import { setCurrentPage } from '../../../redux/navigations/navigations.reducer';
import { RootState } from '../../../redux/navigations/navigations.type';
import { useCreateAdminUserMutation, useGetUsersQuery } from '../../../redux/users/users.actions';
import styles from './Users.module.css';
import UsersColumns from './Users.table';

const Users = () => {
  const [email, setEmail] = useState('');
  const [selectedValue, setSelectedValue] = useState('user');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [valueOfEmail, setValueOfEmail] = useState('');
  const globalPage = useSelector((state: RootState) => state.pagination.pages.usersCurrentPage);
  const { data: userData, isLoading } = useGetUsersQuery({ email, page: globalPage, type: selectedValue });
  const [addTrigger, { isLoading: isAddLoading }] = useCreateAdminUserMutation();

  const [form] = Form.useForm();
  const [openAddModal, setOpenAddModal] = useState(false);

  const onPaginate = (page: number) => {
    dispatch(setCurrentPage({ pageName: 'usersCurrentPage', pageNumber: page }));
  };

  const onIconClick = (id: number, icon: string) => {
    navigate(`${icon}/${id}`);
  };

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage({ isUserSelected: true }));
  };

  const generateRandomPassword = (length: number) => {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?/';
    const numericChars = '0123456789';

    const allChars = lowercaseChars + uppercaseChars + specialChars + numericChars;

    let password = '';
    password +=
      getRandomChar(lowercaseChars) +
      getRandomChar(uppercaseChars) +
      getRandomChar(specialChars) +
      getRandomChar(numericChars);

    for (let i = 4; i < length; i++) {
      password += getRandomChar(allChars);
    }

    password = password
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');

    return password;
  };

  const getRandomChar = (charSet: string) => {
    const randomIndex = Math.floor(Math.random() * charSet.length);
    return charSet[randomIndex];
  };

  const onCreateClick = () => {
    const generatedPassword = generateRandomPassword(8);
    form.setFieldsValue({
      firstName: '',
      lastName: '',
      emailAddress: '',
      password: generatedPassword,
    });

    setOpenAddModal(true);
  };

  const onAddClick = async () => {
    try {
      await form.validateFields().then((values) => {
        const firstName: string = values.firstName;
        const lastName: string = values.lastName;
        const emailAddress: string = values.emailAddress;
        const password: string = values.password;

        addTrigger({
          email: emailAddress,
          password: password,
          first_name: firstName,
          last_name: lastName,
        });
      });

      closeAddAdminModal();
    } catch (error) {
      //handle error
    }
  };

  const closeAddAdminModal = () => {
    setOpenAddModal(false);
  };

  return (
    <div className={styles.mainContainer}>
      <Row>
        <Col span={24} className={styles.userNav}>
          <div className={styles.searchContainer}>
            <div className={styles.imageContainer}>
              <Image
                src={search}
                preview={false}
                rootClassName={styles.image}
                onClick={() => setEmail(valueOfEmail)}
              ></Image>
            </div>
          </div>
          <div className={styles.EmailInputContainer}>
            <Input
              size="small"
              placeholder="Email"
              rootClassName={styles.emailInput}
              onChange={(e) => {
                setValueOfEmail(e.target.value);
              }}
            ></Input>
          </div>
          <div className={styles.EmailInputContainer}>
            <Select
              defaultValue={'Admin'}
              className={styles.selectUser}
              value={selectedValue}
              onChange={handleSelectChange}
              options={[
                { value: 'admin', label: 'Admin' },
                { value: 'user', label: 'User' },
                { value: 'guest', label: 'Guest' },
              ]}
            />
          </div>

          <div className={styles.totalNumberContainer}>
            <p className={styles.totalNumber}>
              Total Number of Users: {userData?.meta.totalItems ? userData?.meta?.totalItems : 0}
            </p>
          </div>
        </Col>
      </Row>
      <Row className={styles.tableContainer}>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          dataSource={userData?.items}
          columns={UsersColumns(onIconClick)}
          rootClassName={styles.table}
          scroll={{ x: 500 }}
          pagination={{
            current: globalPage,
            pageSize: userData?.meta.itemsPerPage ?? 0,
            total: userData?.meta.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
        <FloatButton
          shape="circle"
          className={'floatingButton'}
          icon={<PlusOutlined className={'plusIcon'} />}
          onClick={onCreateClick}
        />
      </Row>
      <AddAdminModal
        open={openAddModal}
        cancel={closeAddAdminModal}
        form={form}
        action={onAddClick}
        loading={isAddLoading}
      />
    </div>
  );
};

export default Users;
