import './Checkbox.css';

import { Checkbox } from 'antd';
import React from 'react';
const CheckBox = () => {
  return (
    <div>
      <Checkbox>Remember me</Checkbox>
    </div>
  );
};

export default CheckBox;
