import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { BlanksOffers, BlanksOffersModel } from './blankOffers.model';

export const blanksOffersApi = createApi({
  reducerPath: 'blankOffers',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['blanksOffers'],
  endpoints: (builder) => ({
    getBlankOffers: builder.query<BlanksOffers, { blank_id: number }>({
      query: (data: { blank_id: number }) => ({
        url: `/blank-offers/blank/${data.blank_id}`,
        method: 'get',
      }),
      providesTags: ['blanksOffers'],
    }),
    createBlankOffers: builder.mutation<BlanksOffers, Partial<BlanksOffers>>({
      query: (data: BlanksOffers) => ({ url: `/blank-offers`, method: 'POST', data: data }),
      invalidatesTags: ['blanksOffers'],
    }),

    getBlankOfferTypes: builder.query<BlanksOffersModel, { page: number }>({
      query: (data: { page: number }) => ({
        url: `/offer-types?limit=5&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['blanksOffers'],
    }),
    deleteBlankOffer: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/blank-offers/${id}`, method: 'delete' };
      },
      invalidatesTags: ['blanksOffers'],
    }),

    editBlanksOffer: builder.mutation<BlanksOffers, Partial<BlanksOffers>>({
      query: (data: BlanksOffers) => {
        return { url: `/blank-offers/${data.id}`, method: 'PATCH', data: data };
      },
      invalidatesTags: ['blanksOffers'],
    }),
  }),
});

export const {
  useGetBlankOffersQuery,
  useCreateBlankOffersMutation,
  useGetBlankOfferTypesQuery,
  useDeleteBlankOfferMutation,
  useEditBlanksOfferMutation,
} = blanksOffersApi;
