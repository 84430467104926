import '../training/AddModal.css';

import { Button, Col, Form, Input, Modal, Row } from 'antd';

import { TrainingCategoryModalTypes } from './Training.category.modal.types';

const TrainingCategoryAddModal = ({
  onChangeInput,
  inputValues,
  onSubmit,
  open,
  cancel,
  form,
}: TrainingCategoryModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} footer={null} className="training-modal">
      <Row>
        <Col span={24} className={'textContainer'}>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={onSubmit}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input
                value={inputValues?.name}
                className="input"
                name="name"
                onChange={onChangeInput}
                placeholder="Enter name"
              />
            </Form.Item>

            <Form.Item className={'enterContainer'}>
              <Button type={'primary'} htmlType="submit" className={'submitButton'}>
                Enter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default TrainingCategoryAddModal;
