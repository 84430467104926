import { Image } from 'antd';
import React from 'react';

import { ExperimentModelProperties } from '../../../redux/lab/lab.model';
import styles from '../CreateExperiment/CreateLab.module.css';
const experimentModelPropertiesColumns = () => {
  {
    return [
      {
        title: 'Key',
        key: 'key',
        render: (property: ExperimentModelProperties) => <p>{property.key}</p>,
      },
      {
        title: 'Value',
        key: 'value',
        render: (property: ExperimentModelProperties) => {
          return property.key === 'output' ? (
            <Image height={40} width={40} className={styles.imageValue} src={property.value} />
          ) : (
            <p>{property.value}</p>
          );
        },
      },
    ];
  }
};
export default experimentModelPropertiesColumns;
