import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../../utils/axiosBaseQuery';
import { TrainingCategoryModel } from './trainingCategory.model';
import { TrainingData } from './trainingData.model';
import { TrainingCount, TrainingModel } from './trainingText.model';

interface argsType {
  id: number;
  searchTextId?: number | string | undefined;
  text?: string | undefined;
  page: number;
}

export const trainingTextApi = createApi({
  reducerPath: 'trainingText',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['TrainingData', 'TrainingModel', 'TrainingCategoryModel', 'TrainingCount'],
  endpoints: (builder) => ({
    getAllTrainingPrompt: builder.query<TrainingData, number>({
      query: (page = 1) => ({
        headers: { 'content-type': 'application/json' },
        url: `/training-prompt/training/1?limit=10&page=${page}`,
        method: 'get',
      }),
      providesTags: ['TrainingData'],
    }),
    getTrainingPrompt: builder.query<TrainingData, argsType>({
      query: (object) => {
        const { id, searchTextId, text, page } = object;
        let url = `/training-prompt/training/${id}?limit=10&page=${page}`;

        if (searchTextId) {
          url = `/training-prompt/training/${id}?limit=10&page=1&textId=${searchTextId}`;
        }
        if (text) {
          url = `/training-prompt/training/${id}?limit=10&page=1&text=${text}`;
        }

        return { url, method: 'get' };
      },
      providesTags: ['TrainingData'],
    }),
    postTraining: builder.mutation<TrainingModel, Partial<TrainingModel>>({
      query: (user: TrainingModel) => ({
        url: `/training-prompt`,
        method: 'POST',
        data: user,
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['TrainingData'],
    }),
    deleteTraining: builder.mutation<TrainingData, number>({
      query: (id) => ({
        url: `/training-prompt/${id}`,
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['TrainingData'],
    }),
    updateTraining: builder.mutation<TrainingModel, Partial<TrainingModel>>({
      query: (data: TrainingModel) => ({
        url: `/training-prompt/${data.id}`,
        method: 'PATCH',
        data: data,
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['TrainingData'],
    }),
    getAllTrainingPromptCategory: builder.query<TrainingCategoryModel, number>({
      query: (page) => ({
        url: `/category?limit=10&page=${page}`,
        method: 'get',
      }),
      providesTags: ['TrainingCategoryModel'],
    }),
    getTrainingPromptCount: builder.query<Array<TrainingCount>, string>({
      query: () => ({
        url: `/training-prompt/by/category`,
        method: 'get',
      }),
      providesTags: ['TrainingCount'],
    }),
  }),
});

export const {
  useGetAllTrainingPromptQuery,
  useGetTrainingPromptQuery,
  useGetAllTrainingPromptCategoryQuery,
  usePostTrainingMutation,
  useDeleteTrainingMutation,
  useUpdateTrainingMutation,
  useGetTrainingPromptCountQuery,
} = trainingTextApi;
