import { Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';

import Images from '../../component/images/Images';
import { useGetDiffusionFilesQuery } from '../../redux/images/images.actions';
import { useCopyDiffusionFilesMutation } from '../../redux/images/images.actions';
import styles from './Diffusion.module.css';

const Diffusion = () => {
  const [page, setPage] = useState(1);
  const { data: diffusionFiles, isLoading } = useGetDiffusionFilesQuery({ page: page });
  const [CopyTrigger] = useCopyDiffusionFilesMutation();

  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number[]>([]);

  const onPaginate = (page: number) => {
    setPage(page);
  };

  const onSaveButton = () => {
    CopyTrigger(selectedItem);
    setSelectedItem([]);
    setOpenModal(true);
  };

  const onChangeHandler = (e: CheckboxChangeEvent, id: number) => {
    if (e.target.checked) {
      setSelectedItem([...selectedItem, id]); // add the item id to the selected items array
    } else {
      setSelectedItem(selectedItem.filter((itemId) => itemId !== id)); // remove the item id from the selected items array
    }
  };

  // Making a checkbox event if the image is clicked
  const onImageClickHandler = (id: number) => {
    const isChecked = selectedItem.includes(id);
    const e = { target: { checked: !isChecked } } as CheckboxChangeEvent;
    onChangeHandler(e, id);
  };

  const closeSaveContainer = () => {
    setSelectedItem([]);
  };
  return (
    <div className={styles.mainContainer}>
      <Images
        onActionClick={onSaveButton}
        data={diffusionFiles}
        isLoading={isLoading}
        onPaginate={onPaginate}
        page={page}
        type={'images'}
        onChangeHandler={onChangeHandler}
        onImageClickHandler={onImageClickHandler}
        closeSaveContainer={closeSaveContainer}
        selectedItem={selectedItem}
      ></Images>
      <Modal
        centered
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
        }}
        footer={false}
        className={styles.alertModal}
      >
        <p className={styles.alertText}>Selected images have been saved to gallery</p>
      </Modal>
    </div>
  );
};

export default Diffusion;
