import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';

import { EditFaqModalType } from './editFaq.modal.types';
import styles from './editFaq.module.css';
const EditFaqModal = ({ open, cancel, form, action, onChange, type, loading }: EditFaqModalType) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> {type === 'create' ? 'Add' : 'Edit'} Blank Information</h1>
          </div>
          <Form
            form={form}
            layout={'vertical'}
            autoComplete="off"
            name="basic"
            onFinish={type === 'create' ? () => action('add') : () => action('edit')}
          >
            <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please input the title' }]}>
              <Input
                className={styles.input}
                name="title"
                type={'text'}
                min={0}
                placeholder="Enter title"
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input the description' }]}
            >
              <Input
                className={styles.input}
                name="description"
                type={'text'}
                min={0}
                placeholder="Enter description"
                onChange={onChange}
              />
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Spin className={styles.spin} /> : type === 'create' ? 'Add' : 'Edit'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditFaqModal;
