import { Button, Form, Input, Modal, Select } from 'antd';

import { RefundOrderModalTypes } from './RefundOrder.modal.types';
import styles from './RefundOrder.module.css';

const RefundOrderModal = ({ open, cancel, action, form }: RefundOrderModalTypes) => {
  return (
    <Modal width={'450px'} centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <h1>Refund Order</h1>
      <br />
      <div className={styles.textContainer}>
        <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={action}>
          <Form.Item
            label="Refund Type"
            name="type"
            rules={[{ required: true, message: 'Please select the refund type' }]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                { value: 'partial', label: 'Partial' },
                { value: 'full', label: 'Full' },
              ]}
            />
          </Form.Item>
          <br />
          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('type') === 'partial' && !value) {
                    return Promise.reject('Please input the amount');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input className={styles.input} name="name" type={'number'} min={0} placeholder="Enter name" />
          </Form.Item>
          <br />
          <Form.Item>
            <Button className={styles.submitButton} type="primary" htmlType="submit">
              Refund Order
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default RefundOrderModal;
