import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Modal, Row, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import CreateLabModal from '../../../component/modals/createLabModal/CreateLabModal.modal';
import DeleteModal from '../../../component/modals/delete/Delete.modal';
import { useFilterAndSetData } from '../../../customHooks/usePaginateData';
import {
  useCreateLabMutation,
  useDeleteExperimentMutation,
  useDeleteLabMutation,
  useGetAllExperimentsQuery,
  useGetLabOutputImagesQuery,
  useGetLabQuery,
  useGetLabTypeQuery,
} from '../../../redux/lab/lab.actions';
import {
  Experiment,
  ExperimentModelProperties,
  ExperimentProperties,
  GetLab,
  LabType,
} from '../../../redux/lab/lab.model';
import { handleScroll } from '../../../utils/helpers';
import styles from '../../blanks/Blanks.module.css';
import styling from '../CreateExperiment/CreateLab.module.css';
import columns from './Experiment.table';
import experimentModelPropertiesColumns from './ExperimentModelProperties.table';
import experimentPropertiesColumns from './ExperimentProperties.table';
import labColumns from './Lab.table';
const Lab = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const modelsInitialState: {
    openResultModal: boolean;
    openDeleteModal: boolean;
    openExperimentPropertiesModal: boolean;
    openExperimentModelPropertiesModal: boolean;
  } = {
    openResultModal: false,
    openDeleteModal: false,
    openExperimentPropertiesModal: false,
    openExperimentModelPropertiesModal: false,
  };
  const labModalInitialState: {
    open: boolean;
    data: {
      labTypeId: number;
      name: string;
      description: string;
    };
  } = {
    open: false,
    data: {
      labTypeId: 0,
      name: '',
      description: '',
    },
  };
  const [page, setPage] = useState(1);
  const [experimentPage, setExperimentPage] = useState(1);
  const [labTypePage, setLabTypePage] = useState(1);
  const [labId, setLabId] = useState(-1);
  const [resultImagesId, setResultImagesId] = useState<number | null>(-1);
  const [experimentId, setExperimentId] = useState(-1);
  const [expandedLabs, setExpandedLabs] = useState<{ [key: number]: boolean }>({});
  const [modals, setModals] = useState({
    openResultModal: modelsInitialState.openResultModal,
    openDeleteModal: modelsInitialState.openDeleteModal,
    openExperimentPropertiesModal: modelsInitialState.openExperimentPropertiesModal,
    openExperimentModelPropertiesModal: modelsInitialState.openExperimentModelPropertiesModal,
  });
  const [labModal, setLabModal] = useState({
    open: labModalInitialState.open,
    data: {
      labTypeId: labModalInitialState.data.labTypeId,
      name: labModalInitialState.data.name,
      description: labModalInitialState.data.description,
    },
  });
  const [skipResult, setSkipResult] = useState(true);
  const [labExperiments, setLabExperiments] = useState<{ [key: number]: Experiment[] }>({});
  const [experimentProperties, setExperimentProperties] = useState<ExperimentProperties[]>([]);
  const [experimentModelProperties, setExperimentModelProperties] = useState<ExperimentModelProperties[]>([]);
  const { data: labType, isFetching: isLabTypeFetching } = useGetLabTypeQuery({ page: labTypePage });
  const { data: labs, isLoading: isLabsLoading } = useGetLabQuery({ page });
  const { data: images } = useGetLabOutputImagesQuery(resultImagesId, {
    skip: skipResult,
  });
  const [deleteExperimentTrigger, { isLoading: isDeleteExperimentLoading }] = useDeleteExperimentMutation();
  const [deleteLabTrigger, { isLoading: isDeleteLabLoading }] = useDeleteLabMutation();
  const [isLab, setIsLab] = useState(false);
  const [addTrigger, { isLoading: isAddLoading }] = useCreateLabMutation();
  const { data: experiments, isFetching: isExperimentsFetching } = useGetAllExperimentsQuery({
    labId: labId,
    page: experimentPage,
  });
  const loadedLabTypes = labType?.items || [];
  const [labTypes, setLabTypes] = useState(loadedLabTypes || []);

  useEffect(() => {
    if (!isExperimentsFetching && experiments) {
      setLabExperiments((prevExperiments) => ({
        ...prevExperiments,
        [labId]: experiments.items,
      }));
    }
  }, [experiments, labId, isExperimentsFetching]);

  const onPaginate = (page: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  };
  const onExperimentsPaginate = (page: number) => {
    setExperimentPage(page);
    window.scrollTo(0, 0);
  };

  useFilterAndSetData<LabType>(labType?.items || [], setLabTypes, labTypes, 'id');

  const handleLabTypesScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isLabTypeFetching) {
      handleScroll<LabType>(e, loadedLabTypes, setLabTypePage, labTypePage);
    }
  };

  const onLabActionClick = async (id?: number | undefined, type?: string | undefined) => {
    if (type === 'addExperiment') {
      navigate(`/main/lab/add-experiment/${id}`);
    } else if (type === 'deleteLab') {
      setLabId(id ?? -1);
      setModals((prevState) => ({
        ...prevState,
        openDeleteModal: true,
      }));
      setIsLab(true);
    }
  };

  const onActionClick = async (
    type: string | undefined,
    experiment?: Experiment | undefined,
    labModelProperties?: ExperimentModelProperties[] | undefined,
  ) => {
    if (type === 'viewOutputImages') {
      setSkipResult(false);
      setResultImagesId(experiment?.id ?? null);
      setModals((prevState) => ({
        ...prevState,
        openResultModal: true,
      }));
    } else if (type === 'deleteExperiment') {
      setExperimentId(experiment?.id ?? -1);
      setModals((prevState) => ({
        ...prevState,
        openDeleteModal: true,
      }));
      setLabId(experiment?.labId ?? -1);
      setIsLab(false);
    } else if (type === 'viewProperties') {
      setExperimentProperties(experiment?.experimentProperties ?? []);
      setModals((prevState) => ({
        ...prevState,
        openExperimentPropertiesModal: true,
      }));
    } else if (type === 'viewExperimentModelProperties') {
      if (Number(experiment?.status) === 100) {
        const ModelProperties = labModelProperties ?? [];
        setExperimentModelProperties(ModelProperties);
        setModals((prevState) => ({
          ...prevState,
          openExperimentModelPropertiesModal: true,
        }));
      }
    }
  };

  const onClickDelete = async () => {
    if (isLab) {
      await deleteLabTrigger(labId);
    } else {
      await deleteExperimentTrigger(experimentId);
    }

    setModals((prevState) => ({
      ...prevState,
      openDeleteModal: false,
    }));
  };

  const expandedRowRenderSubTable = (record: GetLab) => {
    const isExpanded = expandedLabs[record.id];
    if (isExpanded) {
      return (
        <>
          {isExperimentsFetching && record.id === labId ? (
            <Spin className={styling.loading} />
          ) : (
            <Table
              rowKey={(record: Experiment) => record.id.toString()}
              columns={columns(onActionClick)}
              className={styling.table}
              dataSource={labExperiments[record.id]}
              scroll={{ x: 500 }}
              pagination={{
                current: experimentPage,
                pageSize: experiments?.meta?.itemsPerPage ?? 0,
                total: experiments?.meta?.totalItems ?? 0,
                onChange: onExperimentsPaginate,
              }}
            />
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const handleRowExpand = (expanded: boolean, record: GetLab) => {
    // Disable expanding other rows if experiments are fetching
    if (isExperimentsFetching) {
      return;
    } else {
      setExpandedLabs((prevState) => ({
        ...prevState,
        [record.id]: expanded,
      }));
      setLabId(expanded ? record.id : -1);
    }
  };

  const onCreateClick = () => {
    form.setFieldsValue({
      name: '',
      description: '',
    });
    form.resetFields(['labTypeId']);
    setLabModal((prevState) => ({
      ...prevState,
      open: true,
    }));
  };
  const closeCreateLabModal = () => {
    setLabModal((prevState) => ({
      ...prevState,
      open: false,
    }));
  };
  const onEditClick = async () => {
    try {
      await addTrigger({
        labTypeId: form.getFieldValue(['labTypeId']),
        name: form.getFieldValue(['name']),
        description: form.getFieldValue(['description']),
      });
      closeCreateLabModal();
    } catch (error) {
      //handle error
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <p className={styles.title}> Lab</p>
        <Col>
          <p className={styles.line}></p>
        </Col>
        <div className={styles.separator}></div>

        <SmallLoading isLoading={isLabsLoading} />

        <Table
          rowKey={(record: GetLab) => record.id.toString()}
          columns={labColumns(onLabActionClick)}
          className={styles.table}
          dataSource={labs?.items.map((item) => ({ ...item, key: item.id }))}
          expandable={{
            expandedRowRender: expandedRowRenderSubTable,
            onExpand: handleRowExpand,
          }}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: labs?.meta?.itemsPerPage ?? 0,
            total: labs?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
        <FloatButton
          shape="circle"
          className={'floatingButton'}
          icon={<PlusOutlined className={'plusIcon'} />}
          onClick={onCreateClick}
        />

        <CreateLabModal
          open={labModal.open}
          cancel={() =>
            setLabModal((prevState) => ({
              ...prevState,
              open: false,
            }))
          }
          form={form}
          action={onEditClick}
          loading={isAddLoading}
          isLabTypeFetching={isLabTypeFetching}
          labType={labTypes}
          onScroll={handleLabTypesScroll}
        />

        <Modal
          title="Result"
          open={modals.openResultModal}
          onCancel={() => {
            setModals((prevState) => ({
              ...prevState,
              openResultModal: false,
            }));
            setSkipResult(true);
          }}
          footer={null}
        >
          <div className={styling.popupImageContainer}>
            {images?.experimentModelsMapping.map((experimentModel, experimentModelIndex) => (
              <div key={experimentModelIndex} className={styling.labModelPropertiesContainer}>
                {experimentModel.experimentModelProperties.map((property, propertyIndex) => (
                  <img
                    key={propertyIndex}
                    src={property.value}
                    alt={`Image ${propertyIndex + 1}`}
                    className={styling.popupImageItem}
                  />
                ))}
              </div>
            ))}
          </div>
        </Modal>

        <Modal
          title="Properties"
          open={modals.openExperimentPropertiesModal}
          onCancel={() =>
            setModals((prevState) => ({
              ...prevState,
              openExperimentPropertiesModal: false,
            }))
          }
          footer={null}
          width={800}
        >
          <Table
            rowKey={(experimentProperties) => `${experimentProperties.id}`}
            columns={experimentPropertiesColumns()}
            className={styling.table}
            dataSource={experimentProperties}
            pagination={false}
            size="small"
            bordered={true}
          />
        </Modal>

        <Modal
          title="Model Properties"
          open={modals.openExperimentModelPropertiesModal}
          onCancel={() =>
            setModals((prevState) => ({
              ...prevState,
              openExperimentModelPropertiesModal: false,
            }))
          }
          footer={null}
          width={800}
        >
          <Table
            rowKey={(index) => `${index.id}`}
            columns={experimentModelPropertiesColumns()}
            className={styling.table}
            dataSource={experimentModelProperties}
            pagination={false}
            size="small"
            bordered={true}
          />
        </Modal>

        <DeleteModal
          openModal={modals.openDeleteModal}
          cancel={() =>
            setModals((prevState) => ({
              ...prevState,
              openDeleteModal: false,
            }))
          }
          onDelete={onClickDelete}
          loading={isDeleteLabLoading || isDeleteExperimentLoading}
        />
      </Row>
    </Row>
  );
};

export default Lab;
