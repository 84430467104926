import './AddModal.css';

import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';

import { TrainingModalTypes } from './Training.modal.types';

const AddModal = ({ onChangeInput, onClickActive, inputValues, onSubmit, open, cancel, form }: TrainingModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} footer={null} className="training-modal">
      <Row>
        <Col span={24} className={'textContainer'}>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic" onFinish={onSubmit}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input
                value={inputValues?.name}
                className="input"
                name="name"
                onChange={onChangeInput}
                placeholder="Enter name"
              />
            </Form.Item>

            <Form.Item label="Key" name="key" rules={[{ required: true, message: 'Please input your key!' }]}>
              <Input className="input" name="key" onChange={onChangeInput} placeholder="Enter key" />
            </Form.Item>

            <Form.Item label="Value" name="value">
              <Input className="input" name="value" onChange={onChangeInput} placeholder="Enter value" />
            </Form.Item>

            <Form.Item name="active">
              <Checkbox className="activeBox" name="active" onChange={onClickActive} checked={inputValues?.active}>
                Active
              </Checkbox>
            </Form.Item>

            <Form.Item className={'enterContainer'}>
              <Button type={'primary'} htmlType="submit" className={'submitButton'}>
                Enter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddModal;
