import { Modal } from 'antd';

import styles from '../../../component/modals/delete/Delete.modal.module.css';
import { ResponseModalType } from './ResponseModal.type';

const ResponseModal = ({ open, cancel, message, messageHeader }: ResponseModalType) => {
  return (
    <Modal width={'450px'} centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <div className={styles.text}>
        <h1> {messageHeader}</h1>

        <h4>{message}</h4>
      </div>
    </Modal>
  );
};

export default ResponseModal;
