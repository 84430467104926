import { Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';

import styles from './BlankOffersModal.modal.module.css';
import { EditBlankOfferModalType } from './BlankOffersModal.modal.types';
const EditBlankOfferModal = ({
  open,
  cancel,
  form,
  action,
  onChange,
  type,
  loading,
  offerTypes,
  onScroll,
}: EditBlankOfferModalType) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24} className={styles.textContainer}>
          <div className={styles.textContainer}>
            <h1> {type === 'create' ? 'Add' : 'Edit'} Blank Offer </h1>
          </div>
          <Form
            form={form}
            layout={'vertical'}
            autoComplete="off"
            name="basic"
            onFinish={type === 'create' ? () => action('add') : () => action('edit')}
          >
            {type === 'create' && (
              <Form.Item
                label="Offer Types"
                name="offerTypes"
                rules={[{ required: true, message: <span className={styles['error-message']}>required</span> }]}
              >
                <Select listHeight={120} placeholder={'Select an offer type'} onPopupScroll={onScroll}>
                  {offerTypes.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="value"
              name="value"
              rules={[
                {
                  required: true,
                  message: <span className={styles['error-message']}>required</span>,
                },
              ]}
            >
              <Input
                className={styles.input}
                name="value"
                type={'text'}
                min={0}
                placeholder="Enter value"
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item
              label="quantity"
              name="quantity"
              rules={[{ required: true, message: <span className={styles['error-message']}>required</span> }]}
            >
              <Input
                className={styles.input}
                name="quantity"
                type={'text'}
                min={0}
                placeholder="Enter quantity"
                onChange={onChange}
              />
            </Form.Item>

            <Form.Item className={styles.addContainer}>
              <Button className={styles.submitButton} type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Spin className={styles.spin} /> : type === 'create' ? 'Add' : 'Edit'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditBlankOfferModal;
