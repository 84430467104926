import 'react-image-crop/dist/ReactCrop.css';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Upload, UploadFile } from 'antd';
import { InputNumber } from 'antd';
import { Table } from 'antd';
import FormData from 'form-data';
import React, { SetStateAction, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Crop, ReactCrop } from 'react-image-crop';
import { useParams } from 'react-router-dom';
import { Text } from 'recharts';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import ResponseModal from '../../component/modals/responseModal/ResponseModal';
import {
  useAddBlankVariantMockupStageMutation,
  useDeleteBlankVariantMockupStageMutation,
  useGetBlankVariantMockupByIdQuery,
  usePostBlankVariantMockupMutation,
  usePostMockupPreviewMutation,
  useUpdateBlankVariantMockupStageMutation,
} from '../../redux/mockup/mockup.action';
import styles from './Mockups.module.css';
import { BlankVariantStageLocations } from './Mockups.type';

const Mockups = () => {
  const { blankVariantId, mockupId } = useParams();
  const blank_variant_id = Number(blankVariantId);
  const blankVariantMockupId = Number(mockupId);
  const isEdit: boolean = mockupId !== undefined;
  const OriginalImageValue = {
    width: 0,
    height: 0,
    path: '',
    factor: 1,
  };

  const initialCropState: Crop = {
    unit: 'px',
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [activeDrags, setActiveDrags] = useState(0);
  const [image, setImage] = useState(OriginalImageValue);
  const [layerImage, setLayerImage] = useState(OriginalImageValue);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [crop, setCrop] = useState<Crop>(initialCropState);
  const [nonDividedCrop, setNonDividedCrop] = useState<Crop>({
    unit: 'px',
    x: crop.x,
    y: crop.y,
    width: crop.width,
    height: crop.height,
  });
  const [blankVariantStageLocations, setBlankVariantStageLocations] = useState<BlankVariantStageLocations[]>([]);
  const [modalData, setModalData] = useState({
    open: false,
    message: '',
    messageHeader: '',
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [imageFileList, setImageFileList] = useState<UploadFile[]>([]);
  const [layerFileList, setLayerFileList] = useState<UploadFile[]>([]);
  const [editRowEnabled, setEditRowEnabled] = useState(blankVariantStageLocations.map(() => false));
  const [deleteId, setDeleteId] = useState<number>(0);
  const [deletedRow, setDeletedRow] = useState<number>(0);
  const [rowId, setRowId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [stages, setStages] = useState<BlankVariantStageLocations[]>([]);
  const [rowToEdit, setRowToEdit] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const [editInAdd, setEditInAdd] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [previewImage] = usePostMockupPreviewMutation();
  const [saveImage] = usePostBlankVariantMockupMutation();
  const [editMockup] = useUpdateBlankVariantMockupStageMutation();
  const [addMockup] = useAddBlankVariantMockupStageMutation();
  const [deleteStage] = useDeleteBlankVariantMockupStageMutation();
  const { data: blankVariantMockupStages } = useGetBlankVariantMockupByIdQuery(
    { blank_variant_mockup_id: blankVariantMockupId, page: currentPage },
    {
      skip: !isEdit,
    },
  );
  const [scale, setScale] = useState(1);

  const onPaginate = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(4000, 0);
  };

  const onPagination = (page: number) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * 10;
    const endIndex = page * 10;
    const dataSourceSlice = blankVariantStageLocations.slice(startIndex, endIndex);
    setStages(dataSourceSlice);
    window.scrollTo(2000, 0);
  };

  useEffect(() => {
    setStages(blankVariantStageLocations);
  }, [blankVariantStageLocations]);

  let transformedStages;
  useEffect(() => {
    const newStagesToAdd = blankVariantMockupStages?.items || [];
    const filteredStagesToAdd = newStagesToAdd.filter((stageToAdd) => {
      // Check if an object with the same stage.id already exists in blankVariantStageLocations
      return !blankVariantStageLocations.some((existingStage) => existingStage.id === stageToAdd.id);
    });

    transformedStages = filteredStagesToAdd.map((stage) => ({
      x: stage.x,
      y: stage.y,
      width: stage.width,
      height: stage.height,
      scale: 1,
      id: stage.id,
    }));
    const updatedStages = blankVariantStageLocations.concat(transformedStages);
    setBlankVariantStageLocations(updatedStages);
    if (isEdit) {
      if (typeof blankVariantMockupStages?.items[0]?.blankVariantMockup?.blankMockupFiles[0]?.path !== 'undefined') {
        const imageUrl = blankVariantMockupStages?.items[0]?.blankVariantMockup?.blankMockupFiles[0]?.path;
        const img = new Image();
        img.src = imageUrl;
        img.addEventListener('load', function () {
          const width = img.width;
          const height = img.height;
          setImage({
            width: width,
            height: height,
            path: blankVariantMockupStages?.items[0]?.blankVariantMockup?.blankMockupFiles[0]?.path,
            factor: 1,
          });
        });
      }
    }
  }, [blankVariantMockupStages, transformedStages]);

  const enableInputsEdit = (index: number) => {
    const updatedEditRowEnabled = editRowEnabled.map((_, i) => i === index); // Set only the clicked row to true
    updatedEditRowEnabled[index] = true;
    setEditRowEnabled(updatedEditRowEnabled);
    setScale(blankVariantStageLocations[index].scale);
    setImage({ ...image, factor: blankVariantStageLocations[index].scale });

    setNonDividedCrop((prevState) => ({
      ...prevState,
      x: blankVariantMockupStages?.items[index].x ?? 0,
      y: blankVariantMockupStages?.items[index].y ?? 0,
      width: blankVariantMockupStages?.items[index].width ?? 0,
      height: blankVariantMockupStages?.items[index].height ?? 0,
    }));

    setCrop((prevState: Crop) => ({
      ...prevState,
      x: blankVariantStageLocations[index].x,
      y: blankVariantStageLocations[index].y,
      width: blankVariantStageLocations[index].width,
      height: blankVariantStageLocations[index].height,
    }));
  };

  const columns = [
    {
      title: 'x',
      dataIndex: 'x',
      key: 'x',
      render: (_: BlankVariantStageLocations, record: BlankVariantStageLocations, index: number) => {
        return (
          <InputNumber
            disabled={isEdit ? !editRowEnabled[index] : false} // Disable input if editRowEnabled is false for this row
            min={1}
            key={`x-${index}`}
            value={
              isEdit
                ? editRowEnabled[index]
                  ? nonDividedCrop.x.toString().replace(/^0+/, '')
                  : blankVariantMockupStages?.items[index].x
                : stages[index].x.toString().replace(/^0+/, '')
            }
            onChange={(c) => {
              setNonDividedCrop((prevState: Crop) => ({
                ...prevState,
                x: Number(c),
                y: stages[index].y,
                width: stages[index].width,
                height: stages[index].height,
                scale: stages[index].scale,
              }));
              setScale(stages[index].scale);
              setImage({ ...image, factor: stages[index].scale });
              onEditClicked(Number(c), 'x', index);
            }}
          />
        );
      },
    },
    {
      title: 'y',
      dataIndex: 'y',
      key: 'y',
      render: (_: BlankVariantStageLocations, record: BlankVariantStageLocations, index: number) => (
        <InputNumber
          disabled={isEdit ? !editRowEnabled[index] : false} // Disable input if editRowEnabled is false for this row
          min={1}
          value={
            isEdit
              ? editRowEnabled[index]
                ? nonDividedCrop.y.toString().replace(/^0+/, '')
                : blankVariantMockupStages?.items[index].y
              : stages[index].y.toString().replace(/^0+/, '')
          }
          key={`y-${index}`}
          onChange={(c) => {
            setNonDividedCrop((prevState: Crop) => ({
              ...prevState,
              x: stages[index].x,
              y: Number(c),
              width: stages[index].width,
              height: stages[index].height,
              scale: stages[index].scale,
            }));
            setScale(stages[index].scale);
            setImage({ ...image, factor: stages[index].scale });
            onEditClicked(Number(c), 'y', index);
          }}
        />
      ),
    },
    {
      title: 'width',
      dataIndex: 'width',
      key: 'width',
      render: (_: BlankVariantStageLocations, record: BlankVariantStageLocations, index: number) => (
        <InputNumber
          disabled={isEdit ? !editRowEnabled[index] : false} // Disable input if editRowEnabled is false for this row
          min={1}
          value={
            isEdit
              ? editRowEnabled[index]
                ? nonDividedCrop.width.toString().replace(/^0+/, '')
                : blankVariantMockupStages?.items[index].width
              : stages[index].width.toString().replace(/^0+/, '')
          }
          key={`width-${index}`}
          onChange={(c) => {
            setNonDividedCrop((prevState: Crop) => ({
              ...prevState,
              x: stages[index].x,
              y: stages[index].y,
              width: Number(c),
              height: stages[index].height,
              scale: stages[index].scale,
            }));
            setScale(stages[index].scale);
            setImage({ ...image, factor: stages[index].scale });
            onEditClicked(Number(c), 'width', index);
          }}
        />
      ),
    },
    {
      title: 'height',
      dataIndex: 'height',
      key: 'height',
      render: (_: BlankVariantStageLocations, record: BlankVariantStageLocations, index: number) => (
        <InputNumber
          disabled={isEdit ? !editRowEnabled[index] : false}
          key={`height-${index}`}
          min={1}
          value={
            isEdit
              ? editRowEnabled[index]
                ? nonDividedCrop.height.toString().replace(/^0+/, '')
                : blankVariantMockupStages?.items[index].height
              : stages[index].height.toString().replace(/^0+/, '')
          }
          onChange={(c) => {
            setNonDividedCrop((prevState: Crop) => ({
              ...prevState,
              x: stages[index].x,
              y: stages[index].y,
              width: stages[index].width,
              height: Number(c),
              scale: stages[index].scale,
            }));
            setScale(stages[index].scale);
            setImage({ ...image, factor: stages[index].scale });
            onEditClicked(Number(c), 'height', index);
          }}
        />
      ),
    },
    {
      title: 'scale',
      dataIndex: 'scale',
      key: 'scale',
      render: (_: BlankVariantStageLocations, record: BlankVariantStageLocations, index: number) => (
        <InputNumber
          disabled={isEdit}
          min={1}
          key={`scale-${index}`}
          value={
            isEdit
              ? editRowEnabled[index] && scale !== 1
                ? scale
                : 1
              : editInAdd && index === currentIndex
              ? scale
              : stages[index].scale
          }
          onStep={(value, step) => {
            onScaleChangeHandler(value, step, index);
          }}
          onFocus={() => {
            setEditInAdd(true);
            setCurrentIndex(index);
          }}
          onBlur={() => setEditInAdd(false)}
          onChange={(s) => {
            onScaleTypingHandler(s, index);
            onEditClicked(Number(s), 'scale', index);
          }}
        />
      ),
    },
    {
      title: 'Delete',
      key: 'action',
      render: (_: BlankVariantStageLocations, record: BlankVariantStageLocations, index: number) => (
        <Button
          key={`delete-${index}`}
          onClick={() => {
            onDeleteClicked(index);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];
  if (isEdit) {
    columns.push(
      {
        title: 'Edit',
        key: 'action',
        render: (_: BlankVariantStageLocations, record: BlankVariantStageLocations, index: number) => (
          <Button
            key={`edit-${index}`}
            onClick={() => {
              enableInputsEdit(index);
              setRowToEdit({
                x: record.x,
                y: record.y,
                width: record.width,
                height: record.height,
              });
            }}
          >
            Edit
          </Button>
        ),
      },
      {
        title: 'Save',
        key: 'action',
        render: (_: BlankVariantStageLocations, record: BlankVariantStageLocations, index: number) =>
          record.newlySubmitted ? (
            <Button
              key={`add-${index}`}
              onClick={() => {
                onAddStageClicked(index);
              }}
            >
              Add
            </Button>
          ) : (
            <Button
              key={`save-${index}`}
              onClick={() => {
                onEditStageClicked(index);
              }}
            >
              Save
            </Button>
          ),
      },
    );
  }

  const isCropEqualToInitial =
    crop.x === initialCropState.x &&
    crop.y === initialCropState.y &&
    crop.width === initialCropState.width &&
    crop.height === initialCropState.height;

  async function urlToBlob(url: string) {
    // This is where you use the fetch function to make an HTTP GET request to the specified URL
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    return blob;
  }

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  const beforeUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        setImage({
          width: image.width,
          height: image.height,
          path: e.target?.result as string,
          factor: 1,
        });
        setBlankVariantStageLocations([]);
      };
      setCrop(initialCropState);
      setDataSubmitted(false);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const beforeLayerUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        setLayerImage({
          width: image.width,
          height: image.height,
          path: e.target?.result as string,
          factor: 1,
        });
      };
    };
    reader.readAsDataURL(file);

    return false;
  };

  const sendImageToApi = async () => {
    setIsLoading(true);
    const formData: FormData = new FormData();
    formData.append('blank_variant_id', blank_variant_id);
    // Append the image data as a file
    if (isEdit) {
      urlToBlob(image.path)
        .then((blob) => {
          const mockup = blob;
          formData.append('mockup', mockup, 'canvasImage.jpg');
        })
        .catch((error) => {
          throw error;
        });
    } else {
      const mockup = imageFileList[0].originFileObj;
      formData.append('mockup', mockup, imageFileList[0].originFileObj?.name);
    }

    // Append the image data as a file
    const demo = layerFileList[0].originFileObj;
    formData.append('demo', demo, 'Image.jpg');

    const payload = [...blankVariantStageLocations];

    const adjustedPayload = payload.map((item) => {
      if (item.scale !== 1) {
        return {
          ...item,
          x: item.x * item.scale,
          y: item.y * item.scale,
          width: item.width * item.scale,
          height: item.height * item.scale,
          scale: 1,
        };
      } else {
        return item;
      }
    });

    payload.splice(0, payload.length, ...adjustedPayload);

    const data = payload;
    const jsonOutput: { locations: BlankVariantStageLocations[] } = { locations: data };
    formData.append('locations', JSON.stringify(jsonOutput));
    setTimeout(async () => {
      const response = await previewImage({ formData });
      setIsLoading(false);

      if ('data' in response) {
        if ('url' in response.data) {
          if (response.data.url && typeof response.data.url === 'string') {
            setImage({ ...image, path: response.data.url });
          }
        }
      } else {
        //
      }
    }, 500);
  };

  const onSave = async () => {
    setIsLoading(true);
    const formData: FormData = new FormData();
    formData.append('blank_variant_id', blank_variant_id);
    formData.append('blank_variant_mockup_provider_id', 1);

    const scaledLocations = blankVariantStageLocations.map((location) => ({
      x: location.x * location.scale,
      y: location.y * location.scale,
      width: location.width * location.scale,
      height: location.height * location.scale,
      scale: location.scale,
      id: location.id,
      newlySubmitted: location.newlySubmitted,
    }));

    const jsonOutput: { locations: typeof scaledLocations } = { locations: scaledLocations };
    formData.append('blank_variant_mockup_stages', JSON.stringify(jsonOutput));

    // Append the image data as a file
    const mockup = imageFileList[0].originFileObj;
    formData.append('image', mockup, mockup?.name);
    const response = await saveImage({ formData });
    if ('data' in response) {
      setModalData({
        open: true,
        message: 'Mockup is successfully saved.',
        messageHeader: 'Success!',
      });
    } else {
      if ('error' in response) {
        if (response.error) {
          if ('data' in response.error) {
            if (response.error.data) {
              if ('message' in response.error.data) {
                if (response.error.data.message) {
                  setModalData({
                    open: true,
                    message: response.error.data.message.toString(),
                    messageHeader: 'Error',
                  });
                }
              }
            }
          }
        }
      }
    }
    setIsLoading(false);
  };

  const deleteRow = (index: number) => {
    const blankVariantsStageLocations = [...blankVariantStageLocations];
    blankVariantsStageLocations.splice(index, 1);
    setBlankVariantStageLocations(blankVariantsStageLocations);
    if (blankVariantsStageLocations.length === 0) {
      setDataSubmitted(false);
    }
  };

  const onDeleteClicked = (index: number) => {
    if (!blankVariantStageLocations[index].newlySubmitted && isEdit) {
      setOpenDeleteModal(true);
      setDeleteId(blankVariantStageLocations[index].id);
      setDeletedRow(index);
    } else {
      deleteRow(index);
    }
  };

  const deleteStageTrigger = async () => {
    const response = await deleteStage(deleteId);
    if ('data' in response) {
      deleteRow(deletedRow);
      setModalData({
        open: true,
        message: 'Stage is successfully deleted.',
        messageHeader: 'Success!',
      });
    } else {
      setModalData({
        open: true,
        message: 'Error occurred while saving image. Please try again. ',
        messageHeader: 'Error',
      });
    }
    setIsLoading(false);
    setOpenDeleteModal(false);
  };

  const onAddStageClicked = async (index: number) => {
    setIsLoading(true);
    const { scale, id, newlySubmitted, ...stageResult } = blankVariantStageLocations[index];
    const result = { ...stageResult, blank_variant_mockup_id: blankVariantMockupId };
    const response = await addMockup({ stageInfo: result });
    if ('data' in response) {
      setModalData({
        open: true,
        message: 'Mockup is successfully added.',
        messageHeader: 'Success!',
      });
      deleteRow(index);
    } else {
      setModalData({
        open: true,
        message: 'Error occurred while saving image. Please try again. ',
        messageHeader: 'Error',
      });
    }
    setIsLoading(false);
  };

  const onEditStageClicked = async (index: number) => {
    setIsLoading(true);

    const updatedEditRowEnabled = editRowEnabled.map((_, i) => i === index); // Set only the clicked row to true
    updatedEditRowEnabled[index] = false;
    setEditRowEnabled(updatedEditRowEnabled);

    const { id, ...result } = blankVariantStageLocations[index];

    const payload = {
      x: rowToEdit.x !== result.x && scale > 1 ? result.x * scale : result.x,
      y: rowToEdit.y !== result.y && scale > 1 ? result.y * scale : result.y,
      width: rowToEdit.width !== result.width && scale > 1 ? result.width * scale : result.width,
      height: rowToEdit.height !== result.height && scale > 1 ? result.height * scale : result.height,
    };

    const response = await editMockup({ stageInfo: payload, stageId: blankVariantStageLocations[index].id });
    if ('data' in response) {
      setModalData({
        open: true,
        message: 'Mockup is successfully saved.',
        messageHeader: 'Success!',
      });
    } else {
      setModalData({
        open: true,
        message: response.error.toString(),
        messageHeader: 'Error',
      });
    }
    setIsLoading(false);
  };

  const onEditClicked = (value: number, property: keyof BlankVariantStageLocations, index: number) => {
    const newData = [...stages];
    newData[index][property] = Number(value);
    setStages(newData);
  };

  const onScaleNumberPressed = (value: number) => {
    setImage({ ...image, factor: value });
    setNonDividedCrop((prevState) => ({
      ...prevState,
      x: prevState.x * scale,
      y: prevState.y * scale,
      width: prevState.width * scale,
      height: prevState.height * scale,
    }));
    setScale(value);
    setNonDividedCrop((prevState) => ({
      ...prevState,
      x: prevState.x / value,
      y: prevState.y / value,
      width: prevState.width / value,
      height: prevState.height / value,
    }));
  };

  const onScaleTypingHandler = (value: number | null, index: number) => {
    if (value === null) {
      value = stages[index].scale;
    }

    setImage({ ...image, factor: value ?? 1 });

    const row = stages[index];
    const X = row.x;
    const Y = row.y;
    const W = row.width;
    const H = row.height;

    const timesX = X * row.scale;
    const timesY = Y * row.scale;
    const timesWidth = W * row.scale;
    const timesHeight = H * row.scale;

    const newX = timesX / (value ?? 1);
    const newY = timesY / (value ?? 1);
    const newWidth = timesWidth / (value ?? 1);
    const newHeight = timesHeight / (value ?? 1);
    const updatedRow = {
      ...row,
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight,
      scale: value,
    };

    const newStages = [...stages];
    newStages[index] = updatedRow;

    setNonDividedCrop({
      unit: 'px',
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight,
    });
    setScale(value ?? 1);
    setStages(newStages);
    setBlankVariantStageLocations(newStages);
  };

  const onScaleChangeHandler = (index: number, step: any, value: number) => {
    setImage({ ...image, factor: index });

    const row = stages[value];

    const X = row.x;
    const Y = row.y;
    const W = row.width;
    const H = row.height;

    const timesX = X * (step.type === 'up' ? (row.scale > 1 ? row.scale - 1 : row.scale) : row.scale + 1);
    const timesY = Y * (step.type === 'up' ? (row.scale > 1 ? row.scale - 1 : row.scale) : row.scale + 1);
    const timesWidth = W * (step.type === 'up' ? (row.scale > 1 ? row.scale - 1 : row.scale) : row.scale + 1);
    const timesHeight = H * (step.type === 'up' ? (row.scale > 1 ? row.scale - 1 : row.scale) : row.scale + 1);

    const newX = timesX / index;
    const newY = timesY / index;
    const newWidth = timesWidth / index;
    const newHeight = timesHeight / index;

    const updatedRow = {
      ...row,
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight,
    };

    const newStages = [...stages];
    newStages[value] = updatedRow;
    setNonDividedCrop({
      unit: 'px',
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight,
    });
    setScale(index);
    setBlankVariantStageLocations(newStages);
  };
  const onPreviewClicked = () => {
    sendImageToApi();
  };

  const onSubmit = () => {
    setRowId((prevState) => prevState + 1);

    const isDuplicate = blankVariantStageLocations.some(
      (item) =>
        item.x * item.scale === nonDividedCrop?.x * image.factor &&
        item.y * item.scale === nonDividedCrop?.y * image.factor &&
        item.width * item.scale === nonDividedCrop?.width * image.factor &&
        item.height * item.scale === nonDividedCrop?.height * image.factor,
    );

    if (
      (crop && crop.width !== 0 && crop.height !== 0) ||
      (nonDividedCrop && nonDividedCrop.width !== 0 && nonDividedCrop.height !== 0)
    ) {
      if (isEdit) {
        if (!isDuplicate) {
          setBlankVariantStageLocations([
            ...blankVariantStageLocations,
            {
              x: nonDividedCrop?.x,
              y: nonDividedCrop?.y,
              width: nonDividedCrop?.width,
              height: nonDividedCrop?.height,
              scale: image.factor,
              id: rowId,
              newlySubmitted: 1,
            },
          ]);
        }
      } else {
        if (!isDuplicate) {
          setBlankVariantStageLocations([
            ...blankVariantStageLocations,
            {
              x: nonDividedCrop?.x,
              y: nonDividedCrop?.y,
              width: nonDividedCrop?.width,
              height: nonDividedCrop?.height,
              scale: image.factor,
              id: rowId,
            },
          ]);
        }
      }
      setDataSubmitted(true);
    }
  };

  const cancel = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleImageFileChange = (event: { fileList: SetStateAction<UploadFile[]> }) => {
    setImageFileList(event?.fileList);
  };

  const handleLayerFileChange = (event: { fileList: SetStateAction<UploadFile[]> }) => {
    setLayerFileList(event?.fileList);
  };

  return (
    <div className={styles.mainContainer}>
      <div>
        <Row className={styles.header}>
          <Col>
            <div className={styles.subHeader}>
              <p className={styles.users}>Blanks </p>
              <p className={styles.details}>/ Blank Variants </p>
              <p className={styles.details}> / Sharp</p>
            </div>
          </Col>
        </Row>
        <div className={styles.imageContainer}>
          {image.path ? (
            <ReactCrop
              style={{ width: image.width / image.factor, height: image.height / image.factor }}
              crop={nonDividedCrop}
              onChange={(c) => {
                setCrop(c);
                setNonDividedCrop((prevState) => ({
                  ...prevState,
                  x: c.x,
                  y: c.y,
                  height: c.height,
                  width: c.width,
                }));
              }}
            >
              <img
                className={styles.image}
                style={{ width: image.width / image.factor, height: image.height / image.factor }}
                src={image.path}
              />
            </ReactCrop>
          ) : (
            ''
          )}
        </div>
        <div className={styles.imageCropperToolsContainer}>
          {!isEdit && (
            <div className={styles.cropperItemDivider}>
              <Button disabled={!dataSubmitted} onClick={onSave}>
                Save
              </Button>
            </div>
          )}

          <div className={styles.cropperItemDivider}>
            <Button
              disabled={
                isCropEqualToInitial &&
                (nonDividedCrop.width === 0 ||
                  nonDividedCrop.height === 0 ||
                  nonDividedCrop.x === 0 ||
                  nonDividedCrop.y === 0)
              }
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
          <div className={styles.cropperItemDivider}>
            <Button
              disabled={
                isEdit ? layerImage.path === '' : isCropEqualToInitial || layerImage.path === '' || !dataSubmitted
              }
              onClick={onPreviewClicked}
            >
              Preview
            </Button>
          </div>
          <div className={styles.cropperItemDivider}>
            <Text className={styles.cropperTextDivider}>Width</Text>
            <InputNumber
              min={1}
              value={nonDividedCrop.width ? nonDividedCrop?.width : 0}
              onChange={(c) =>
                setNonDividedCrop((prevState: Crop) => ({
                  ...prevState,
                  width: Number(c),
                }))
              }
            />
          </div>
          <div className={styles.cropperItemDivider}>
            <Text className={styles.cropperTextDivider}>Height </Text>
            <InputNumber
              min={1}
              value={nonDividedCrop?.height ? nonDividedCrop?.height : 0}
              onChange={(c) =>
                setNonDividedCrop((prevState: Crop) => ({
                  ...prevState,
                  height: Number(c),
                }))
              }
            />
          </div>

          <div className={styles.cropperItemDivider}>
            <Text className={styles.cropperTextDivider}>X</Text>
            <InputNumber
              min={1}
              value={nonDividedCrop?.x ? nonDividedCrop?.x : 0}
              onChange={(c) =>
                setNonDividedCrop((prevState: Crop) => ({
                  ...prevState,
                  x: Number(c),
                }))
              }
            />
          </div>
          <div className={styles.cropperItemDivider}>
            <Text className={styles.cropperTextDivider}>Y</Text>
            <InputNumber
              min={1}
              value={nonDividedCrop?.y ? nonDividedCrop?.y : 0}
              onChange={(c) =>
                setNonDividedCrop((prevState: Crop) => ({
                  ...prevState,
                  y: Number(c),
                }))
              }
            />
          </div>
          <div className={styles.cropperItemDivider}>
            <Text className={styles.cropperTextDivider}>Scale</Text>
            <InputNumber
              min={1}
              max={10}
              value={scale}
              onChange={(value) => {
                if (typeof value === 'number') {
                  onScaleNumberPressed(value);
                }
              }}
            />
          </div>
          {!isEdit && (
            <Upload showUploadList={false} beforeUpload={beforeUpload} onChange={handleImageFileChange}>
              <Button className={styles.cropperItemDivider} icon={<UploadOutlined />}>
                Upload Canvas
              </Button>
            </Upload>
          )}
          <Upload showUploadList={false} beforeUpload={beforeLayerUpload} onChange={handleLayerFileChange}>
            <Button className={styles.cropperItemDivider} icon={<UploadOutlined />}>
              Upload Image
            </Button>
          </Upload>
        </div>
      </div>

      <div className={styles.tables}>
        <Draggable {...dragHandlers}>
          <div className="table">
            {isEdit ? (
              <Table
                className={styles.table}
                columns={columns}
                rowKey={(record: BlankVariantStageLocations) => record.id.toString()}
                dataSource={blankVariantMockupStages?.items?.map((item) => ({
                  x: item.x,
                  y: item.y,
                  width: item.width,
                  height: item.height,
                  scale: 1,
                  id: item.id,
                }))}
                pagination={{
                  current: currentPage,
                  pageSize: blankVariantMockupStages?.meta?.itemsPerPage ?? 0,
                  total: blankVariantMockupStages?.meta?.totalItems ?? 0,
                  onChange: onPaginate,
                }}
              ></Table>
            ) : (
              dataSubmitted && (
                <Table
                  className={styles.table}
                  columns={columns}
                  rowKey={(record: BlankVariantStageLocations) => record.id.toString()}
                  dataSource={stages}
                  pagination={{
                    pageSize: 10,
                    current: currentPage,
                    total: blankVariantStageLocations.length,
                    onChange: (page) => onPagination(page),
                  }}
                ></Table>
              )
            )}
          </div>
        </Draggable>
      </div>
      <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={deleteStageTrigger} />
      <ResponseModal cancel={cancel} {...modalData} />
      <SmallLoading isLoading={isLoading}></SmallLoading>
    </div>
  );
};

export default Mockups;
