import { Col, Form, Input, Modal, Row } from 'antd';

import { AddressInformationModalTypes } from './AddressInformation.modal.types';
const AddressInformationModal = ({ open, cancel, form, type }: AddressInformationModalTypes) => {
  return (
    <Modal centered open={open} onCancel={cancel} closable={true} footer={[]}>
      <Row>
        <Col span={24}>
          <div>
            <h1> {type === 'shipping' ? 'Shipping' : 'Billing'} Address</h1>
          </div>
          <Form form={form} layout={'vertical'} autoComplete="off" name="basic">
            <Form.Item label="Country" name="country">
              <Input disabled={true} name="country" type={'text'} />
            </Form.Item>
            <Form.Item label="State" name="state">
              <Input disabled={true} name="state" type={'text'} />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input disabled={true} name="city" type={'text'} />
            </Form.Item>
            <Form.Item label="Postal Code" name="postal_code">
              <Input disabled={true} name="postal_code" type={'number'} />
            </Form.Item>
            <Form.Item label="Address Line" name="address_line">
              <Input disabled={true} name="address_line" type={'text'} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddressInformationModal;
