import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table, Tooltip, UploadFile } from 'antd';
import FormData from 'form-data';
import React, { SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';

import SmallLoading from '../../component/loadings/smallLoading/SmallLoading';
import BlankInformationModal from '../../component/modals/blankInformation/BlankInformationModal';
import DeleteModal from '../../component/modals/delete/Delete.modal';
import UploadImageModal from '../../component/modals/uploadImage/UploadImageModal';
import {
  useAddBlankInformationMutation,
  useDeleteBlankInformationMutation,
  useEditBlankInformationMutation,
  useGetBlankInformationQuery,
  useUpdateBlankInformationImageMutation,
  useUploadBlankInformationImageMutation,
} from '../../redux/blankInformation/blankInformation.actions';
import { BlankInformation as BlankInformationType } from '../../redux/blankInformation/blankInformation.model';
import styles from './BlankInformation.module.css';
import columns from './BlankInformation.table';

const BlankInformation = () => {
  const { id } = useParams();
  const blank_id = Number(id);
  const initialState: { blank_id: number; name: string; description: string } = {
    blank_id: blank_id,
    name: '',
    description: '',
  };

  const [page, setPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [currentId, setCurrentId] = useState(0);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();
  const [openUploadImageModal, setOpenUploadImageModal] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);

  const [blankInfoState, setBlankInfoState] = useState({
    data: initialState,
    type: 'create',
    openAddEditModal: false,
  });
  const { data: blankInformation, isLoading } = useGetBlankInformationQuery({ blank_id, page });
  const [deleteTrigger] = useDeleteBlankInformationMutation();
  const [addTrigger] = useAddBlankInformationMutation();
  const [uploadImage] = useUploadBlankInformationImageMutation();
  const [editTrigger] = useEditBlankInformationMutation();
  const [updateImage] = useUpdateBlankInformationImageMutation();

  const onPaginate = (page: number) => {
    setPage(page);
  };

  const handleImageUpload = async (blankInfoId: number, type: string) => {
    const formData: FormData = new FormData();
    if (typeof fileList[0] === 'undefined') {
      setUpdateError(true);
    } else {
      formData.append('image', fileList[0].originFileObj);
      if (type === 'add') {
        await uploadImage({ formData, blankInfoId });
      } else {
        const response = await updateImage({ formData, blankInfoId });
        if ('data' in response) {
          setUpdateError(false);
          closeUploadImageModal();
        } else {
          setUpdateError(true);
        }
      }
      setFileList([]);
      setImageUploaded(false);
    }
  };

  const onClick = async (type: string) => {
    if (type === 'add') {
      try {
        const response = await addTrigger(blankInfoState.data);
        if ('data' in response && typeof response.data.id === 'number') {
          handleImageUpload(response.data.id, type);
        } else {
          //
        }
      } catch (error) {
        //
      }
    } else if (type === 'edit') {
      const updatedBlankInfo = {
        id: currentId,
        name: form.getFieldValue(['name']),
        description: form.getFieldValue(['description']),
      };
      try {
        await editTrigger(updatedBlankInfo);
      } catch (error) {
        //
      }
    } else {
      //if its edit image
      handleImageUpload(currentId, type);
      if (updateError) setOpenUploadImageModal(false);
    }
    setBlankInfoState({ ...blankInfoState, openAddEditModal: false });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const data = { ...blankInfoState.data, [name]: value };
    setBlankInfoState({ ...blankInfoState, data: data });
  };

  const handleFileChange = (event: { fileList: SetStateAction<UploadFile[]> }) => {
    setFileList(event?.fileList);
    setImageUploaded(true);
    setUpdateError(false);
  };

  const onActionClick = (blank: BlankInformationType | undefined, type: string | undefined) => {
    switch (type) {
      case 'add':
        form.resetFields();
        setBlankInfoState((prevState) => ({
          ...prevState,
          type: type,
          openAddEditModal: true,
        }));
        break;

      case 'delete':
        if (blank && blank?.id) {
          setCurrentId(blank.id);
          setOpenDeleteModal(true);
        }
        break;

      case 'edit':
        if (blank && blank?.id) {
          setCurrentId(blank.id);
        }
        form.setFieldValue(['name'], blank?.name);
        form.setFieldValue(['description'], blank?.description);
        setBlankInfoState((prevState) => ({
          ...prevState,
          type: type,
          openAddEditModal: true,
        }));
        break;

      case 'editImage':
        if (blank && blank?.id) {
          setCurrentId(blank.id);
        }
        setOpenUploadImageModal(true);
        break;

      default:
      // Handle any other cases here
    }
  };

  const onClickDelete = () => {
    deleteTrigger(currentId);
    setOpenDeleteModal(false);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeAddEditModal = () => {
    setBlankInfoState({ ...blankInfoState, openAddEditModal: false });
    setImageUploaded(false);
  };

  const closeUploadImageModal = () => {
    setOpenUploadImageModal(false);
    setImageUploaded(false);
    setFileList([]);
  };

  return (
    <>
      <Row className={styles.mainContainer}>
        <Row className={styles.container}>
          <Row className={styles.header}>
            <Col>
              <div className={styles.subHeader}>
                <p className={styles.users}>Blanks</p>
                <p className={styles.details}>/ Blank Information</p>
              </div>
            </Col>
          </Row>
          <Row className={styles.detailsTitle}>
            <Col className={styles.title}>
              <p>Blank Information</p>
            </Col>
            <Col>
              <p className={styles.line}></p>
            </Col>
            <div className={styles.margins}></div>
          </Row>
          <Row className={styles.tableContainer}>
            <SmallLoading isLoading={isLoading} />
            <Table
              rowKey="id"
              columns={columns(onActionClick)}
              className={styles.table}
              dataSource={blankInformation?.items}
              scroll={{ x: 500 }}
              pagination={{
                current: page,
                pageSize: blankInformation?.meta?.itemsPerPage ?? 0,
                total: blankInformation?.meta?.totalItems ?? 0,
                onChange: onPaginate,
              }}
            />
            <Tooltip title="Add blank Information" color="#23a89e">
              <FloatButton
                shape="circle"
                className={'floatingButton'}
                icon={<PlusOutlined className={'plusIcon'} />}
                onClick={() => {
                  onActionClick(blankInfoState.data, 'add');
                }}
              />
            </Tooltip>
          </Row>
        </Row>
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
        <BlankInformationModal
          imageUploaded={imageUploaded}
          handleChangeImage={handleFileChange}
          form={form}
          onChange={handleInputChange}
          action={onClick}
          cancel={closeAddEditModal}
          open={blankInfoState.openAddEditModal}
          type={blankInfoState.type}
        />
        <UploadImageModal
          open={openUploadImageModal}
          cancel={closeUploadImageModal}
          action={onClick}
          handleChangeImage={handleFileChange}
          error={updateError}
          imageUploaded={imageUploaded}
        />
      </Row>
    </>
  );
};
export default BlankInformation;
