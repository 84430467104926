import '../../users/models/Antd.css';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, FloatButton, Form, Row, Table, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import searchIcon from '../../../assets/searchIcon.svg';
import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../../component/modals/delete/Delete.modal';
import AddModal from '../../../component/modals/training/AddModal';
import useBackNavigation from '../../../hooks/useBackNavigation';
import { setCurrentPage } from '../../../redux/navigations/navigations.reducer';
import { RootState } from '../../../redux/navigations/navigations.type';
import {
  useCreateTrainingMutation,
  useDeleteTrainingMutation,
  useGetTrainingQuery,
  useUpdateTrainingMutation,
} from '../../../redux/trainings/training/training.actions';
import { TrainingModel } from '../../../redux/trainings/training/training.model';
import styles from '../../users/models/TrainingModels.module.css';
import columns from './Training.table';
const Training = () => {
  const dispatch = useDispatch();
  const globalPage = useSelector((state: RootState) => state.pagination.pages.trainingCurrentPage);
  const [form] = Form.useForm();
  const [trainingId, setTrainingId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const initialState = { name: '', key: '', value: '', active: true };
  const [trainingState, setTrainingState] = useState({
    data: initialState,
    type: 'create',
    openModal: false,
    inputValues: initialState,
  });

  const placeHolders = ['ID', 'Name', 'Key', 'Value', 'Active'];
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const id = inputValues['ID'];
  const name = inputValues['Name'];
  const key = inputValues['Key'];
  const value = inputValues['Value'];
  const active = inputValues['Active'];

  const [create] = useCreateTrainingMutation();
  const [deleteTrigger] = useDeleteTrainingMutation();
  const { data, isLoading } = useGetTrainingQuery({ id, name, key, value, active, page: globalPage });
  const [updateTrigger] = useUpdateTrainingMutation();
  const navigate = useNavigate();
  useBackNavigation('trainingCurrentPage');

  const onPaginate = (page: number) => {
    dispatch(setCurrentPage({ pageName: 'trainingCurrentPage', pageNumber: page }));
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onClickDelete = () => {
    deleteTrigger(trainingId);
    setOpenDeleteModal(false);
  };

  const onActionClick = (data: TrainingModel, type: string) => {
    if (type === 'delete') {
      setTrainingId(data.id);
      setOpenDeleteModal(true);
    } else if (type === 'edit') {
      setTrainingState({ ...trainingState, data, type, openModal: true });
    }
  };

  const onCreateClick = () => {
    setTrainingState({ ...trainingState, type: 'create', openModal: true });
  };

  const onCloseModal = () => {
    setTrainingState({ ...trainingState, openModal: false, inputValues: initialState });
  };
  const updateTrainingState = (
    trainingInputs: { name: string; key: string; value: string; active: boolean },
    trainingData: { name: string; key: string; value: string; active: boolean },
  ) => {
    if (trainingState.type === 'create') {
      setTrainingState({ ...trainingState, inputValues: trainingInputs });
    } else if (trainingState.type === 'edit') {
      setTrainingState({ ...trainingState, data: trainingData });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const trainingData = { ...trainingState.data, [name]: value };
    const trainingInputs = { ...trainingState.inputValues, [name]: value };

    updateTrainingState(trainingInputs, trainingData);
  };

  const onClickActive = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;
    const trainingData = { ...trainingState.data, active: checked };
    const trainingInputs = { ...trainingState.inputValues, active: checked };

    updateTrainingState(trainingInputs, trainingData);
  };
  const onSubmit = () => {
    const { type, data: trainingData } = trainingState;

    if (type === 'create') {
      create(trainingState.inputValues);
      setTrainingState({ ...trainingState, inputValues: initialState, openModal: false });
    } else if (type === 'edit' && trainingData.name && trainingData.key) {
      updateTrigger(trainingData);
      setTrainingState({ ...trainingState, openModal: false });
    }
  };

  const onIconClick = (id: number, type: string) => {
    if (type === 'text') {
      navigate(`text/${id}`);
    } else if (type === 'rules') {
      navigate(`rules/${id}`);
    } else if (type === 'image_rules') {
      navigate(`image/rules/training/${id}`);
    } else if (type === 'raffle') {
      navigate(`category/raffle/${id}`);
    }
  };

  const navigateToCategory = () => {
    navigate(`category`);
  };

  useEffect(() => {
    if (trainingState.openModal) {
      form.setFieldsValue(trainingState.type === 'create' ? trainingState.inputValues : trainingState.data);
    }
  }, [trainingState.openModal]);

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.trainingSubHeader}>
              <p className={styles.mainTitle}> Tools</p>
              <p className={styles.trainingSection}> / Training</p>
              <p className={styles.mainTitle}>/ Training Text</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.trainingTitle}>
            <div className={styles.titleContainer}>
              <p>Tools - </p> <span className={styles.subTitle}>Training</span>
            </div>
            <div className={styles.trainingCatContainer}>
              <Button className={styles.trainingCategoryBtn} onClick={navigateToCategory}>
                Add Training Category
              </Button>
            </div>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <Col>
            <p className={styles.closeModelText}>Close</p>
          </Col>
        </Row>
        <Row className={styles.search}>
          <Col className={styles.inputsColumn}>
            <img alt={'Search'} src={searchIcon} />
            <span>Search</span>
          </Col>
          {placeHolders.map((placeHolder, i) => (
            <Col key={i}>
              <input
                className={styles.inputs}
                placeholder={placeHolder}
                onChange={(e) => {
                  setInputValues((prevInputValues) => ({
                    ...prevInputValues,
                    [placeHolder]: e.target.value,
                  }));
                }}
              />
            </Col>
          ))}
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick, onIconClick)}
          className={styles.table}
          dataSource={data?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: globalPage,
            pageSize: data?.meta?.itemsPerPage ?? 0,
            total: data?.meta?.totalItems ?? 0,
            onChange: onPaginate,
          }}
        />
        <Tooltip title="Add Training" color="#23a89e">
          <FloatButton
            shape="circle"
            className={'floatingButton'}
            icon={<PlusOutlined className={'plusIcon'} />}
            onClick={onCreateClick}
          />
        </Tooltip>
        <AddModal
          form={form}
          open={trainingState.openModal}
          cancel={onCloseModal}
          inputValues={trainingState.type === 'create' ? trainingState.inputValues : trainingState.data}
          onChangeInput={handleInputChange}
          onClickActive={onClickActive}
          onSubmit={onSubmit}
        ></AddModal>
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
      </Row>
    </Row>
  );
};

export default Training;
