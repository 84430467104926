import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { OfferTypeModel, OfferTypes } from './offerTypes.model';
export const offerTypesApi = createApi({
  reducerPath: 'offerTypes',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['offerTypes'],
  endpoints: (builder) => ({
    createOfferTypes: builder.mutation<OfferTypes, Partial<OfferTypes>>({
      query: (data: OfferTypes) => ({ url: `/offer-types`, method: 'POST', data: data }),
      invalidatesTags: ['offerTypes'],
    }),
    getOfferTypes: builder.query<OfferTypeModel, { page: number }>({
      query: (data: { page: number }) => ({
        url: `/offer-types?limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['offerTypes'],
    }),
    deleteOfferTypes: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/offer-types/${id}`, method: 'delete' };
      },
      invalidatesTags: ['offerTypes'],
    }),

    editOfferTypes: builder.mutation<OfferTypes, Partial<OfferTypes>>({
      query: (data: OfferTypes) => {
        return { url: `/offer-types/${data.id}`, method: 'PATCH', data: data };
      },
      invalidatesTags: ['offerTypes'],
    }),
  }),
});

export const {
  useGetOfferTypesQuery,
  useCreateOfferTypesMutation,
  useDeleteOfferTypesMutation,
  useEditOfferTypesMutation,
} = offerTypesApi;
