import { ShoppingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import Moment from 'react-moment';

import ModelsIcon from '../../../assets/models.png';
import paymentMethodIcon from '../../../assets/payment_method.png';
import profileIcon from '../../../assets/user_profile.png';
import styles from './Users.module.css';

// eslint-disable-next-line no-unused-vars
const UsersColumns = (onIconClick: { (id: number, arg0: string): void }) => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => <div>{id}</div>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => (
        <div>
          <a className={styles.emailText}>{email}</a>
        </div>
      ),
    },
    {
      title: 'Date of Creation',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => (
        <div>
          <a className={styles.dateText}>
            <Moment fromNow>{date}</Moment>
          </a>
        </div>
      ),
    },
    {
      title: 'User details',
      dataIndex: 'user_details',
      key: 'user_details',
      render: (date: string, record: { id: number }) => (
        <div className={styles.iconsContainer}>
          <Tooltip title="Profile" color="#23a89e">
            <img
              onClick={() => onIconClick(record.id, 'profile')}
              height={20}
              width={20}
              alt={'user Profile'}
              className={styles.informationIcon}
              src={profileIcon}
            />
          </Tooltip>
          <Tooltip title="Models" color="#23a89e">
            <img
              onClick={() => onIconClick(record.id, 'models')}
              height={20}
              width={20}
              className={styles.informationIcon}
              alt={'user Profile'}
              src={ModelsIcon}
            />
          </Tooltip>
          <Tooltip title="Payment method" color="#23a89e">
            <img
              onClick={() => onIconClick(record.id, 'payment-methods')}
              alt={'payment methods'}
              className={styles.informationIcon}
              src={paymentMethodIcon}
              height={20}
              width={20}
            />
          </Tooltip>
          <Tooltip title="View Order Information" color="#23a89e">
            <ShoppingOutlined
              className={styles.informationIcon}
              onClick={() => {
                onIconClick(record.id, 'order');
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
};

export default UsersColumns;
