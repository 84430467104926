import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { AdminData, Profile, UserData } from './users.model';

interface argsType {
  email?: string | undefined;
  type?: string | undefined;
  page: number;
}
export const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['UserModel'],
  endpoints: (builder) => ({
    getUsers: builder.query<UserData, argsType>({
      query: (object) => {
        const { email, page, type } = object;
        let url = `/users?type=${type}&limit=10&page=${page}`;

        if (email) {
          url += `&email=${email}`;
        }

        return { url, method: 'get' };
      },
      providesTags: ['UserModel'],
    }),

    getUserProfile: builder.query<Profile, number>({
      query: (id) => {
        return {
          url: `/users/${id}`,
          method: 'get',
        };
      },
    }),

    createAdminUser: builder.mutation<AdminData, Partial<AdminData>>({
      query: (data: AdminData) => ({ url: `/authentication/admin/register`, method: 'POST', data: data }),
      invalidatesTags: ['UserModel'],
    }),
  }),
});

export const { useGetUsersQuery, useGetUserProfileQuery, useCreateAdminUserMutation } = usersApi;
