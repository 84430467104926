import { Col, Form, Row, Table } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AddressInformationModal from '../../component/modals/addressInformation/AddressInformationModal';
import { useGetOrderByIdQuery } from '../../redux/orders/orders.actions';
import { Order as OrderType } from '../../redux/orders/orders.model';
import styles from '../blankInformation/BlankInformation.module.css';
import columns from './OrderInformation.table';

const Order = () => {
  const { id } = useParams();
  const orderId = Number(id);
  const navigate = useNavigate();
  const [addressType, setAddressType] = useState('');
  const [form] = Form.useForm();
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const { data: orderInfo } = useGetOrderByIdQuery(orderId);

  const onActionClick = (order: OrderType | undefined, type: string | undefined) => {
    switch (type) {
      case 'shipping address':
        setAddressType('shipping');
        form.setFieldValue('country', orderInfo?.shipping_address.country);
        form.setFieldValue('state', orderInfo?.shipping_address.state);
        form.setFieldValue('city', orderInfo?.shipping_address.city);
        form.setFieldValue('postal_code', orderInfo?.shipping_address.postal_code);
        form.setFieldValue('address_line', orderInfo?.shipping_address.address_line);
        setOpenAddressModal(true);
        break;
      case 'billing address':
        setAddressType('billing');
        form.setFieldValue('country', orderInfo?.billing_address.country);
        form.setFieldValue('state', orderInfo?.billing_address.state);
        form.setFieldValue('city', orderInfo?.billing_address.city);
        form.setFieldValue('postal_code', orderInfo?.billing_address.postal_code);
        form.setFieldValue('address_line', orderInfo?.billing_address.address_line);
        setOpenAddressModal(true);
        break;
      case 'order line items':
        navigate(`/main/orders/order-information/order-line-item/${orderId}`);
        break;
      case 'ProductPrintFile':
        break;
      default:
      //Handle any other cases here
    }
  };

  const closeAddressModal = () => {
    setOpenAddressModal(false);
  };

  return (
    <>
      <Row className={styles.mainContainer}>
        <Row className={styles.container}>
          <Row className={styles.header}>
            <Col>
              <div className={styles.subHeader}>
                <p className={styles.users}>Orders</p>
                <p className={styles.details}>/ Order Information </p>
              </div>
            </Col>
          </Row>
          <Row className={styles.detailsTitle}>
            <Col className={styles.title}>
              <p>Order Information</p>
            </Col>
            <Col>
              <p className={styles.line}></p>
            </Col>
            <div className={styles.margins}></div>
          </Row>
          <Row className={styles.tableContainer}>
            <Table
              rowKey="id"
              columns={columns(onActionClick)}
              className={styles.table}
              dataSource={orderInfo ? [orderInfo] : []}
              scroll={{ x: 500 }}
            />
          </Row>
        </Row>
        <AddressInformationModal
          open={openAddressModal}
          cancel={closeAddressModal}
          type={addressType}
          form={form}
        ></AddressInformationModal>
      </Row>
    </>
  );
};
export default Order;
