import { EditOutlined, FileImageOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import DeleteIcon from '../../assets/icon _close circle_.svg';
import { BlankInformation } from '../../redux/blankInformation/blankInformation.model';
import styles from '../blanks/Blanks.module.css';

const columns = (
  onActionClick: (_blankInformation?: BlankInformation | undefined, _type?: string | undefined) => void,
) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Blank',
      dataIndex: 'image',
      key: 'image',
      render: (img: string) => {
        return <img alt={img} height={40} width={40} src={img} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <p>{text}</p>,
    },

    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: '',
      key: 'Blank Information',
      render: (blankInformation: BlankInformation) => {
        return (
          <Tooltip title="Edit blank Information" color="#23a89e">
            <EditOutlined
              className={styles.informationIcon}
              onClick={() => {
                onActionClick(blankInformation, 'edit');
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'Blank Information',
      render: (blankInformation: BlankInformation) => {
        return (
          <Tooltip title="Edit blank image" color="#23a89e">
            <FileImageOutlined
              className={styles.informationIcon}
              onClick={() => {
                onActionClick(blankInformation, 'editImage');
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'Delete',
      render: (blankInformation: BlankInformation) => {
        return (
          <Tooltip title="Delete" color="#23a89e">
            <img
              alt={'Delete'}
              style={{ cursor: 'pointer' }}
              src={DeleteIcon}
              className={styles.deleteIcon}
              onClick={() => {
                onActionClick(blankInformation, 'delete');
              }}
            />
          </Tooltip>
        );
      },
    },
  ];
};
export default columns;
