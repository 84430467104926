import React from 'react';

export const DashboardLogoIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 8.6H3.6C2.55414 8.6 1.95343 8.33921 1.60711 7.99289C1.26079 7.64657 1 7.04586 1 6V3.6C1 2.55414 1.26079 1.95343 1.60711 1.60711C1.95343 1.26079 2.55414 1 3.6 1H6C7.04586 1 7.64657 1.26079 7.99289 1.60711C8.33921 1.95343 8.6 2.55414 8.6 3.6V6C8.6 7.04586 8.33921 7.64657 7.99289 7.99289C7.64657 8.33921 7.04586 8.6 6 8.6Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4004 8.6H18.0004C16.9545 8.6 16.3538 8.33921 16.0075 7.99289C15.6612 7.64657 15.4004 7.04586 15.4004 6V3.6C15.4004 2.55414 15.6612 1.95343 16.0075 1.60711C16.3538 1.26079 16.9545 1 18.0004 1H20.4004C21.4462 1 22.047 1.26079 22.3933 1.60711C22.7396 1.95343 23.0004 2.55414 23.0004 3.6V6C23.0004 7.04586 22.7396 7.64657 22.3933 7.99289C22.047 8.33921 21.4462 8.6 20.4004 8.6Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4004 23H18.0004C16.9545 23 16.3538 22.7392 16.0075 22.3929C15.6612 22.0466 15.4004 21.4459 15.4004 20.4V18C15.4004 16.9542 15.6612 16.3535 16.0075 16.0071C16.3538 15.6608 16.9545 15.4 18.0004 15.4H20.4004C21.4462 15.4 22.047 15.6608 22.3933 16.0071C22.7396 16.3535 23.0004 16.9542 23.0004 18V20.4C23.0004 21.4459 22.7396 22.0466 22.3933 22.3929C22.047 22.7392 21.4462 23 20.4004 23Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 23H3.6C2.55414 23 1.95343 22.7392 1.60711 22.3929C1.26079 22.0466 1 21.4459 1 20.4V18C1 16.9542 1.26079 16.3535 1.60711 16.0071C1.95343 15.6608 2.55414 15.4 3.6 15.4H6C7.04586 15.4 7.64657 15.6608 7.99289 16.0071C8.33921 16.3535 8.6 16.9542 8.6 18V20.4C8.6 21.4459 8.33921 22.0466 7.99289 22.3929C7.64657 22.7392 7.04586 23 6 23Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
