import { PlusOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Row, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import SmallLoading from '../../../component/loadings/smallLoading/SmallLoading';
import DeleteModal from '../../../component/modals/delete/Delete.modal';
import TrainingImageRulesAddModal from '../../../component/modals/trainingImageRules/TrainingImageRulesAddModal';
import {
  useCreateTrainingImageRulesMutation,
  useDeleteTrainingImageRulesMutation,
  useGetImageResizeMethodQuery,
  useGetImageTypesQuery,
  useGetTrainingImageRulesQuery,
  useUpdateTrainingImageRulesMutation,
} from '../../../redux/trainings/trainingImageRules/training.image.rules.action';
import { TrainingImageRulesData } from '../../../redux/trainings/trainingImageRules/training.image.rules.model';
import styles from '../../users/models/TrainingModels.module.css';
import columns from './Training.image.rules.table';
import { ImageRulesType } from './Training.image.rules.type';
const TrainingImageRules = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const trainingId = parseInt(id as string);
  const [page, setPage] = useState(1);
  const formRef = useRef(null);

  const { data, isLoading } = useGetTrainingImageRulesQuery({ id: trainingId, page });
  const { data: imageType } = useGetImageTypesQuery('');
  const { data: imageResizeMethod } = useGetImageResizeMethodQuery('');
  const [createTrainingImageRules] = useCreateTrainingImageRulesMutation();
  const [deleteTrigger] = useDeleteTrainingImageRulesMutation();
  const [updateTrainingImageRules] = useUpdateTrainingImageRulesMutation();
  const initialState = {
    name: '',
    description: '',
    image_type_id: '',
    image_resize_method_id: '',
    imageTypes: { label: 'PNG', value: 1, name: 'PNG', id: 1 },
    imageResizeMethods: { label: 'Contain', value: 2, name: 'Contain', id: 2 },
  };
  const [trainingRulesId, setTrainingRequirementsId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isVisible, isNotVisible] = useState(data?.items.length === 0);
  const [trainingImageRulesState, setTrainingImageRulesState] = useState({
    data: initialState,
    type: 'create',
    openModal: false,
    inputValues: initialState,
  });

  useEffect(() => {
    isNotVisible(data?.items.length === 0);
  }, [data]);

  useEffect(() => {
    if (trainingImageRulesState.openModal) {
      form.setFieldsValue(
        trainingImageRulesState.type === 'create' ? trainingImageRulesState.inputValues : trainingImageRulesState.data,
      );
    }
  }, [trainingImageRulesState.openModal]);

  const onPaginate = (page: number) => {
    setPage(page);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeEditModal = () => {
    setTrainingImageRulesState({ ...trainingImageRulesState, openModal: false, inputValues: initialState });
  };
  const onClickDelete = () => {
    deleteTrigger(trainingRulesId);
    setOpenDeleteModal(false);
  };

  const onActionClick = (data: TrainingImageRulesData, type: string) => {
    if (type === 'delete') {
      setTrainingRequirementsId(data.id);
      setOpenDeleteModal(true);
    } else if (type === 'edit') {
      setTrainingRequirementsId(data.id);
      setTrainingImageRulesState({ ...trainingImageRulesState, data, type, openModal: true });
    }
  };

  const onCreateClick = () => {
    setTrainingImageRulesState({ ...trainingImageRulesState, type: 'create', openModal: true });
  };

  useEffect(() => {
    if (trainingImageRulesState.type === 'create') {
      if (formRef.current) {
        form.setFieldValue('image_type_id', {
          value: imageType ? imageType[0].id : '',
          name: imageType ? imageType[0].name : '',
          key: imageType ? imageType[0].id : '',
        });
        form.setFieldValue('image_resize', {
          value: imageResizeMethod ? imageResizeMethod[0].id : '',
          name: imageResizeMethod ? imageResizeMethod[0].name : '',
          key: imageResizeMethod ? imageResizeMethod[0].id : '',
        });
      }
    } else {
      if (formRef.current) {
        form.setFieldValue('image_type_id', {
          value: trainingImageRulesState?.data?.imageTypes?.id,
          name: trainingImageRulesState?.data?.imageTypes?.name,
          key: trainingImageRulesState?.data?.imageTypes?.id,
        });
        form.setFieldValue('image_resize', {
          value: trainingImageRulesState?.data?.imageResizeMethods?.id,
          name: trainingImageRulesState?.data?.imageResizeMethods?.name,
          key: trainingImageRulesState?.data?.imageResizeMethods?.id,
        });
      }
    }
  }, [imageType, imageResizeMethod, trainingImageRulesState.openModal, form]);

  const onSubmit = (_data: ImageRulesType) => {
    const { type } = trainingImageRulesState;
    if (type === 'create') {
      createTrainingImageRules({
        training_id: trainingId,
        name: _data.name,
        description: _data.description,
        image_type_id: _data?.image_type_id?.value,
        image_resize_method_id: _data?.image_resize?.value,
      });
      setTrainingImageRulesState({ ...trainingImageRulesState, openModal: false });
    } else if (type === 'edit') {
      updateTrainingImageRules({
        id: trainingRulesId,
        name: _data.name,
        description: _data.description,
        image_type_id: _data?.image_type_id?.value,
        image_resize_method_id: _data?.image_resize?.value,
      });
      setTrainingImageRulesState({ ...trainingImageRulesState, openModal: false });
    }
  };

  return (
    <Row className={styles.mainContainer}>
      <Row className={styles.container}>
        <Row className={styles.header}>
          <Col>
            <div className={styles.trainingSubHeader}>
              <p className={styles.mainTitle}> Tools</p>
              <p className={styles.trainingSection}> / Training</p>
              <p className={styles.mainTitle}>/ Training Text</p>
            </div>
          </Col>
        </Row>
        <Row className={styles.detailsTitle}>
          <Col className={styles.title}>
            <p>Tools - </p> <span className={styles.subTitle}>Training</span>
          </Col>
          <Col>
            <p className={styles.line}></p>
          </Col>
          <Col>
            <p className={styles.closeModelText}>Close</p>
          </Col>
        </Row>
        <SmallLoading isLoading={isLoading} />
        <Table
          rowKey="id"
          columns={columns(onActionClick)}
          className={styles.table}
          dataSource={data?.items}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: data?.meta?.itemsPerPage ?? 0,
            total: data?.meta?.itemsPerPage ?? 0,
            onChange: onPaginate,
          }}
        />
        {isVisible && (
          <FloatButton
            shape="circle"
            className={'floatingButton'}
            icon={<PlusOutlined className={'plusIcon'} />}
            onClick={onCreateClick}
          />
        )}
        <TrainingImageRulesAddModal
          form={form}
          types={imageType}
          resizeMethods={imageResizeMethod}
          open={trainingImageRulesState.openModal}
          cancel={closeEditModal}
          inputValues={
            trainingImageRulesState.type === 'create'
              ? trainingImageRulesState.inputValues
              : trainingImageRulesState.data
          }
          onSubmit={onSubmit}
        ></TrainingImageRulesAddModal>
        <DeleteModal openModal={openDeleteModal} cancel={closeDeleteModal} onDelete={onClickDelete} />
      </Row>
    </Row>
  );
};

export default TrainingImageRules;
