import { Switch } from 'antd';

import { BlankPrintLocations } from '../../redux/blankPrintLocations/blankPrintLocations.model';
import { formatDate } from '../../utils/helpers';

const columns = (
  onActionClick: (
    _blankPrintLocation?: BlankPrintLocations | undefined,
    _type?: string | undefined,
    _active?: boolean,
  ) => void,
  setBlankActive: { [key: string]: boolean },
) => {
  {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',

        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Blank Id',
        dataIndex: 'blank_id',
        key: 'blank_id',
        render: (text: number) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
          return <p>{text}</p>;
        },
      },
      {
        title: 'Active',
        key: 'is_active',
        render: (blankPrintLocation: BlankPrintLocations) => {
          let isActive = false;
          if (blankPrintLocation.id) {
            isActive = setBlankActive[blankPrintLocation.id.toString()];
          }
          return (
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Switch
                checked={isActive}
                onChange={() => {
                  onActionClick(blankPrintLocation, 'activation');
                }}
              />
            </div>
          );
        },
      },
      {
        title: 'Created at',
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
      {
        title: 'Updated at',
        key: 'updated_at',
        dataIndex: 'updated_at',
        render: (text: string) => {
          const formattedDate = formatDate(text);
          return <p>{formattedDate}</p>;
        },
      },
    ];
  }
};
export default columns;
