import { createApi } from '@reduxjs/toolkit/query/react';
import FormData from 'form-data';

import axiosBaseQuery from '../../utils/axiosBaseQuery';
import { BlanksProperties, BlanksPropertiesModel, ImageApiObject, ImageUrl } from './blanksProperties.model';

export const blanksPropertiesApi = createApi({
  reducerPath: 'blankProperties',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),
  tagTypes: ['blanksProperty'],
  endpoints: (builder) => ({
    getBlankProperties: builder.query<BlanksPropertiesModel, { blank_id: number; page: number }>({
      query: (data: { blank_id: number; page: number }) => ({
        url: `/blank-properties/blank/{blankId}?blankId=${data.blank_id}&limit=10&page=${data.page}`,
        method: 'get',
      }),
      providesTags: ['blanksProperty'],
    }),
    deleteBlankProperties: builder.mutation<string, number>({
      query: (id: number) => {
        return { url: `/blank-properties/${id}`, method: 'delete' };
      },
      invalidatesTags: ['blanksProperty'],
    }),
    createBlankProperties: builder.mutation<BlanksProperties, { blank_id: number; key: string; value: string }>({
      query: (data: BlanksProperties) => ({ url: `/blank-properties`, method: 'POST', data: data }),
      invalidatesTags: ['blanksProperty'],
    }),
    editBlanksProperties: builder.mutation<BlanksProperties, { id: number; key: string; value: string }>({
      query: (data: BlanksProperties) => {
        return { url: `/blank-properties/${data.id}`, method: 'PATCH', data: data };
      },
      invalidatesTags: ['blanksProperty'],
    }),
    uploadBlankPropertyImage: builder.mutation<ImageUrl, ImageApiObject>({
      query: (data: { formData: FormData; id: number }) => ({
        headers: {
          'content-type': 'multipart/form-data',
          accept: '/',
        },
        url: `/blank-properties/upload/image?id=${data.id}`,
        method: 'POST',
        data: data.formData,
      }),
      invalidatesTags: ['blanksProperty'],
    }),
  }),
});

export const {
  useGetBlankPropertiesQuery,
  useDeleteBlankPropertiesMutation,
  useCreateBlankPropertiesMutation,
  useEditBlanksPropertiesMutation,
  useUploadBlankPropertyImageMutation,
} = blanksPropertiesApi;
